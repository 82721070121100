/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from 'react';
import { Attachment, Edit, InfoRound } from '@rsuite/icons';
import {
  Table,
  FlexboxGrid,
  SelectPicker,
  IconButton,
  CheckPicker,
  Modal,
  Button,
  TagGroup, Tag,
} from 'rsuite';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { GerarCSV as CSV } from '~/utils/gerarCSV'
import { Container } from '~/styles/tabela';
import pt from 'date-fns/locale/pt-BR';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { formatDistance } from "date-fns";
import { TablePagination } from '~/components/TablePagination';
import api from '~/services/api';
import formatCurrency from '~/utils/formatCurrency';
import SearchInputTable from '~/components/SearchInputTable';
import { InputDataRange } from '~/components/InputDataRange';
import { FiltroTreinamentos } from '~/components/FiltroTreinamentos';
import { obterExtremosSemana } from '~/utils/datas';
import EditarProfissional from '../../Profissionais/EditarProfissional';
import DetalhesCarteiraDialog from './components/DetalhesCarteiraDialog';
import SelectState from '../../../components/SelectState';

const { HeaderCell, Column, Cell } = Table;

function CarteiraProfissionais() {
  const [carteiraProfissionais, setCarteiraProfissionais] = useState({
    lista: [],
    quantidade: 0,
  });
  const [profissoes, setProfissoes] = useState([]);
  const [experienciaSelecionada, setExperienciaSelecionada] = useState();
  const [treinamentos, setTreinamentos] = useState([]);
  const [treinamentoSelecionado, setTreinamentoSelecionado] = useState();
  const [trabFeitosSelecionado, setTrabFeitosSelecionado] = useState();
  const [atributoSelecionado, setAtributoSelecionado] = useState('nome');
  const [modalConfirmacao, setModalConfirmacao] = useState(false);
  const [textodigitado, setTextoDigitado] = useState();
  const [estadoSelecionado, setEstadoSelecionado] = useState();
  const [data, setData] = useState(obterExtremosSemana(new Date()));
  const [pagina, setPagina] = useState(1);
  const [exibirDetalhesDialog, setExibirDetalhesDialog] = useState({
    exibir: false,
  });
  const [profissionalEditando, setProfissionalEditando] = useState();
  const [showEditarProfissional, setShowEditarProfissional] = useState(false);
  const [diasMes, setDiasMes] = useState([]);
  const [carregando, setCarregando] = useState(true);

  const [orderBy, setOrderBy] = useState('');
  const [vagaSemana, setVagaSemana] = useState({
    seg:0,
    ter:0,
    qua:0,
    qui:0,
    sex:0,
    sab:0,
    dom:0,
  });

  const buscarCarteiraProfissionais = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.get(`/profissionais/works-week`, {
        params: {
          [atributoSelecionado]: textodigitado,
          data,
          pagina,
          treinamentos: treinamentoSelecionado,
          experiencias: experienciaSelecionada,
          trabFeitos: trabFeitosSelecionado,
          estado: estadoSelecionado,
          orderBy,
        },
      });
      const diaSemana = resposta.data.vagasAutorizadas
      setVagaSemana({
        seg: `${diaSemana.seg_full === null ? 0 : diaSemana.seg_full} vaga(s)` ,
        ter: `${diaSemana.ter_full === null ? 0 : diaSemana.ter_full} vaga(s)` ,
        qua: `${diaSemana.qua_full === null ? 0 : diaSemana.qua_full} vaga(s)` ,
        qui: `${diaSemana.qui_full === null ? 0 : diaSemana.qui_full} vaga(s)` ,
        sex: `${diaSemana.sex_full === null ? 0 : diaSemana.sex_full} vaga(s)` ,
        sab: `${diaSemana.sab_full === null ? 0 : diaSemana.sab_full} vaga(s)` ,
        dom: `${diaSemana.dom_full === null ? 0 : diaSemana.dom_full} vaga(s)` ,
      })
      const profissoes1 = await api.get('profissao');
      setProfissoes(profissoes1.data);
      const treinamentos1 = await api.get('treinamento');
      setTreinamentos(treinamentos1.data);

      const getDuration = (startDuration, stopoverDuration) => {
        const durationDateFrom = new Date(startDuration);
        const durationDateTo = new Date(stopoverDuration);
        return formatDistance(durationDateTo, durationDateFrom, {
          locale: pt,
        });
      }
      const lista = resposta.data.lista.map((item) => {

        const newItem = Object.fromEntries(Object.entries(item).map(([key, value]) => [key, Array.isArray(value) ? value.filter(Boolean) : value]));
        return {
          id: newItem.id_profissional,
          ...newItem,
          // segunda: newItem.seg_full.length > 0 ? 'Tenho trabalho' : '',
          // terca: newItem.ter_full.length > 0 ? 'Tenho trabalho' : '',
          // quarta: newItem.qua_full.length > 0 ? 'Tenho trabalho' : '',
          // quinta: newItem.qui_full.length > 0 ? 'Tenho trabalho' : '',
          // sexta: newItem.sex_full.length > 0 ? 'Tenho trabalho' : '',
          // sabado: newItem.sab_full.length > 0 ? 'Tenho trabalho' : '',
          // domingo: newItem.dom_full.length > 0 ? 'Tenho trabalho' : '',
          segunda: newItem.seg_full,
          terca: newItem.ter_full,
          quarta: newItem.qua_full,
          quinta: newItem.qui_full,
          sexta: newItem.sex_full,
          sabado: newItem.sab_full,
          domingo: newItem.dom_full,
        }
      });
      setCarteiraProfissionais({ lista, quantidade: resposta.data.quantidade });
      setDiasMes(resposta.data.diaDoMes.diasArray)
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, [
    atributoSelecionado,
    textodigitado,
    data,
    pagina,
    treinamentoSelecionado,
    experienciaSelecionada,
    trabFeitosSelecionado,
    estadoSelecionado,
    orderBy,
  ]);

  function download(dados) {
    const blob = new Blob([dados], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'download.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a)
  }
  async function gerarCSV() {
    setCarregando(true)
    setModalConfirmacao(false)
    try {
      const resposta = await api.get('profissionais/work-week', {
        params: {
          [atributoSelecionado]: textodigitado,
          data,
          pagina,
          treinamentos: treinamentoSelecionado,
          experiencias: experienciaSelecionada,
          trabFeitos: trabFeitosSelecionado,
          estado: estadoSelecionado,
          orderBy,
          csvExport: true
        },
      });
      const lista = resposta.data.lista.map((item) => {
        return {
          id: item.id_profissional,
          ...item,
          segunda: item.seg_full.length > 0 ? 'Tenho trabalho' : '',
          terca: item.ter_full.length > 0 ? 'Tenho trabalho' : '',
          quarta: item.qua_full.length > 0 ? 'Tenho trabalho' : '',
          quinta: item.qui_full.length > 0 ? 'Tenho trabalho' : '',
          sexta: item.sex_full.length > 0 ? 'Tenho trabalho' : '',
          sabado: item.sab_full.length > 0 ? 'Tenho trabalho' : '',
          domingo: item.dom_full.length > 0 ? 'Tenho trabalho' : '',
        }
      });


      CSV({
        // data: resposta.data.lista,
        data: lista,
        download: true,
      });

      setCarregando(false);
      toast.success(resposta.data.mensagem)
    } catch (e) {
      setCarregando(false);
      toast.error(e.message);
    }
  }

  useEffect(() => {
    buscarCarteiraProfissionais();
  }, [buscarCarteiraProfissionais]);

  function abrirEditarProfissional(profissional) {
    setProfissionalEditando(profissional);
    setShowEditarProfissional(true);
  }

  function fecharEditarProfissional() {
    buscarCarteiraProfissionais();
    setShowEditarProfissional(false);
  }


  const App = ({ infor = '', color = 'green' }) => (
    <TagGroup>
      <Tag style={{borderRadius: '25px', margin: '5px', marginBottom: '10px'}} size="lg" color={color}>{infor}</Tag>
      {/* <Tag size="md" color="green">{infor}</Tag>
      <Tag size="sm" color="green">{infor}</Tag> */}
    </TagGroup>
  );

  App.propTypes = {
    infor: PropTypes.string.isRequired,
  };

  function CelulaDiaDaSemana({ rowData, dataKey, ...props }) {
    const diaSemana = rowData[dataKey] || [];
    const newKey = `${dataKey?.substr(0,3)}_interest`;
    const diaSemanaInteresse = rowData[newKey] || [];
    if (diaSemana.length === 0 && diaSemanaInteresse.length > 0) {
      return (
        <Cell {...props} style={{ padding: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <App infor={diaSemanaInteresse[0]} color='yellow' />
        </Cell>
      );

    }
    return (
      <Cell {...props} style={{ padding: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {diaSemana.map(dia => (<App infor={dia} />))}
      </Cell>
    );
  }

  CelulaDiaDaSemana.propTypes = {
    rowData: PropTypes.objectOf(PropTypes.any),
    dataKey: PropTypes.objectOf.isRequired,
  };

  CelulaDiaDaSemana.defaultProps = {
    rowData: {},
  };

  return (
    <Container>
      <h2>Trabalhos da Semana </h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchInputTable setTextoDigitado={setTextoDigitado} />
        <SelectPicker
          value={atributoSelecionado}
          placeholder="Selecione o Status"
          searchable={false}
          data={[
            { value: 'nome', label: 'Nome' },
            { value: 'bairro', label: 'Bairro' },
            { value: 'cidade', label: 'Cidade' },
          ]}
          onChange={setAtributoSelecionado}
          style={{ marginLeft: '-15px' }}
          cleanable={false}
        />

        <InputDataRange
          ranges={[]}
          value={data}
          oneTap
          onChange={setData}
          isoWeek
          hoverRange="week"
        />
        <SelectState
          selectedState={estadoSelecionado}
          setSelectedStates={setEstadoSelecionado}
        // marginRight
        // style={{ marginRight: '-30px' }}
        />
        <FiltroTreinamentos
          appearance="default"
          style={{ width: 224 }}
          searchable={false}
          data={treinamentos.map((treinamento) => ({
            label: treinamento.treinamento,
            value: treinamento.id,
          }))}
          value={treinamentoSelecionado}
          onChange={(value) => setTreinamentoSelecionado(value)}
        />
        <CheckPicker
          style={{ width: 224 }}
          appearance="default"
          placeholder="Experiências"
          data={profissoes.map((profissao) => ({
            label: profissao.profissao,
            value: profissao.id,
          }))}
          value={experienciaSelecionada}
          onChange={(value) => setExperienciaSelecionada(value)}
        />
        <CheckPicker
          style={{ width: 224 }}
          appearance="default"
          placeholder="Já trabalhou como:"
          data={profissoes.map((profissao) => ({
            label: profissao.profissao,
            value: profissao.id,
          }))}
          value={trabFeitosSelecionado}
          onChange={(value) => setTrabFeitosSelecionado(value)}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '1740px' }}>
      <TablePagination
        carregando={carregando}
        dados={carteiraProfissionais.lista}
        quantidade={carteiraProfissionais.quantidade}
        onChangePage={setPagina}
        setOrderBy={setOrderBy}
        wordWrap="break-word"
        headerHeight={70}
      >
        <Column resizable width={100}>
          <HeaderCell>Opções</HeaderCell>
          <Cell dataKey="id" style={{ padding: '5px' }}>
            {(rowData) => (
              <>
                <IconButton
                  title="Detalhes"
                  appearance="subtle"
                  onClick={() => {
                    setExibirDetalhesDialog({
                      exibir: true,
                      id_profissional: rowData.id,
                    });
                  }}
                  icon={<InfoRound />}
                />
                <IconButton
                  title="Editar"
                  appearance="subtle"
                  onClick={() => {
                    abrirEditarProfissional(rowData.id);
                  }}
                  icon={<Edit />}
                />
              </>
            )}
          </Cell>
        </Column>
        <Column resizable width={240} sortable>
          <HeaderCell>Nome Profissional</HeaderCell>
          <Cell dataKey="nome" />
        </Column>
        <Column align="center" resizable width={200} >
          <HeaderCell>Segunda - {diasMes[0]} <div>{vagaSemana.seg}</div></HeaderCell>
          <CelulaDiaDaSemana dataKey="segunda" />
        </Column>
        <Column align="center" resizable width={200} >
          <HeaderCell>Terça - {diasMes[1]} <div>{vagaSemana.ter}</div></HeaderCell>
          <CelulaDiaDaSemana dataKey="terca" />
        </Column>
        <Column align="center" resizable width={200} >
          <HeaderCell>Quarta - {diasMes[2]} <div>{vagaSemana.qua}</div></HeaderCell>
          <CelulaDiaDaSemana dataKey="quarta" />
        </Column>
        <Column align="center" resizable width={200} >
          <HeaderCell>Quinta - {diasMes[3]} <div>{vagaSemana.qui}</div></HeaderCell>
          <CelulaDiaDaSemana dataKey="quinta" />
        </Column>
        <Column align="center" resizable width={200} >
          <HeaderCell>Sexta - {diasMes[4]} <div>{vagaSemana.sex}</div></HeaderCell>
          <CelulaDiaDaSemana dataKey="sexta" />
        </Column>
        <Column align="center" resizable width={200} >
          <HeaderCell>Sábado - {diasMes[5]} <div>{vagaSemana.sab}</div></HeaderCell>
          <CelulaDiaDaSemana dataKey="sabado" />
        </Column>
        <Column align="center" resizable width={200} >
          <HeaderCell>Domingo - {diasMes[6]} <div>{vagaSemana.dom}</div></HeaderCell>
          <CelulaDiaDaSemana dataKey="domingo" />
        </Column>



      </TablePagination>
      </FlexboxGrid>
      <DetalhesCarteiraDialog
        exibir={exibirDetalhesDialog.exibir}
        id_profissional={exibirDetalhesDialog.id_profissional}
        datas={data}
        onFechar={() => setExibirDetalhesDialog({ exibir: false })}
      />
      <EditarProfissional
        idProfissional={profissionalEditando && profissionalEditando}
        showEditarProfissional={showEditarProfissional}
        setShowEditarProfissional={setShowEditarProfissional}
        fecharEditarProfissional={fecharEditarProfissional}
        atualizarLista={buscarCarteiraProfissionais}
      />
      <Modal
        open={modalConfirmacao}
        onClose={() => setModalConfirmacao(false)}
        size="xs"
      >
        <Modal.Header>Gerar Relatório CSV</Modal.Header>
        <Modal.Body>
          <p>
            Deseja realmente gerar o csv dos profissionais com o filtro
            atual?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalConfirmacao(false)}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <Button
            appearance="primary"
            type="button"
            onClick={() => {
              gerarCSV();
            }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default CarteiraProfissionais;
