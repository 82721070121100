import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import 'rsuite/styles/index.less';
import theme from './theme';


import gotham from '../fonts/gotham-book.ttf';
import gotham700 from '../fonts/gotham-black.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: 'gotham';
    src: url(${gotham700});
    font-weight: 700;
  }

  @font-face {
    font-family: 'gotham';
    src: url(${gotham});
  }


  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font: 14px 'gotham', sans-serif
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }
  .rs-table-row:nth-child(even) {
    background: #f2f2f2;
    .rs-table-cell {
      background: #f2f2f2;
    }
  }
  .rs-theme-dark .rs-table-row:nth-child(even) {
    background: #f2f2f2;
    .rs-table-cell {
      background: ${theme.darkGray};
    }
  }
  .rs-theme-dark .rs-table-row:nth-child(even):hover {
    background: #f2f2f2;
    .rs-table-cell {
      background: #363636;
    }
  }

  /* Tema dark de algumas tabelas de Administração/Logs */
  .rs-theme-dark .thTableLogs {
    background-color: #1A1D24 !important;
  }
  .rs-theme-dark .trTableLogs:nth-child(even) {
    background: #464749;
    .rs-table-cell {
      background: #464749;
    }
  }
  .rs-theme-dark .trTableLogs:nth-child(odd) {
    background: #1A1D24;
    .rs-table-cell {
      background: #1A1D24;
    }
  }
  /* ===== Mesmo de cima, mas específico para aba "Pedidos" ======= */
  .rs-theme-dark .thTablePedLogs {
    background-color: #1A1D24 !important;
  }
  .rs-theme-dark .trTablePedLogs:nth-child(even) {
    background: #464749;
    .rs-table-cell {
      background: #464749;
    }
  }
  .rs-theme-dark .trTablePedLogs:nth-child(odd) {
    background: #1A1D24;
    .rs-table-cell {
      background: #1A1D24;
    }
  }
  /* ===== Dark-mode tabela Dashboard ======= */
  .rs-theme-dark .rs-list > .rs-list-item:nth-of-type(odd) {
    background-color: #464749;
  }

  .rs - picker - toggle - value{
  color: ${theme.primaryColor} !important;
}
  .rs - table - cell - header - icon - sort{
  color: ${theme.primaryColor} !important;
}
  .rs - picker - select - menu - item.rs - picker - select - menu - item - active{
  color: ${theme.primaryColor} !important;
}
  .rs - toggle - checked.rs - toggle - presentation {
  background - color: ${theme.darkRed};
}
  .rs - toggle - presentation {
  background - color: ${theme.darkGreen};
}
  .rs - toggle - checked.rs - toggle - presentation:hover {
  background - color: ${theme.lightGray};
}
  .rs - toggle - presentation:hover {
  background - color: ${theme.lightGray};
}
  .recharts -default -legend{
  margin: 30px!important;
}
  .recharts - wrapper {
  z - index: 2;
}
  .rs - form - group {
  margin - bottom: 8px;
}
}
`;
