/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Table, FlexboxGrid, Schema } from 'rsuite';
import { toast } from 'react-toastify';
import { Plus } from '@rsuite/icons';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import {
  CelulaEditavel,
  CelulaAcao,
} from '~/components/Celulas/components/CelulaEditavel';
import { ModalIcon } from '~/components/ModalCriacao/ModalIcon';
import { Modal2 } from '~/components/ModalCriacao/Modal2';
import { Modal1 } from '~/components/ModalCriacao/Modal1';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import SearchTable from '../components/SearchTable';

const { HeaderCell, Column } = Table;

const { StringType } = Schema.Types;

const modelo = Schema.Model({
  descricao: StringType().isRequired('Descrição Obrigatório'),
});

function ComoDescobriu() {
  const [dados, setDados] = useState({
    idiomasLista: [],
    idiomasListaFiltrada: [],
    filtro: '',
    objeto: {},
  });
  const [apagarIdiomaDialog, setApagarIdiomaDialog] = useState({
    exibir: false,
    descricao: {},
  });
  const [carregando, setCarregando] = useState(true);
  const [adicionarIdiomaDialog, setAdicionarIdiomaDialog] = useState(false);
  const [camposInput, setCamposInput] = useState({});

  async function buscarIdiomas() {
    const resposta = await api.get('descobriu-switch');
    const resposta1 = await api.get('descobriu-switch');
    setDados({
      idiomasLista: resposta.data,
      idiomasListaFiltrada: resposta.data,
      listaInicial: resposta1.data,
    });
    setCarregando(false);
  }

  useEffect(() => {
    buscarIdiomas();
  }, []);

  function filtrarLista(texto) {
    setDados({
      ...dados,
      idiomasListaFiltrada: dados.idiomasLista.filter((descricao) =>
        descricao.descricao
          .toString()
          .toUpperCase()
          .includes(texto ? texto.toUpperCase() : '')
      ),
      filtro: texto,
    });
  }

  function abrirApagarDialog(descricao) {
    setApagarIdiomaDialog({
      exibir: true,
      descricao,
    });
  }

  function fecharApagarDialog() {
    setApagarIdiomaDialog({
      exibir: false,
      descricao: {},
    });
  }

  async function excluirIdioma() {
    try {
      await api.delete(`/descobriu-switch/${apagarIdiomaDialog.descricao.id}`);
      toast.success('Plataforma excluida com sucesso');
      fecharApagarDialog();
      buscarIdiomas();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function adicionarIdioma() {
    try {
      setCarregando(true);
      await api.post(`/descobriu-switch`, camposInput);
      toast.success('Plataforma criado com sucesso');
      setCarregando(false);
      setAdicionarIdiomaDialog(false);
      buscarIdiomas();
    } catch (e) {
      toast.error(e.message);
    }
  }

  function alterarTextoCelula(id, key, value) {
    const nextData = Object.assign([], dados.idiomasLista);
    nextData.find((item) => item.id === id)[key] = value;
    setDados({ ...dados, idiomasLista: nextData });
    filtrarLista(dados.filtro);
  }

  async function alterarEstadoCelula(id) {
    const novaLista = Object.assign([], dados.idiomasLista);
    const item = novaLista.find((descricao) => descricao.id === id);
    const itemIncial = dados.listaInicial.find((descricao) => descricao.id === id);
    if (item.status === 'EDIT' && item.descricao !== itemIncial.descricao) {
      try {
        setCarregando(true);
        await api.put(`descobriu-switch/${id}`, item);
        toast.success('Idioma atualizado com sucesso');
        buscarIdiomas();
      } catch (error) {
        toast.error(error.message);
      }
      setCarregando(false);
    } else {
      item.status = item.status ? null : 'EDIT';
      setDados({ ...dados, idiomasLista: novaLista });
      filtrarLista(dados.filtro);
    }
  }

  return (
    <Container>
      <h2>Como descobriu</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchTable estilo={{ width: '300px' }} filtrarLista={filtrarLista} />
        <ModalIcon
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setAdicionarIdiomaDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '440px' }}>
        <TabelaSemPaginacao
          carregando={carregando}
          dados={dados.idiomasListaFiltrada}
        >
          <Column resizable width={240} sortable>
            <HeaderCell>Nome</HeaderCell>
            <CelulaEditavel dataKey="descricao" onChange={alterarTextoCelula} />
          </Column>

          <Column resizable width={200}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onClick={alterarEstadoCelula}
              onDelete={abrirApagarDialog}
            />
          </Column>
        </TabelaSemPaginacao>
      </FlexboxGrid>

      <Modal1
        show={adicionarIdiomaDialog}
        onHide={() => setAdicionarIdiomaDialog(false)}
        title="Adicionar descrição"
        label="Descrição"
        name="descricao"
        model={modelo}
        onSubmit={(status) => status && adicionarIdioma()}
        onChange={setCamposInput}
        onClick={() => setAdicionarIdiomaDialog(false)}
      />

      <Modal2
        show={apagarIdiomaDialog.exibir}
        onHide={fecharApagarDialog}
        title="Apagar plataforma"
        text="Você realmente deseja apagar esta Plataforma ?"
        apagarId={apagarIdiomaDialog.descricao.id}
        apagarDado={apagarIdiomaDialog.descricao.descricao}
        fecharApagarDialog={fecharApagarDialog}
        excluir={() => excluirIdioma()}
      />
    </Container>
  );
}

export default ComoDescobriu;
