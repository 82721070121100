/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { isAfter, isBefore, subDays, format, parseISO } from 'date-fns';
import {
  Grid,
  Row,
  Col,
  DatePicker,
  Button,
  AutoComplete,
  SelectPicker,
  InputGroup,
  Form,
  Input,
  FlexboxGrid,
  ButtonGroup,
  RadioGroup,
  Radio,
  Dropdown,
  IconButton,
  TagGroup,
  Tag,
} from 'rsuite';
import {
  FaCalendarCheck
} from 'react-icons/fa'

// import Handle from '@styled-icons/bootstrap/HandIndexFill';
import { LightbulbFlash } from '@styled-icons/remix-line/LightbulbFlash';
import { Bulb } from '@styled-icons/evaicons-solid/Bulb';
import { Shake } from '@styled-icons/evaicons-solid/Shake';
import { Pointer, PointerDimensions } from '@styled-icons/evil/Pointer';
import { PlusSquare } from '@styled-icons/fa-solid/PlusSquare';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import MultiDatePicker from '@rsuite/multi-date-picker';
import InputMascara from '~/components/InputMascara';
import api from '~/services/api';
import theme from '~/styles/theme';

import {
  atualizarIdEstabelecimento,
  autorizarPedido,
  confirmarPedido,
  cancelarPedido,
} from '~/store/modulos/pedido/actions';
import {
  ResponsiveForm,
  ResponsiveFormTextArea,
} from '~/components/ResponsiveForm';
import useSearchNames from '~/services/useSearchNames';
import formatCurrency from '~/utils/formatCurrency';
import * as Styled from './style';
import { HidePicker, DatePickerValueTransparent } from './style';
import { ModalCupom } from './modal/Cupom'
import { ModalCancelarPedido } from './modal/CancelarPedido'
import { DarkModeContext } from '../../../../common/contexts/darkMode';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('HH:MM');

function CamposDadosPedido({
  pedidoAntigo,
  setPedidoAntigo,
  editarPedido,
  finalizarPedido,
  children,
  fecharEditarPedido,
  atualizarCamposInput,
  setAtualizar,
  setSelectedDates,
  selectedDates,
}) {
  const [pedidos, setPedidos] = useState([]);

  const dispatch = useDispatch();
  const { camposInput } = useSelector((state) => {
    return state.pedido;
  });

  const [uniformes, setUniformes] = useState([]);
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);
  const [cupomModal, setCupomModal] = useState(false);
  const [cancelarModal, setCancelarModal] = useState(false);
  const { toggleMode } = useContext(DarkModeContext);

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };
  const range = [
    {
      label: 'Hoje',
      value: new Date(),
    },
  ];

  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos'
  );

  useEffect(() => {
    const buscarUniformes = async () => {
      try {
        const resposta = await api.get('uniforme');
        setUniformes(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    buscarUniformes();
  }, []);

  async function alterarStatus(funcao) {
    await dispatch(funcao);
    fecharEditarPedido();
  }

  const buscarPedidos = useCallback(async () => {
    try {
      const resposta = await api.get('/motivo-cancelamento', {
        params: {
          id_pedido: camposInput.id,
        },
      });
      const lista = resposta.data.lista.map((motivos) => ({
        ...motivos,
        data_formatada: format(parseISO(motivos.created_at), 'dd/MM/yyyy'),
        numero_pedido: motivos.pedido.numero_pedido,
        nome_fantasia: motivos.estabelecimento.nome_fantasia,
        horario: format(parseISO(motivos.created_at), 'HH:mm'),
      }));
      setPedidos({
        lista,
        quantidade: resposta.data.quantidade,
      });
    } catch (e) {
      toast.error(e.message);
    }
  }, [camposInput.id]);

  useEffect(() => {
    buscarPedidos();
  }, [buscarPedidos]);

  const compare = (a, b) => {
    return a.getTime() - b.getTime();
  };
  const isSelected = (date) =>
    selectedDates.some(
      (selectedDate) => selectedDate.getTime() === date.getTime()
    );

  const handleDateClick = (date) => {
    if (isSelected(date)) {
      setSelectedDates(
        selectedDates.filter((d) => d.getTime() !== date.getTime())
      );
    } else {
      setSelectedDates([...selectedDates, date].sort(compare));
    }
  };

  useEffect(() => {
    if (selectedDates && selectedDates.length) {
      dispatch(atualizarCamposInput({ ...camposInput, data: selectedDates }));
    }
    if(selectedDates && selectedDates.length === 0 && !editarPedido && camposInput.status === '') {
      dispatch(atualizarCamposInput({  ...camposInput, data: null }))
    }

  }, [selectedDates]);

  function handleOpenDatepicker(date) {
  const containerDatepicker = document.getElementsByClassName('rs-picker-date-menu')
    const Datepicker = [...containerDatepicker]
    const toolbar = Datepicker[0].children[1]
    toolbar.style.display = 'none'
  }

  const removeTag = tag => {
    const nextTags = selectedDates.filter(item => item !== tag);
    setSelectedDates(nextTags);
  };

  return (
    <Styled.Panel
      header={
        <Styled.Header>
          <h5>Dados do Pedido</h5>
          <RadioGroup
            onChange={(value) => {
              setPedidoAntigo(value);
            }}
            value={pedidoAntigo}
            inline
            defaultValue="filtrados"
            style={{ display: editarPedido ? 'none' : 'block' }}
          >
            <Radio value={false}>Pedido novo</Radio>
            <Radio value>Pedido antigo</Radio>
          </RadioGroup>
        </Styled.Header>
      }
    >
      {/* ----------------------------separação-------------------- */}

      <Grid fluid>
        <Row>
          <Col md={24} xs={24} sm={24}>
            <Col md={8} xs={24} sm={24}>
              <Form.Group>
                <ResponsiveForm
                  label="Nome do Cliente*"
                  name="nome_estabelecimento"
                  accepter={AutoComplete}
                  data={nomesEstabelecimentos.map((p) => ({
                    label: p.nome_fantasia,
                    value: p.nome_fantasia,
                    id: p.id,
                  }))}
                  onSelect={async (value, item) => {
                    setTimeout(() => {
                      dispatch(atualizarIdEstabelecimento(item.id));
                    }, 1000);
                  }}
                  onEntered={() => {
                    // dispatch(atualizarIdEstabelecimento(null));
                  }}
                  value={camposInput.nome_estabelecimento}
                  onChange={buscarNomesEstabelecimentos}
                />
              </Form.Group>
              <Form.Group >
                <ResponsiveForm
                  label="Uniforme*"
                  name="id_uniforme"
                  placeholder="Selecione"
                  accepter={SelectPicker}
                  value={camposInput.id_uniforme}
                  searchable={false}
                  style={{ width: '100%' }}
                  data={uniformes.map((p) => {
                    return {
                      label: `Blusa: ${p.blusa}, Calça: ${p.calca}, Calçado: ${p.calcado}`,
                      value: p.id,
                    };
                  })}
                />
              </Form.Group>
              <Row>
                <Col md={8} xs={8} sm={8}>
                  <Form.Group >
                    <Form.ControlLabel>Data*</Form.ControlLabel>
                    {editarPedido ? (
                      <DatePicker
                        placeholder="DD/MM/AA"
                        name="data"
                        format="dd/MM/yy"
                        cleanable={false}
                        accepter={DatePicker}
                        oneTap
                        locale={locale}
                        value={camposInput.data && camposInput.data}
                        ranges={range}
                        onChange={(data) =>
                          dispatch(atualizarCamposInput({ ...camposInput, data }))
                        }
                      />
                    ) : pedidoAntigo ? (
                      <DatePicker
                        placeholder="DD/MM/AA"
                        name="data"
                        format="dd/MM/yy"
                        cleanable={false}
                        accepter={DatePicker}
                        oneTap
                        locale={locale}
                        value={camposInput.data && camposInput.data}
                        ranges={range}
                        disabledDate={(data) => isAfter(data, new Date())}
                        onChange={(data) =>
                          dispatch(atualizarCamposInput({ ...camposInput, data }))
                        }
                      />
                    ) : (
                      <HidePicker>
                        <DatePickerValueTransparent
                        accepter={DatePicker}
                        name="data"
                        ranges={[]}
                        format="dd/MM/yy"
                        locale={locale}

                          onSelect={date => handleDateClick(date)}
                          disabledDate={(data) =>
                            pedidoAntigo
                              ? isAfter(data, subDays(new Date(), 1))
                              : isBefore(data, subDays(new Date(), 1))
                          }
                          placeholder="Selecione"
                          label='Selecione'
                          caretAs={FaCalendarCheck}
                          onOpen={(date) => handleOpenDatepicker(date)}
                        />
                      </HidePicker>
                    )}
                  </Form.Group>
                </Col>
                <Col md={8} xs={8} sm={8}>
                  <Form.Group>
                    <ResponsiveForm
                      label="Hora de Início*"
                      name="hora_inicio_texto"
                      placeholder="Selecione"
                      mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                      pipe={autoCorrectedDatePipe}
                      accepter={InputMascara}
                      value={camposInput.hora_inicio_texto}
                    />
                  </Form.Group>
                </Col>
                <Col md={8} xs={8} sm={8}>
                  <Form.Group >
                    <ResponsiveForm
                      label="Hora do Término*"
                      name="hora_fim_texto"
                      placeholder="Selecione"
                      mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                      pipe={autoCorrectedDatePipe}
                      accepter={InputMascara}
                      value={camposInput.hora_fim_texto}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <Col md={8} xs={8} sm={8}>
                  <Form.Group>
                    <ResponsiveForm
                      label='T.Dinâmica %'
                      name="tarifa_dinamica"
                      accepter={Input}
                      value={camposInput.tarifa_dinamica}
                    />
                  </Form.Group>
                </Col>
                <Col md={8} xs={8} sm={8}>
                    <Form.Group style={{marginTop: '-5px'}} >
                      <Form.ControlLabel style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>Prazo curto</Form.ControlLabel>
                      <ResponsiveForm
                        // label="Prazo curto"
                        name="tarifa_emergencia"
                        placeholder="Selecione"
                        defaultValue={0}
                        accepter={SelectPicker}
                        value={camposInput.tarifa_emergencia}
                        searchable={false}
                        style={{ width: '100%' }}
                        data={[{
                          label: 'Sem alteração',
                          value: 0,
                        },
                        {
                          label: '10% cobrança - 0% repasse',
                          value: 10,
                        },
                        {
                          label: '25% cobrança - 12.5% repasse',
                          value: 25,
                        },
                        {
                          label: '40% cobrança - 20% repasse',
                          value: 40,
                        },
                        {
                          label: '50% cobrança - 25% repasse',
                          value: 50,
                        },
                        ]}
                      />
                    </Form.Group>

                </Col>
                <Col md={8} xs={8} sm={8}>
                  <Form.Group >
                    <ResponsiveForm
                      label='T.Feriado %'
                      name="tarifa_feriado"
                      accepter={Input}
                      value={camposInput.tarifa_feriado}
                    />
                  </Form.Group>
                </Col>

                <Form.Group style={{marginTop: '75px'}}>
                <ResponsiveFormTextArea
                  label="Observação para o profissional(Opcional)"
                  name="obs_profissionais"
                  rows={5}
                  componentClass="textarea"
                />
              </Form.Group>
              </Row>
            </Col>

            {/* ----------------------------separação-------------------- */}

            <Col md={8} xs={24} sm={24}>


              <FlexboxGrid justify="flex-start">
                <Form.Group>
                  <Form.ControlLabel>Cupom</Form.ControlLabel>
                  <InputGroup style={{ width: '100%' }} inside >
                    <Form.Control
                      // size='xs'
                      name="cupom_texto"
                      disabled
                      placeholder='Insira o cupom aqui->'
                    />
                    {/* <LightbulbFlash  size={40}/> */}
                    <PlusSquare size={30}
                      style={{
                        color: toggleMode ? theme.smallButtonDarkTheme : theme.smallButtonLightTheme,
                        borderRadius: 5,
                        cursor: 'pointer',
                        marginTop: '2px'
                      }}
                      onClick={() => {
                        setCupomModal(true)
                      }}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  {/* <InputGroup.Button
                  style={{ marginTop: '20px', marginLeft: '5px', borderRadius: 4 }}
                  onClick={() => {
                  setCupomModal(true)
                  }}
                >
                  Aqui
              </InputGroup.Button> */}
                </Form.Group>

              </FlexboxGrid>

              <Form.Group>
                <ResponsiveFormTextArea
                  label="Observação (Opcional)"
                  name="observacao"
                  rows={5}
                  componentClass="textarea"
                />
              </Form.Group>

              <Form.Group style={{ marginButton: '24px', marginTop: '-8px' }}>
                <ResponsiveForm
                  label="Intervalo (Opcional)"
                  name="intervalo_texto"
                  placeholder="Selecione"
                  mask={[/\d/, /\d/, ':', /\d/, /\d/]}
                  pipe={autoCorrectedDatePipe}
                  accepter={InputMascara}
                  value={camposInput.intervalo_texto}
                />
              </Form.Group>
            </Col>
            {!editarPedido && !pedidoAntigo ?
            <Col md={8} xs={24} sm={24}>
            <Form.Group controlId="tagPicker">

              {/* new task */}
            <TagGroup>
            <b>Datas selecionadas:</b> <br/>
            {selectedDates.map((date, index) => (
                <Tag
                  key={index}
                  closable
                  onClose={() => removeTag(date)}
                >{date.toLocaleDateString()}</Tag>
              ))}

            </TagGroup>

             </Form.Group>
             </Col>
             : null
            }

            {/* ----------------------------separação-------------------- */}

            <Col md={8} xs={24} sm={24}>
              <FlexboxGrid.Item componentClass={Col}>
                {camposInput.status_pagamento && (
                  <Form.Group style={{ marginTop: '-5px' }}>
                    <Form.ControlLabel>Status Pagamento</Form.ControlLabel>
                    <ResponsiveForm
                      name="status_pagamento"
                      value={camposInput.status_pagamento || ''}
                      readOnly
                    />
                  </Form.Group>
                )}
                {editarPedido && (
                  <Form.Group>
                    <Form.ControlLabel>Valor</Form.ControlLabel>
                    <ResponsiveForm
                      name="valor"
                      value={formatCurrency(camposInput.valor)}
                      readOnly
                    />
                  </Form.Group>
                )}
                <div
                  style={{
                    display: camposInput.status === `Cancelado` ? `block` : `none`,
                    height: `150px`,
                  }}
                >
                  <h5 style={{ fontSize: '15px' }}>Motivo do Cancelamento: </h5>
                  {camposInput.status && camposInput.status === 'Cancelado' && (
                    camposInput.motivoCancelamento === null ? (
                      <p>Não há motivo</p>
                    ) : (
                      <p>{camposInput.motivoCancelamento}</p>
                    )
                  )
                  }
                </div>
                {camposInput.status && camposInput.status === 'Finalizado' && (
                  <Form.Group style={{ marginTop: '18px' }}>
                    <ResponsiveFormTextArea
                      label="Feedback do Cliente"
                      name="feedback_estb"
                      rows={4}
                      componentClass="textarea"
                    />
                  </Form.Group>
                )}
                {editarPedido && (
                  <>
                    <Form.Group
                      style={{ marginBottom: '10px', marginTop: '-10px' }}
                    >
                      <Form.ControlLabel>
                        Alterar Status: <b>{camposInput.status || ''}</b>
                      </Form.ControlLabel>
                      <ButtonGroup>
                        <Button
                          onClick={() => {
                            alterarStatus(autorizarPedido());
                          }}
                          appearance="ghost"
                          style={{ fontSize: '12px', padding: '5px', marginTop: '5px' }}
                        >
                          Autorizar
                        </Button>
                        <Button
                          onClick={() => {
                            alterarStatus(confirmarPedido());
                          }}
                          appearance="ghost"
                          style={{ fontSize: '12px', padding: '5px', marginTop: '5px' }}
                        >
                          Confirmar
                        </Button>
                        <Button
                          style={{ fontSize: '12px', padding: '5px', marginTop: '5px', marginRight: '200px' }}
                          onClick={finalizarPedido}
                          appearance="ghost"
                        >
                          Finalizar
                        </Button>
                        <Button
                          style={{ fontSize: '12px', padding: '5px', marginTop: '5px' }}
                          onClick={() => {
                            setCancelarModal(true)
                            // alterarStatus(cancelarPedido());
                          }}
                          appearance="ghost"
                        >
                          Cancelar
                        </Button>
                      </ButtonGroup>
                    </Form.Group>
                  </>
                )}
              </FlexboxGrid.Item>
            </Col>
            {children}

            <ModalCupom
              exibir={cupomModal}
              fechar={() => setCupomModal(false)}
            />
            <ModalCancelarPedido
              exibir={cancelarModal}
              fechar={() => setCancelarModal(false)}
              setAtualizar={setAtualizar}
              fecharEditarPedido={fecharEditarPedido}
            />
          </Col>
        </Row>
      </Grid>
    </Styled.Panel>
  );
}

CamposDadosPedido.propTypes = {
  editarPedido: PropTypes.bool,
  pedidoAntigo: PropTypes.bool,
  finalizarPedido: PropTypes.func,
  fecharEditarPedido: PropTypes.func.isRequired,
  atualizarCamposInput: PropTypes.func.isRequired,
  setSelectedDates: PropTypes.func,
  selectedDates: PropTypes.func,
  setPedidoAntigo: PropTypes.func.isRequired,
  setAtualizar: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CamposDadosPedido.defaultProps = {
  editarPedido: false,
  pedidoAntigo: false,
  children: [],
  finalizarPedido: () => { },
  setSelectedDates: () => { },
  selectedDates: () => { },
};

export default CamposDadosPedido;
