import React, { useEffect, useState, useCallback } from 'react';
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable */
import { useSelector } from 'react-redux';

import {
  Table,
  AutoComplete,
  InputGroup,
  FlexboxGrid,
  Button,
  SelectPicker,
  CheckPicker,
  IconButton,
  Whisper,
  Popover,
  Modal,
  DatePicker,
  Input,
} from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { Container } from '~/styles/tabela';
import api from '~/services/api';

import { InputDataRange } from '~/components/InputDataRange';
import useSearchNames from '~/services/useSearchNames';
import { ButtonFullBlue } from '~/components/Buttons/ButtonFullBlue/styles';
import { CelulaFormatada } from './components/CelulaFormatada';
import GerarFatura from './components/GerarFatura';
import GerarFaturasSemanais from './components/GerarFaturasSemanais';
import DropDownCell from './components/DropDownCell';
import ActionCell from './components/ActionCell';
import GerarSegundaVia from './components/GerarSegundaVia';
import { TablePagination } from '../../components/TablePagination';

const { HeaderCell, Column } = Table;

export default function IuguIndex() {
  const user = useSelector((state) => state.setUser.data);
  const { role } = user;

  const [exibir, setExibir] = useState(false);
  const [exibirCancelar, setExibirCancelar] = useState(false);
  const [datas, setDatas] = useState();
  const [relatorios, setRelatorios] = useState({
    lista: [],
    quantidade: 0,
    valorTotal: 0,
  });
  const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState(
    {}
  );
  const [statusFiltro, setStatusFiltro] = useState([
    'pending',
    'paid',
    'canceled',
  ]);
  const [metodoPg, setMetodoPg] = useState();
  const [pedido, setPedido] = useState();
  const [pedidoII, setPedidoII] = useState();
  const [registro, setRegistro] = useState();
  const [registroII, setRegistroII] = useState();
  const [pagina, setPagina] = useState(1);
  const [carregando, setCarregando] = useState(false);
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);
  const [idFatura, setIdFatura] = useState('');

  const [dataPg, setDataPg] = useState();

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const [orderBy, setOrderBy] = useState('');

  const buscarRelatorios = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.get('iugu/faturas', {
        params: {
          registro,
          metodoPg,
          pedido,
          dataPg,
          datas,
          pagina,
          status: statusFiltro,
          id_iugu: estabelecimentoSelecionado.id_iugu || null,
          orderBy,
        },
      });
      const lista = resposta.data.lista.map((fatura) => ({
        ...fatura,
        data_fatura: format(parseISO(fatura.data_fatura), 'dd/MM/yyyy'),
        data_vencimento: format(parseISO(fatura.data_vencimento), 'dd/MM/yyyy'),
        data_pagamento: fatura.data_pagamento
          ? format(parseISO(fatura.data_pagamento), 'dd/MM/yyyy')
          : 'Não Pago',
        numero_pedido: fatura.pedido ? fatura.pedido.numero_pedido : '-',
        metodo_pagamento: fatura.metodo_pagamento
          ? fatura.metodo_pagamento === 'iugu_credit_card'
            ? 'Crédito'
            : fatura.metodo_pagamento === 'iugu_bank_slip'
              ? 'Boleto'
              : 'Pix'
          : '-',
        nome_fantasia: fatura.estabelecimento
          ? fatura.estabelecimento.nome_fantasia
          : '-',
        pdf: `${fatura.url}.pdf`,
        valor_cents: fatura.valor.includes('BRL')
          ? `R$ ${fatura.valor.replace('BRL', '')}`
          : fatura.valor,
      }));
      setRelatorios({
        lista,
        quantidade: lista.length > 0 ? lista[0].full_count : 0,
      });
      if(relatorios.quantidade === 0) setPagina(1)
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, [
    pagina,
    dataPg,
    registro,
    pedido,
    metodoPg,
    statusFiltro,
    datas,
    estabelecimentoSelecionado,
    orderBy,
    relatorios.quantidade
  ]);

  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos'
  );

  useEffect(() => {
    buscarRelatorios();
  }, [buscarRelatorios]);

  async function cancelarFatura(id) {
    setCarregando(true);
    setExibirCancelar(false);
    await api
      .put(`iugu/faturas/cancelar/${id}`)
      .then(async () => {
        await buscarRelatorios();
        setCarregando(false);
        toast.success('Fatura cancelada com sucesso!');
      })
      .catch((err) => {
        toast.error(err.message);
        setCarregando(false);
      });
  }

  async function verificarIugu() {
    setCarregando(true);
    await api
      .get('/iugu/faturas/verificarBanco')
      .then(async (response) => {
        toast.success(response.data.message);
        await buscarRelatorios();
      })
      .catch((err) => {
        toast.error(err.message);
        setCarregando(false);
      });
  }

  async function gerarFaturasSemanais() {
    setCarregando(true);
    await api
      .get('/iugu/faturas/verificarBanco')
      .then(async (response) => {
        toast.success(response.data.message);
        await buscarRelatorios();
      })
      .catch((err) => {
        toast.error(err.message);
        setCarregando(false);
      });
  }

  const speaker = (
    <Popover>
      <p>
        - Usar se houver faturas na iugu que não estejam em nossa base de dados.
      </p>
      <p>- Limite de 100 faturas.</p>
      <p>
        - Se houver um número maior que o limite, entrar em contato com os
        desenvolvedores.
      </p>
    </Popover>
  );
  return (
    <Container>
      <h2>Faturas</h2>

      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <InputGroup
          style={{ width: '300px' }}
        >
          <AutoComplete
            placeholder="Nome do Estabelecimento"
            width={200}
            data={nomesEstabelecimentos.map((p) => ({
              label: p.nome_fantasia,
              value: p.nome_fantasia,
              id_iugu: p.id,
            }))}
            onSelect={async (value, item) => {
              setTimeout(() => {
                setEstabelecimentoSelecionado(item);
              }, 1000);
            }}
            onChange={(value) => {
              if (value !== '') {
                buscarNomesEstabelecimentos(value);
              } else {
                setEstabelecimentoSelecionado('');
                buscarRelatorios();
              }
            }}
          />
          <InputGroup.Button>
            <SearchIcon />
          </InputGroup.Button>
        </InputGroup>
        <InputDataRange
          ranges={[]}
          isoWeek
          oneTap
          locale={locale}
          onClean={() => {
            setDatas([]);
          }}
          onChange={setDatas}
          value={datas}
          hoverRange="week"
        />

        <InputDataRange
          placeholder="Data pgto"
          ranges={[]}
          isoWeek
          value={dataPg}
          locale={locale}
          onChange={(value) => setDataPg(value)}
        />

        <SelectPicker
          value={metodoPg}
          title="Filtro Metodo"
          toggleComponentClass={Button}
          appearance="default"
          searchable={false}
          placeholder="Forma pgto"
          data={[
            { label: 'Cartão de Crédito', value: 'iugu_credit_card' },
            { label: 'Boleto', value: 'iugu_bank_slip' },
            { label: 'Pix', value: 'iugu_pix' },
          ]}
          onChange={setMetodoPg}
        />

        <CheckPicker
          value={statusFiltro}
          title="Filtro Status"
          toggleComponentClass={Button}
          appearance="default"
          searchable={false}
          placeholder="Selecione Status"
          data={[
            { label: 'Pendente', value: 'pending' },
            { label: 'Pago', value: 'paid' },
            { label: 'Cancelado', value: 'canceled' },
            { label: 'Parcialmente Pago', value: 'partially_paid' },
            { label: 'Devolvido', value: 'refunded' },
            { label: 'Expirado', value: 'expired' },
            { label: 'Autorizado', value: 'authorized' },
          ]}
          onChange={setStatusFiltro}
        />

        <InputGroup
          style={{ width: '150px' }}
        >
          <Input
            value={pedidoII}
            placeholder="N. Pedido"
            onChange={(value) => {
              setPedidoII(value);
              if (value.replace(/[a-z]+|[A-Z]+|-/gm, '').length > 4)
                setPedido(value.replace(/[a-z]+|[A-Z]+|-/gm, ''));
              if (value.length === 0) setPedido(value);
            }}
          />
          <InputGroup.Button>
            <SearchIcon />
          </InputGroup.Button>
        </InputGroup>

        <InputGroup
          style={{ width: '150px' }}
        >
          <Input
            value={registroII}
            placeholder="N. Registro"
            onChange={(value) => {
              setRegistroII(value);
              if (value.replace(/[a-z]+|[A-Z]+|-/gm, '').length > 4)
                setRegistro(value.replace(/[a-z]+|[A-Z]+|-/gm, ''));
              if (value.length === 0) setRegistro(value);
            }}
          />
          <InputGroup.Button>
            <SearchIcon />
          </InputGroup.Button>
        </InputGroup>

        <GerarFatura
          setCarregando={setCarregando}
          buscarRelatorios={buscarRelatorios}
        />
        <ButtonFullBlue
          appearance="primary"
          onClick={verificarIugu}
        >
          Atualizar Iugu
        </ButtonFullBlue>

        <Whisper
          placement="bottom"
          trigger="hover"
          controlId="control-id-hover"
          speaker={speaker}
        >
          <IconButton
            style={{ backgroundColor: 'transparent' }}
            size="xs"
            icon={<InfoOutlineIcon />}
          />
        </Whisper>
        {(role === 4 || role === 5) && (
          <GerarFaturasSemanais
            style={{ marg: 10 }}
            setCarregando={setCarregando}
          />
        )}
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '1510px' }}>
      <TablePagination
        carregando={carregando}
        dados={relatorios.lista}
        onChangePage={setPagina}
        quantidade={relatorios.quantidade}
        setOrderBy={setOrderBy}
      >
        <Column resizable width={80}>
          <HeaderCell>Opções</HeaderCell>
          <DropDownCell
            setExibir={setExibir}
            setIdFatura={setIdFatura}
            setExibirCancelar={setExibirCancelar}
            dataKey="id"
          />
        </Column>

        <Column resizable sortable width={100}>
          <HeaderCell>Status</HeaderCell>
          <CelulaFormatada dataKey="status" />
        </Column>

        <Column resizable sortable width={120}>
          <HeaderCell>Forma Pgto</HeaderCell>
          <CelulaFormatada dataKey="metodo_pagamento" />
        </Column>

        <Column resizable sortable width={100}>
          <HeaderCell>N. Pedido</HeaderCell>
          <CelulaFormatada dataKey="numero_pedido" />
        </Column>

        <Column resizable width={200} sortable>
          <HeaderCell>Nome Fantasia</HeaderCell>
          <CelulaFormatada dataKey="nome_fantasia" />
        </Column>

        <Column resizable width={180} sortable>
          <HeaderCell>Cpf/Cnpj</HeaderCell>
          <CelulaFormatada dataKey="cpf_cnpj" />
        </Column>

        <Column resizable width={140} sortable>
          <HeaderCell>Data de Criação</HeaderCell>
          <CelulaFormatada dataKey="data_fatura" />
        </Column>

        <Column resizable width={130} sortable>
          <HeaderCell>Vencimento</HeaderCell>
          <CelulaFormatada dataKey="data_vencimento" />
        </Column>

        <Column resizable width={130} sortable>
          <HeaderCell>Data Pgto</HeaderCell>
          <CelulaFormatada dataKey="data_pagamento" />
        </Column>

        <Column resizable width={70} sortable>
          <HeaderCell>Qtd</HeaderCell>
          <CelulaFormatada dataKey="quant_itens_fatura" />
        </Column>

        <Column resizable width={100} sortable>
          <HeaderCell>Valor</HeaderCell>
          <CelulaFormatada dataKey="valor_cents" />
        </Column>

        <Column resizable width={80}>
          <HeaderCell>Url</HeaderCell>
          <ActionCell dataKey="url" />
        </Column>
        <Column resizable width={80}>
          <HeaderCell>Baixar Pdf</HeaderCell>
          <ActionCell dataKey="pdf" />
        </Column>
      </TablePagination>
      </FlexboxGrid>
      <GerarSegundaVia
        exibir={exibir}
        setExibir={setExibir}
        setCarregando={setCarregando}
        idFatura={idFatura}
        buscarRelatorios={buscarRelatorios}
      />
      <Modal
        open={exibirCancelar}
        onClose={() => setExibirCancelar(false)}
        size="xs"
      >
        <Modal.Header>Cancelar fatura</Modal.Header>
        <Modal.Body>
          <p>Deseja realmente cancelar a fatura?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setExibirCancelar(false)} appearance="subtle">
            Cancelar
          </Button>
          <Button
            appearance="primary"
            type="button"
            onClick={() => {
              cancelarFatura(idFatura);
            }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
