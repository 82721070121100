/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, FlexboxGrid, Table, Toggle, SelectPicker, AutoComplete, InputGroup, Input } from 'rsuite';

import { useDispatch, useSelector } from 'react-redux';
import { Container } from '~/styles/tabela';


import {
  buscarFalhaNaCobrancaCount
} from '~/store/modulos/notificacao/actions';
import SearchInputTable from '~/components/SearchInputTable';
import { InputDataRange } from '~/components/InputDataRange';
import { TablePagination } from '~/components/TablePagination';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import useSearchNames from '~/services/useSearchNames';
import { Search } from '@rsuite/icons';
import { TogglesStatus } from './styles';
import { CelulaNotifcacao } from '../../Alertas/components/CelulaNotificacao';
import api from '../../../services/api';
import { DarkModeContext } from '../../../common/contexts/darkMode';

const { HeaderCell, Column, Cell } = Table;

function FalhaCobranca() {
  const [pagina, setPagina] = useState(1);
  const [estabelecimento, setEstabelecimento] = useState();
  const [status, setStatus] = useState();
  const [codigo, setCodigo] = useState();
  const [date, setDate] = useState([]);
  const [relatoriosFalhaCobranca, setRelatoriosFalhaCobranca] = useState({
    lista: [],
    quantidade: 0,
  });
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);

  const { toggleMode } = useContext(DarkModeContext)

  const [data, setData] = useState([]);
  const [textodigitado, setTextoDigitado] = useState();
  const [codigoSelecionado, setCodigoSelecionado] = useState();
  const notificacoes = useSelector((state) => state.notificacao.notificacoes);
  const atualizacaoGlobalTempoReal = useSelector((state) => state.notificacao.falhaNaCobrancaCount);
  const [carregando, setCarregando] = useState(true);
  const dispatch = useDispatch();



  const buscarFalhaCobrança = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.get('logs/iugu', {
        params: {
          pagina: 1,
          estabelecimento,
          status,
          codigo: codigoSelecionado,
          date,
        },
      });
      let count = 0;
      const lista = resposta.data.lista.map((fatura) => {
        const resp = {
          ...fatura,
          mensagem: fatura.info_message,
          data: format(parseISO(fatura.createdAt), 'dd/MM/yyyy'),
          status_Boolean: fatura.status !== "pendente",
        }
        if (fatura.status === "pendente") {
          count += 1;
        }
        return resp
      });
      dispatch(buscarFalhaNaCobrancaCount({ falhaNaCobrancaCount: count }))
      setRelatoriosFalhaCobranca({
        lista,
        quantidade: resposta.data.quantidade,
      });
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, [estabelecimento, status, codigoSelecionado, date]);

  useEffect(() => { buscarFalhaCobrança() }, [atualizacaoGlobalTempoReal])

  async function alteracaoStatus(e, value) {
    try {
      const statusValue = {
        status: !value ? 'resolvido' : 'pendente'
      }
      setCarregando(true);
      await api.put(`logs/iugu/${e.id}`, statusValue);
      setCarregando(false);
      buscarFalhaCobrança();
      toast.success('Status atualizado com sucesso');
    } catch (error) {
      toast.error(error.message);
    }
  }

  const CelulaAcaoStatus = ({ rowData, ...props }) => (
    <Cell {...props}>
      <TogglesStatus>
        <Toggle
          checkedChildren="Resolvido" unCheckedChildren="Pendente"
          defaultChecked={rowData.status_Boolean}
          onChange={() => alteracaoStatus(rowData, rowData.status_Boolean)}
        /></TogglesStatus>
    </Cell>
  );

  useEffect(() => {
    buscarFalhaCobrança();
  }, [pagina, codigoSelecionado, date, estabelecimento, status]);

  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos'
  );

  return (
    <Container>
      <h2>Falha na Cobrança</h2>
      <FlexboxGrid align="middle" style={{ gap: '10px' }}>
        <SearchInputTable estilo={{ width: '200px' }} place='Estabelecimento' setTextoDigitado={setEstabelecimento} />
        <SearchInputTable estilo={{ width: '120px' }} place='Código' setTextoDigitado={setCodigoSelecionado} />

        <InputDataRange
          // format="DD/MM/YYYY"
          ranges={[]}
          // placeholder="Data"
          value={date}
          onChange={setDate}
        />
        <SelectPicker
          value={status}
          title="Filtro Status"
          toggleComponentClass={Button}
          appearance="default"
          searchable={false}
          placeholder="Status de Pagamento"
          data={[
            { label: 'Resolvidos', value: 'resolvido' },
            { label: 'Pendentes', value: 'pendente' },
          ]}
          onChange={setStatus}
        />
        <Button
          href='https://support.iugu.com/hc/pt-br/articles/206858953-Como-identificar-o-erro-da-tentativa-de-pagamento-'
          target="_blank"
          style={{ color: toggleMode ? '#fff' : '#0e385b', border: '1px solid #0e385b' }}
          // eslint-disable-next-line react/jsx-no-bind
          size="sm"
        >
          Lista de códigos de erro
        </Button>
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '1020px' }}>
        <TablePagination
          carregando={carregando}
          dados={relatoriosFalhaCobranca.lista}
          quantidade={relatoriosFalhaCobranca.quantidade}
          onChangePage={setPagina}
        >
          <Column resizable width={270}>
            <HeaderCell>Estabelecimento</HeaderCell>
            <Cell dataKey="estabelecimento" />
          </Column>
          <Column resizable width={300}>
            <HeaderCell>Mensagem</HeaderCell>
            <Cell dataKey="mensagem" />
          </Column>
          <Column resizable width={100}>
            <HeaderCell>Código</HeaderCell>
            <Cell dataKey="codigo" />
          </Column>
          <Column resizable width={150}>
            <HeaderCell>Data</HeaderCell>
            <Cell dataKey="data" />
          </Column>
          <Column resizable width={200}>
            <HeaderCell>Status</HeaderCell>
            <CelulaAcaoStatus dataKey='status_Boolean' />
          </Column>
        </TablePagination>
      </FlexboxGrid>
    </Container>
  );
}

export default FalhaCobranca
  ;
