/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Plus, Edit } from '@rsuite/icons';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import TrashIcon from '@rsuite/icons/Trash';

import { IconButton, Panel } from 'rsuite';
import api from '~/services/api';
import { Manual } from './styles';

import AdicionarCertificado from '../AdicionarCertificado';
import EditarCertificado from '../EditarCertificado';
import ExcluirCertificado from '../ExcluirCertificado';
import { DarkModeContext } from '../../../../common/contexts/darkMode';

function ListaCertificados({
  idProfissional,
  certificados,
  onListaAlterada,
  children,
}) {
  const [adicionarCertificadoDialog, setAdicionarCertificadoDialog] =
    useState(false);
  const [editarCertificadoDialog, setEditarCertificadoDialog] =
    useState(false);
  const [excluirCertificadoDialog, setExcluirCertificadoDialog] = useState({
    exibir: false,
    certificado: {},
  });
  const [idCertificado, setIdCertificado] = useState();

  const { toggleMode } = useContext(DarkModeContext);

  async function adicionarCerficiado({ arquivo, id_treinamento }) {
    setAdicionarCertificadoDialog(false);
    try {
      const formData = new FormData();
      formData.append('certificado', arquivo);
      await api.post(`/profissional/certificado/${idProfissional}`, formData, {
        params: {
          id_treinamento,
        },
      });
      toast.success('Certificado Adicionado');
      onListaAlterada();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function atualizarCertificado(id, aprovado) {
    try {
      await api.put(`/profissional/certificado/${id}`, { aprovado });
      toast.success('Certificado atualizado');
      onListaAlterada();
    } catch (e) {
      toast.error(e.message);
    }
  }


  async function editarTreinamento(treinamento) {
    const {new_id_treinamento} = treinamento
    try {
      await api.put(`/profissional/certificado_treinamentos/${idCertificado}`,
      { new_id_treinamento });
      toast.success('Treinamento atualizado');
      onListaAlterada();
    } catch (e) {
      toast.error(e.message);
    }
  }

  return (
    <Panel
      header={<h4>Certificados</h4>}
      eventKey="123"
      bordered
      collapsible
      defaultExpanded
    >
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
      >
        <h6>Adicionar Certificado</h6>
        <IconButton
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={(e) => {
            e.preventDefault();
            setAdicionarCertificadoDialog(true);
          }}
        />
      </div>
      {certificados &&
        certificados.map((certificado) => (
          <Manual key={certificado.id}>
            <div>
              <h5 style={{color: toggleMode && 'white' }}>Certificado: {certificado.treinamento.treinamento}</h5>
              <h6>Aprovado: {certificado.aprovado ? 'Sim' : 'Não'}</h6>
            </div>
            <div>
              <IconButton
                style={{ marginTop: '0' }}
                onClick={() => {
                //   window.open(certificado.arquivo.caminho);
                setEditarCertificadoDialog(true);
                setIdCertificado(certificado.id)
                }}
                icon={<Edit />}
                circle
                size="md"
              />
              <IconButton
                style={{ marginTop: '0' }}
                onClick={() => {
                  window.open(certificado.arquivo.caminho);
                }}
                icon={<FileDownloadIcon />}
                circle
                size="md"
              />
              {certificado.aprovado ? (
                <IconButton
                  style={{ marginTop: '0' }}
                  onClick={() => {
                    atualizarCertificado(certificado.id, !certificado.aprovado);
                  }}
                  icon={<CloseOutlineIcon />}
                  circle
                  size="md"
                />
              ) : (
                <IconButton
                  style={{ marginTop: '0' }}
                  onClick={() => {
                    atualizarCertificado(certificado.id, !certificado.aprovado);
                  }}
                  icon={<CheckRoundIcon />}
                  circle
                  size="md"
                />
              )}
              <IconButton
                style={{ marginTop: '0' }}
                onClick={() =>
                  setExcluirCertificadoDialog({
                    exibir: true,
                    certificado,
                  })
                }
                icon={<TrashIcon />}
                circle
                size="md"
              />
            </div>
          </Manual>
        ))}
      <AdicionarCertificado
        exibir={adicionarCertificadoDialog}
        fechar={() => setAdicionarCertificadoDialog(false)}
        salvar={adicionarCerficiado}
      />
      <EditarCertificado
        idCertificado={idCertificado}
        exibir={editarCertificadoDialog}
        fechar={() => setEditarCertificadoDialog(false)}
        salvar={editarTreinamento}
      />
      <ExcluirCertificado
        exibir={excluirCertificadoDialog.exibir}
        certificado={excluirCertificadoDialog.certificado}
        fechar={() => {
          setExcluirCertificadoDialog({
            exibir: false,
            certificado: {},
          });
        }}
        atualizar={onListaAlterada}
      />
      {children}
    </Panel>
  );
}

ListaCertificados.propTypes = {
  certificados: PropTypes.array,
  onListaAlterada: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  idProfissional: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ListaCertificados.defaultProps = {
  children: [],
  certificados: [],
  idProfissional: '',
};

export default ListaCertificados;
