import React, { useEffect, useState, useCallback } from 'react';
import { Table, SelectPicker, FlexboxGrid } from 'rsuite';
import { toast } from 'react-toastify';

import pt from 'date-fns/locale/pt-BR';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { Container } from '~/styles/tabela';
import api from '~/services/api';
import CelulaAcao from '~/components/Celulas/components/CelulaAcao';
import SearchInputTable from '~/components/SearchInputTable';
import SelectState from '~/components/SelectState';
import { TablePagination } from '~/components/TablePagination';
import EditarEstabelecimento from './EditarEstabelecimento';

const { HeaderCell, Column, Cell } = Table;

function Estabelecimentos() {
  const [estabelecimentos, setEstabelecimentos] = useState({
    lista: [],
    quantidade: 0,
  });
  const [estabelecimentoEditando, setEstabelecimentoEditando] = useState();
  const [showEditarEstabelecimento, setShowEditarEstabelecimento] =
    useState(false);
  const [estadoSelecionado, setEstadoSelecionado] = useState();
  const [carregando, setCarregando] = useState(true);
  const [textodigitado, setTextoDigitado] = useState();
  const [pagina, setPagina] = useState(1);
  const [atributoSelecionado, setAtributoSelecioando] =
    useState('nome_fantasia');

  const atributosFiltro = [
    {
      label: 'Nome Fantasia',
      value: 'nome_fantasia',
    },
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Cnpj',
      value: 'cpf_cnpj',
    },
    {
      label: 'Cidade',
      value: 'cidade',
    },
  ];

  const [orderBy, setOrderBy] = useState('order by data_criacao desc');

  const buscarEstabelecimentos = useCallback(async () => {
    setCarregando(true);
    if(estabelecimentos.quantidade > 0 && estabelecimentos.lista.length === 0) setPagina(1)
    try {
      const resposta = await api.get('estabelecimentos', {
        params: {
          [atributoSelecionado]: textodigitado,
          pagina,
          estado: estadoSelecionado,
          orderBy,
        },
      });
      setEstabelecimentos({
        quantidade: resposta.data.quantidade,
        lista: resposta.data.lista.map((cliente) => ({
          ...cliente,
          data_criacao: formatDistanceToNow(new Date(cliente.data_criacao), {
            locale: pt,
          }),
          estado: cliente.endereco.bairro.cidade.estado.uf,
          cidade: cliente.endereco.bairro.cidade.cidade,
        })),
      });
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, [atributoSelecionado, textodigitado, pagina, estadoSelecionado, orderBy, estabelecimentos.quantidade, setPagina]);

  useEffect(() => {
    buscarEstabelecimentos();
  }, [buscarEstabelecimentos]);

  function abrirEditarEstabelecimento(pedido) {
    setEstabelecimentoEditando(pedido);
    setShowEditarEstabelecimento(true);
  }

  function fecharEditarEstabelecimento() {
    buscarEstabelecimentos();
    setShowEditarEstabelecimento(false);
  }

  return (
    <Container>
      <h2>Clientes</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchInputTable
          estilo={{ marginRight: '0px', width: '300px' }}
          setTextoDigitado={setTextoDigitado} />
        <SelectPicker
          appearance="default"
          // style={{ marginRight: '15px' }}
          searchable={false}
          data={atributosFiltro}
          value={atributoSelecionado}
          cleanable={false}
          onChange={(value) => setAtributoSelecioando(value)}
        />
        <SelectState
          selectedState={estadoSelecionado}
          setSelectedStates={setEstadoSelecionado}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '1180px' }}>
        <TablePagination
          carregando={carregando}
          dados={estabelecimentos.lista}
          quantidade={estabelecimentos.quantidade}
          onChangePage={setPagina}
          setOrderBy={setOrderBy}
        >
          <Column resizable width={60}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onEditar={(estabelecimento) => {
                abrirEditarEstabelecimento(estabelecimento);
              }}
            />
          </Column>
          <Column resizable width={240} sortable>
            <HeaderCell>Nome Fantasia</HeaderCell>
            <Cell dataKey="nome_fantasia" />
          </Column>

          <Column resizable width={240} sortable>
            <HeaderCell>Email</HeaderCell>
            <Cell dataKey="email" />
          </Column>

          <Column resizable width={240} sortable>
            <HeaderCell>Cpf/Cnpj</HeaderCell>
            <Cell dataKey="cpf_cnpj" />
          </Column>
          <Column resizable width={150} sortable>
            <HeaderCell>Cidade</HeaderCell>
            <Cell dataKey="cidade" />
          </Column>
          <Column resizable width={100} sortable>
            <HeaderCell>Estado</HeaderCell>
            <Cell dataKey="estado" />
          </Column>
          <Column resizable width={150} sortable>
            <HeaderCell>Tempo Cadastro</HeaderCell>
            <Cell dataKey="data_criacao" />
          </Column>
        </TablePagination>
      </FlexboxGrid>
      <EditarEstabelecimento
        idEstabelecimento={
          estabelecimentoEditando && estabelecimentoEditando.id
        }
        showEditarEstabelecimento={showEditarEstabelecimento}
        setShowEditarEstabelecimento={setShowEditarEstabelecimento}
        // eslint-disable-next-line react/jsx-no-bind
        fecharEditarEstabelecimento={fecharEditarEstabelecimento}
      />
    </Container>
  );
}

export default Estabelecimentos;
