/* eslint-disable react/jsx-no-bind */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InfoRound } from '@rsuite/icons';

import {
  DateRangePicker,
  FlexboxGrid,
  Loader,
  AutoComplete,
  Table,
  Form,
  CheckPicker,
  Checkbox,
  IconButton,
  SelectPicker,
  RadioGroup,
  Radio,
  Toggle,
} from 'rsuite';
import { startOfMonth, endOfMonth } from 'date-fns';
import { toast } from 'react-toastify';
import { Container } from '~/styles/tabela';
import api from '~/services/api';
import InputAutocomplete from '~/components/InputAutocomplete';
import useSearchNames from '~/services/useSearchNames';
import { TablePagination } from '~/components/TablePagination';
import CelulaAcao from './components/CelulaAcao';
import DetalhesCarteiraDialog from '../CarteiraProfissionais/components/DetalhesCarteiraDialog';

import DetalhesCliente from './components/DetalhesCliente';
import { dadosCarteira } from '../../../store/modulos/carteiraClientes/actions';
import {
  FullInput,
  StyledTogglesFav,
  StyledTogglesBloq,
  DivToggles,
} from './styles';

const { HeaderCell, Column, Cell } = Table;

function Dashboard() {
  const dispatch = useDispatch();
  const dadosCliente = useSelector((state) => state.carteiraClientes);
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);
  const [experienciaSelecionada, setExperienciaSelecionada] = useState([]);
  const [proSelecionado, setProSelecionado] = useState();
  const [pagina, setPagina] = useState(1);
  const [favoritos, setFavoritos] = useState(false);
  const [bloqueados, setBloqueados] = useState(false);
  const [dataFiltro, setDataFiltro] = useState([
    startOfMonth(new Date(), { weekStartsOn: 1 }),
    endOfMonth(new Date(), { weekStartsOn: 1 }),
  ]);
  const [filterPro, setFilterPro] = useState(dadosCliente.pro);
  const anos = ['2021', '2022', '2023', '2024'].map(
    item => ({ label: item, value: Number(item) })
  );

  const [anoSelecionado, setAnoSelecionado] = useState();
  const [idEstabelecimentoFiltro, setIdEstabelecimentoFiltro] = useState();
  const [exibirDetalhesDialog, setExibirDetalhesDialog] = useState({
    exibir: false,
  });
  const [carregando, setCarregando] = useState(false);

  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos'
  );

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const [orderBy, setOrderBy] = useState('');

  // eslint-disable-next-line no-unused-vars
  const [ano, setAno] = useState(new Date().getFullYear());

  const buscarCarteriaClientes = useCallback(async () => {
    if (!idEstabelecimentoFiltro) return;
    setCarregando(true);
    try {
      const resposta = await api.get(
        `relatorios/carteira-clientes/${idEstabelecimentoFiltro}`,
        {
          params: {
            datas: dataFiltro,
            pagina,
            favoritos,
            bloqueados,
            experienciaSelecionada,
            proSelecionado,
            orderBy,
            ano
          },
        }
      );
      const lista = resposta.data.lista.map((i) => ({
        ...i,
        media: i.avaliacao ? i.avaliacao.toFixed(2) : '',
        profissional: i.profissional !== null ? i.profissional.nome : '',
        idProfissional: i.profissional !== null ? i.profissional.id : '',
        profissao: i.profissao.profissao,
        favorito: i.favorito,
        bloqueado: i.bloqueado,
      }));

      dispatch(
        dadosCarteira({
          ...resposta.data,
          profissionais: lista,
          services: resposta.data.services,
          pro: resposta.data.pro,
        })
      );
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, [
    pagina,
    idEstabelecimentoFiltro,
    dataFiltro,
    ano,
    favoritos,
    bloqueados,
    experienciaSelecionada,
    proSelecionado,
    orderBy,
  ]);

  useEffect(() => {
    buscarCarteriaClientes();
  }, [buscarCarteriaClientes]);

  useEffect(() => {
    if(dadosCliente.quantidade > 0 && dadosCliente.profissionais.length === 0) setPagina(1)
  }, [dadosCliente.quantidade]);


  function aproNull(value) {
    if (value !== '') {
      return proSelecionado;
    }
    return setProSelecionado('');
  }

  async function favoritarProfissional(profissional) {
    setCarregando(true);
    try {
      profissional.bloqueado &&
        (await api.post('relatorios/carteira-clientes/bloquear', {
          params: {
            idProfissional: profissional.idProfissional,
            idEstabelecimento: idEstabelecimentoFiltro,
          },
        }));
      await api.post('relatorios/carteira-clientes/favoritar', {
        params: {
          idProfissional: profissional.idProfissional,
          idEstabelecimento: idEstabelecimentoFiltro,
        },
      });
      buscarCarteriaClientes();
      toast.success('Status atualizado com sucesso!');
    } catch (error) {
      toast.error(error.message);
    }
    setCarregando(false);
  }
  async function bloquearProfissional(profissional) {
    setCarregando(true);
    try {
      profissional.favorito &&
        (await api.post('relatorios/carteira-clientes/favoritar', {
          params: {
            idProfissional: profissional.idProfissional,
            idEstabelecimento: idEstabelecimentoFiltro,
          },
        }));
      await api.post('relatorios/carteira-clientes/bloquear', {
        params: {
          idProfissional: profissional.idProfissional,
          idEstabelecimento: idEstabelecimentoFiltro,
        },
      });
      buscarCarteriaClientes();
      toast.success('Status atualizado com sucesso!');
    } catch (error) {
      toast.error(error.message);
    }
    setCarregando(false);
  }

  const [favbloq, setFavbloq] = useState({ favorito: false, bloqueado: false });

  const [proId, setProId] = useState();

  async function bloquearProfissionalTodos() {
    setCarregando(true);
    try {
      const dados = {
        params: {
          idProfissional: proId && proId,
          idEstabelecimento: idEstabelecimentoFiltro && idEstabelecimentoFiltro,
        },
      };
      const resultado = await api.post(
        'relatorios/carteira-clientes/verificarStatus',
        dados
      );
      resultado.data.favorito &&
        (await api.post('relatorios/carteira-clientes/favoritar', {
          params: {
            idProfissional: proId && proId,
            idEstabelecimento:
              idEstabelecimentoFiltro && idEstabelecimentoFiltro,
          },
        }));
      await api
        .post('relatorios/carteira-clientes/bloquear', {
          params: {
            idProfissional: proId,
            idEstabelecimento: idEstabelecimentoFiltro,
          },
        })
        .then(() =>
          setFavbloq({ favorito: false, bloqueado: !favbloq.bloqueado })
        );
      buscarCarteriaClientes();
      toast.success('Status atualizado com sucesso!');
    } catch (error) {
      toast.error(error.message);
    }
    setCarregando(false);
  }

  async function favoritarProfissionalTodos() {
    setCarregando(true);
    try {
      const dados = {
        params: {
          idProfissional: proId && proId,
          idEstabelecimento: idEstabelecimentoFiltro && idEstabelecimentoFiltro,
        },
      };
      const resultado = await api.post(
        'relatorios/carteira-clientes/verificarStatus',
        dados
      );

      resultado.data.bloqueado &&
        (await api.post('relatorios/carteira-clientes/bloquear', {
          params: {
            idProfissional: proId,
            idEstabelecimento: idEstabelecimentoFiltro,
          },
        }));
      await api
        .post('relatorios/carteira-clientes/favoritar', {
          params: {
            idProfissional: proId,
            idEstabelecimento: idEstabelecimentoFiltro,
          },
        })
        .then(() =>
          setFavbloq({ favorito: !favbloq.favorito, bloqueado: false })
        );

      buscarCarteriaClientes();
      toast.success('Status atualizado com sucesso!');
    } catch (error) {
      toast.error(error.message);
    }
    setCarregando(false);
  }

  const [nomes, setNomes] = useState([]);

  const TodosProfissionais = useSearchNames(setNomes, 'profissionais');

  function filtrarProfissionais(value) {
    setFilterPro(
      dadosCliente.pro.filter((prof) =>
        prof.nome.toLowerCase().includes(value.toLowerCase())
      )
    );
  }

  const [filtroSelecionado, setFiltroSelecionado] = useState('filtrados');

  async function favBloqInicial(id) {
    setProId(id);
    const dados = {
      params: {
        idProfissional: id && id,
        idEstabelecimento: idEstabelecimentoFiltro && idEstabelecimentoFiltro,
      },
    };
    const resultado = await api.post(
      'relatorios/carteira-clientes/verificarStatus',
      dados
    );
    setFavbloq(resultado.data);
  }

  return (
    <Container>
      <h2>Carteira Clientes</h2>
      <Form layout="horizontal">
        <FlexboxGrid justify="start" align="middle" style={{ gap: '10px' }}>
          <InputAutocomplete
            estilo={{ marginRight: '0px' }}
            name="estabelecimento"
            list={nomesEstabelecimentos}
            onchange={buscarNomesEstabelecimentos}
            setState={setIdEstabelecimentoFiltro}
            placeholder="Cliente"
            target="estabelecimentos"
          />

          <Form.Control
            accepter={DateRangePicker}
            name="data"
            ranges={[]}
            isoWeek
            locale={locale}
            onChange={setDataFiltro}
            format="yyyy-MM-dd"
            placeholder="Data"
            value={dataFiltro}
          />


          <FullInput style={{ paddingTop: '37px' }}>
            <div style={{ display: 'flex' }}>
              <Form.Control
                accepter={AutoComplete}
                name="Profissional"
                data={
                  filtroSelecionado === 'filtrados' && dadosCliente.pro
                    ? filterPro
                      .map((p) => ({
                        label: p.nome,
                        value: p.nome,
                        id: p.id,
                      }))
                      .slice(0, 10)
                    : filtroSelecionado === 'todos'
                      ? nomes
                        .map((p) => ({
                          label: p.nome,
                          value: p.nome,
                          id: p.id,
                        }))
                        .slice(0, 10)
                      : [{ label: '', value: '.nome_fantasia' }]
                }
                onSelect={(value, item) => {
                  favBloqInicial(item.id);
                  setProSelecionado(item.id);
                }}
                onChange={
                  filtroSelecionado === 'filtrados'
                    ? (value) => {
                      filtrarProfissionais(value);
                      aproNull(value);
                    }
                    : TodosProfissionais
                }
                placeholder="Profissional"
                style={{ marginRight: '15px', width: 143 }}
              />
              <DivToggles>
                <StyledTogglesFav filtroSelecionado={filtroSelecionado}>
                  <Toggle
                    checked={favbloq.favorito}
                    onChange={() => {
                      favoritarProfissionalTodos();
                    }}
                    size="sm"
                    unCheckedChildren="Favoritar"
                    checkedChildren="Desfavoritar"
                  />
                </StyledTogglesFav>
                <StyledTogglesBloq filtroSelecionado={filtroSelecionado}>
                  <Toggle
                    checked={favbloq.bloqueado}
                    onChange={() => {
                      bloquearProfissionalTodos();
                    }}
                    size="sm"
                    unCheckedChildren="Bloquear"
                    checkedChildren="Desbloquear"
                  />
                </StyledTogglesBloq>
              </DivToggles>
            </div>

            <RadioGroup
              onChange={(value) => {
                setFiltroSelecionado(value);
              }}
              value={filtroSelecionado}
              inline
              defaultValue="filtrados"
            >
              <Radio value="filtrados">Já trabalhou</Radio>
              <Radio value="todos">Todos</Radio>
            </RadioGroup>
          </FullInput>

          <CheckPicker
            style={{ width: 193 }}
            appearance="default"
            placeholder="Serviços"
            data={dadosCliente.services.map((services) => ({
              label: services.profissao,
              value: services.id,
            }))}
            value={experienciaSelecionada}
            onChange={(value) => setExperienciaSelecionada(value)}
          />

          {/* <SelectPicker
          placeholder="Ano"
          data={anos}
          searchable={false}
          value={anoSelecionado}
          onChange={(value) => {
            if(value !== null){
              setAno(value)
            }else {
              setAno(new Date().getFullYear())
            }
          }}
          /> */}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Checkbox
              onChange={() => setFavoritos(!favoritos)}
              checked={favoritos}
            >
              Favoritos
            </Checkbox>
            <Checkbox
              onChange={() => setBloqueados(!bloqueados)}
              checked={bloqueados}
            >
              Bloqueados
            </Checkbox>
          </div>
        </FlexboxGrid>
      </Form>

      <DetalhesCliente
      ano={ano}
      anos={anos}
      anoSelecionado={anoSelecionado}
      setAno={setAno}
      />
      <FlexboxGrid style={{ width: '1200px' }}>
        <TablePagination
          carregando={carregando}
          dados={dadosCliente.profissionais}
          quantidade={dadosCliente.quantidade || 0}
          onChangePage={setPagina}
          setOrderBy={setOrderBy}
        >
          <Column resizable width={100}>
            <HeaderCell>Detalhes</HeaderCell>
            <Cell dataKey="id" style={{ padding: '5px' }}>
              {(rowData) => (
                <IconButton
                  appearance="subtle"
                  onClick={() => {
                    setExibirDetalhesDialog({
                      exibir: true,
                      id_profissional: rowData.idProfissional,
                    });
                  }}
                  icon={<InfoRound />}
                />
              )}
            </Cell>
          </Column>
          <Column resizable width={240} sortable>
            <HeaderCell>Nome Profissional</HeaderCell>
            <Cell dataKey="profissional" />
          </Column>

          <Column resizable width={150} sortable>
            <HeaderCell>Média</HeaderCell>
            <Cell dataKey="media" />
          </Column>
          <Column resizable width={150} sortable>
            <HeaderCell>Shifts</HeaderCell>
            <Cell dataKey="shifts" />
          </Column>
          <Column resizable width={240} sortable>
            <HeaderCell>Serviço</HeaderCell>
            <Cell dataKey="profissao" />
          </Column>
          <Column resizable width={160}>
            <HeaderCell>Favoritar</HeaderCell>
            <CelulaAcao dataKey="favorito" onFavoritar={favoritarProfissional} />
          </Column>
          <Column resizable width={160}>
            <HeaderCell>Bloquear</HeaderCell>
            <CelulaAcao dataKey="bloqueado" onBloquear={bloquearProfissional} />
          </Column>
        </TablePagination>
      </FlexboxGrid>
      <DetalhesCarteiraDialog
        exibir={exibirDetalhesDialog.exibir}
        id_profissional={exibirDetalhesDialog.id_profissional}
        datas={dataFiltro}
        onFechar={() => setExibirDetalhesDialog({ exibir: false })}
      />
      {carregando && <Loader center size="lg" vertical />}
    </Container>
  );
}

export default Dashboard;
