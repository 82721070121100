/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-inner-declarations */
import React, { useEffect, useState, useCallback } from 'react';
import {
  Table,
  FlexboxGrid,
  SelectPicker,
  Col,
  Loader,
  CheckPicker,
} from 'rsuite';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { Container } from '~/styles/tabela';
import SearchInputTable from '~/components/SearchInputTable';
import api from '~/services/api';
import { InputData } from '~/components/InputData';
import { TablePagination } from '~/components/TablePagination';
import { useSelector, useDispatch } from 'react-redux';
import { getLocation as requestLocation } from '~/store/modulos/notificacao/actions';
import moment from 'moment';
import CelulaAcao from './components/CelulaAcao';
import CelulaEdit from './components/CelulaEdit';
import { CardsCheckin } from './components/CardCheckin';
import EditarPedido from '../EditarPedido';
import ModalEnviarNotificacaoCheckin from '../components/ModalNotificacao/ModalEnviarNoficacaoCheckin';
import { CelulaFormatada } from './components/CelulaFormatada';
import { Row } from './styles';
import MapModal from './components/MapModal';

const { HeaderCell, Column } = Table;

function RelatoriosProfissional() {
  const [filtroData, setFiltroData] = useState(new Date());
  const [relatorios, setRelatorios] = useState({
    lista: [],
    quantidade: 0,
  });
  const [checkin, setCheckin] = useState('0');
  const [pagina, setPagina] = useState(1);
  const [carregando, setCarregando] = useState(false);
  const [estados, setEstados] = useState([]);
  const [estadoSelecionado, setEstadoSelecionado] = useState();
  const [loading, setLoading] = useState(false);
  const [estabDigitado, setEstabDigitado] = useState();
  const [proDigitado, setProDigitado] = useState();
  const [servicoSelecionado, setServicoSelecionado] = useState();
  const [pedidoEditando, setPedidoEditando] = useState();
  const [filtroCidades, setFiltroCidades] = useState();
  const [showEditarPedido, setShowEditarPedido] = useState(false);
  const location = useSelector((state) => state.notificacao.location);
  const dispatch = useDispatch();

  const [lembreteEnviado, setLembreteEnviado] = useState()

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const [orderBy, setOrderBy] = useState(
    'order by reg.hora_inicio asc, pro.nome asc'
  );

  const buscarRelatorios = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.get('pedidos/registros-dia', {
        params: {
          diaSemana: filtroData,
          pagina,
          estado: estadoSelecionado,
          checkin,
          profissional: proDigitado,
          estab: estabDigitado,
          servico: servicoSelecionado,
          cidade: filtroCidades,
          orderBy,
        },
      });

      function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }

      if (resposta.data.lista) {
        const listaFormatada = resposta && resposta.data && resposta.data.lista.map((registros) => ({
          ...registros,
          data_formatada:
            registros.inicio !== ''
              ? format(new Date(registros.inicio), 'HH:mm')
              : '',
          reg: registros.id !== '' ? numberWithCommas(registros.id) : '',
          checkin_hora: registros.checkin_hora ? moment(registros.checkin_hora).format('HH:mm:ss') : null,
          envio_lembrete: registros.data_envio_lembrete ? moment(registros.data_envio_lembrete).format('HH:mm:ss') : null,
        }));

        // .map(reg => ({ ...reg, checkin_hora: moment(reg.checkin_hora).format('HH:mm:ss') }))

        let resulServise = [];
        if (resposta.data.servico.length > 0) {
          resulServise = resposta.data.servico.map((servico) => ({
            label: servico.profissao,
            value: servico.id,
          }));
        }
        console.log(resposta, 'resposta registroDia')
        const lista = resposta.data;
        console.log(lista, 'lista registroDia')

        setRelatorios({
          lista: listaFormatada,
          quantidade: lista && lista.lista && lista.lista.length > 0 ? lista.lista[0].full_count : 0,
          // quantidade: resposta.data.quantidade,
          checkinR: lista.checkinR,
          checkinAF: lista.checkinAF,
          cancelados: lista.cancelados,
          faltantes: lista.faltou,
          servico: resulServise,
        });
        if(relatorios.quantidade === 0) setPagina(1)

      } else {
        const listaVazia = {
          nome: ' ',
          telefone1: '',
          profissao: '',
        };
        setRelatorios({
          lista: listaVazia,
          quantidade: 0,
          checkinR: 0,
          checkinAF: 0,
          cancelados: 0,
          faltantes: 0,
          servico: '',
        });
      }

      const estados1 = await api.get('estado');
      setEstados(estados1.data);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, [
    pagina,
    filtroData,
    estadoSelecionado,
    checkin,
    estabDigitado,
    proDigitado,
    servicoSelecionado,
    filtroCidades,
    orderBy,
    relatorios.quantidade,
    setPagina
  ]);


  useEffect(() => {
    buscarRelatorios();
  }, [buscarRelatorios, location, lembreteEnviado]);


  function abrirEditarPedido(pedido) {
    setPedidoEditando(pedido);
    setShowEditarPedido(true);
  }

  function fecharEditarPedido() {
    buscarRelatorios();
    setShowEditarPedido(false);
  }

  async function realizarCheckin(lista) {
    if (lista.checkinii !== true)
      try {
        await api.put(`registros/checkin-adm/${lista.id}`);
        buscarRelatorios();
        toast.success('Status atualizado com sucesso!');
      } catch (error) {
        toast.error(error.message);
      }
    setCarregando(false);
  }

  async function getLocation(profissional_id, id_registro) {
    await api
      .get(`/profissional/location/${profissional_id}?id_registro=${id_registro}`)
      .then((result) => {
        toast.success(result.data);
        dispatch(requestLocation());
      })
      .catch((e) => {
        toast.error(e.message);
      });
  }

  // function alterColor(lista) {
  //   if (lista.status == 'Confirmado') {
  //     document.getElementById('teste').style.backgroundColor = 'green';
  //   }
  //   if (lista.status == 'Cancelado') {
  //     let Style = '';
  //     Style = 'padding: 10px 10px; background-color: red;';
  //     return Style;
  //   }
  // }
  return (
    <Container>
      <h4>Registros do Dia</h4>
      <FlexboxGrid style={{ gap: '10px' }} justify="start">
        <FlexboxGrid.Item as={Col} col={4}>
          <InputData
            cleanable={false}
            style={{ marginRight: "0px" }}
            ranges={[]}
            isoWeek
            oneTap
            locale={locale}
            value={filtroData}
            onChange={setFiltroData}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item as={Col} col={4}>
          <SelectPicker
            appearance="default"
            // style={{ marginRight: '15px' }}
            placeholder="Estado"
            data={estados.map((estado) => ({
              label: estado.uf,
              value: estado.id,
            }))}
            value={estadoSelecionado}
            onChange={(value) => setEstadoSelecionado(value)}
          />
        </FlexboxGrid.Item>
        <SearchInputTable
          setTextoDigitado={setProDigitado}
          place="Profissional"
          estilo={{
            width: '200px',
            // marginLeft: '15px', // marginRight: '15px',
          }}
        />
        <SearchInputTable
          setTextoDigitado={setEstabDigitado}
          estilo={{
            width: '200px',
            // marginLeft: '15px', // marginRight: '15px',
          }}
          place="Estabelecimento"
        />
        <SearchInputTable
          setTextoDigitado={setFiltroCidades}
          estilo={{
            width: '200px',
            // marginLeft: '15px', // marginRight: '15px',
          }}
          place="Cidade"
        />
        <CheckPicker
          style={{ width: 193 }}
          appearance="default"
          placeholder="Serviços"
          data={relatorios.servico}
          value={servicoSelecionado}
          onChange={(value) => setServicoSelecionado(value)}
        />
        <ModalEnviarNotificacaoCheckin
          filtroData={filtroData}
        />
      </FlexboxGrid>

      <FlexboxGrid>
        <FlexboxGrid.Item as={Col} col={16}>
          <Row>
            <CardsCheckin
              checkinAF={relatorios.checkinAF}
              checkinR={relatorios.checkinR}
              setCheckin={setCheckin}
              checkin={checkin}
              texto_1="Feito"
              texto_2="A fazer"
              titulo="Checkin"
            />
            <CardsCheckin
              cancelados={relatorios.cancelados}
              faltantes={relatorios.faltantes}
              checkinAF={relatorios.checkinAF}
              checkinR={relatorios.checkinR}
              setCheckin={setCheckin}
              checkin={checkin}
              texto_1="Cancelados"
              texto_2="Faltas"
              titulo="Cancelamentos"
            />
          </Row>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '1560px' }}>
      <TablePagination
        carregando={carregando}
        dados={relatorios.lista}
        quantidade={relatorios.quantidade}
        onChangePage={setPagina}
        setOrderBy={setOrderBy}
      >
        <Column resizable width={60}>
          <HeaderCell>Editar</HeaderCell>
          <CelulaEdit
            dataKey="id_registro"
            onEditar={(pedido) => {
              abrirEditarPedido(pedido);
            }}
          />
        </Column>
        <Column resizable width={190} sortable>
          <HeaderCell>Nome</HeaderCell>
          <CelulaFormatada dataKey="nome" />
        </Column>
        <Column resizable width={150}>
          <HeaderCell>Telefone</HeaderCell>
          <CelulaFormatada dataKey="telefone1" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Serviços</HeaderCell>
          <CelulaFormatada dataKey="profissao" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Estabelecimento</HeaderCell>
          <CelulaFormatada dataKey="estab" />
        </Column>
        <Column resizable width={70} sortable>
          <HeaderCell>Inicio</HeaderCell>
          <CelulaFormatada dataKey="data_formatada" />
        </Column>
        <Column resizable width={100}>
          <HeaderCell>Nº Registro</HeaderCell>
          <CelulaFormatada dataKey="reg" />
        </Column>
        <Column resizable width={120} sortable>
          <HeaderCell>Cidade</HeaderCell>
          <CelulaFormatada dataKey="cidade" />
        </Column>
        <Column resizable width={120} sortable>
          <HeaderCell>Status</HeaderCell>
          <CelulaFormatada dataKey="status" />
        </Column>
        <Column resizable width={100} sortable>
          <HeaderCell>Check In</HeaderCell>
          <CelulaFormatada dataKey="checkin" />
        </Column>
        <Column resizable width={100} sortable>
          <HeaderCell>Horário</HeaderCell>
          <CelulaFormatada dataKey="checkin_hora" />
        </Column>
        <Column resizable width={150}>
          <HeaderCell>Confirma</HeaderCell>
          <CelulaAcao
            loading={setLoading}
            dataKey="checkinii"
            onCheckin={realizarCheckin}
            getLocation={getLocation}
            setLembreteEnviado={setLembreteEnviado}
          />
        </Column>
        <Column resizable width={100}>
          <HeaderCell>Envio</HeaderCell>
          <CelulaFormatada dataKey="envio_lembrete" />
        </Column>

      </TablePagination>
      </FlexboxGrid>
      <EditarPedido
        idPedido={pedidoEditando && pedidoEditando.id_pedido}
        showEditarPedido={showEditarPedido}
        codPedido={pedidoEditando && pedidoEditando.numero_pedido}
        setShowEditarPedido={setShowEditarPedido}
        fecharEditarPedido={fecharEditarPedido}
      />
      <MapModal getLocation={getLocation} />
      {loading && (
        <Loader backdrop center content="Enviando Lembrete..." vertical />
      )}
    </Container>
  );
}

export default RelatoriosProfissional;
