/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Plus, RemindFill, Search } from '@rsuite/icons';

import {
  Table,
  Input,
  InputGroup,
  Button,
  FlexboxGrid,
  Modal,
  Form,
  IconButton,
  Schema,
  SelectPicker,
} from 'rsuite';
import { useDebouncedCallback } from 'use-debounce/lib';
import { toast } from 'react-toastify';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import { CelulaEditavel } from '~/components/Celulas/components/CelulaEditavel';
import SelectState from '~/components/SelectState';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';
import { CelulaAcaoHabilidade } from './components/CelulaEditavel';
import { ResponsiveFormTextArea } from '../../../components/ResponsiveForm';

const { HeaderCell, Column, Cell } = Table;

const { StringType, NumberType } = Schema.Types;

const modelo = Schema.Model({
  motivo_cancelamento: StringType().isRequired(
    'Motivo de cancelamento obrigatório.'
  ),
  tipo_cancelamento: StringType().isRequired(
    'Tipo de cancelamento obrigatório.'
  ),
});

function Habilidades() {
  const [dados, setDados] = useState({
    listaInicial: [],
    habilidadeLista: [],
    habilidadeListaFiltrada: [],
    profissaoLista: [],
    filtro: '',
  });
  const [apagarHabilidadeDialog, setApagarHabilidadeDialog] = useState({
    exibir: false,
    habilidade: {},
  });
  const [editarHabilidadeDialog, setEditarHabilidadeDialog] = useState({
    exibir: false,
    habilidade: {},
  });
  const [carregando, setCarregando] = useState(true);
  const [adicionarHabilidadeDialog, setHabilidadeDialog] = useState(false);
  const [camposInput, setCamposInput] = useState({});
  // const [pagina, setPagina] = useState(1);
  // const [orderBy, setOrderBy] = useState('');
  const [atributoSelecionado, setAtributoSelecionado] = useState('');
  const [tipoSelecionado, seTipoSelecionado] = useState('estabelecimento');
  // const [activePage, setActivePage] = useState(5);

  const tiposCancelamentos = [
    {
      label: 'Profissional',
      value: 'profissional',
    },
    {
      label: 'Estabelecimento',
      value: 'estabelecimento',
    },
    {
      label: 'Admin',
      value: 'admin',
    },
  ];
  async function buscarHabilidades() {
    try {
      const paramsBusca = {
        tipo: tipoSelecionado,
        descricao: atributoSelecionado,
      };
      const resposta = await api.get('/tipos-cancelamento', {
        params: paramsBusca,
      });
      const resposta1 = await api.get('/tipos-cancelamento', {
        params: paramsBusca,
      });
      const lista = resposta.data.map((motivo) => ({
        ...motivo,
      }));
      setDados({
        listaInicial: resposta1.data,
        habilidadeLista: lista,
        habilidadeListaFiltrada: lista,
      });
      setCarregando(false);
    } catch (e) {
      setCarregando(false);
      toast.error(e.message);
    }
  }

  useEffect(() => {
    buscarHabilidades();
  }, [tipoSelecionado, atributoSelecionado]);

  function abrirApagarDialog(habilidade) {
    setApagarHabilidadeDialog({
      exibir: true,
      habilidade,
    });
  }

  function fecharApagarDialog() {
    setApagarHabilidadeDialog({
      exibir: false,
      habilidade: {},
    });
  }

  function abrirEditarDialog(habilidade) {
    setEditarHabilidadeDialog({
      exibir: true,
      habilidade,
    });
  }

  function fecharEditarDialog() {
    setEditarHabilidadeDialog({
      exibir: false,
      habilidade: {},
    });
  }

  async function excluirMotivo() {
    try {
      await api.delete(
        `/tipos-cancelamento/${apagarHabilidadeDialog.habilidade.id}`
      );
      toast.success('Motivo excluido com sucesso');
      fecharApagarDialog();
      buscarHabilidades();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function adicionarMotivoCancelamento() {
    try {
      setCarregando(true);
      await api.post(`/tipos-cancelamento`, {
        descricao: camposInput.motivo_cancelamento,
        tipo: camposInput.tipo_cancelamento,
      });
      toast.success('Motivo Adicionado com sucesso');
      setCarregando(false);
      setHabilidadeDialog(false);
      buscarHabilidades();
    } catch (e) {
      toast.error(e.message);
    }
  }
  async function editarMotivo() {
    const newMotivo = {
      tipo: camposInput.tipo_cancelamento,
      descricao: camposInput.motivo_cancelamento,
    };
    try {
      setCarregando(true);
      await api.put(
        `/tipos-cancelamento/${editarHabilidadeDialog.habilidade.id}`,
        newMotivo
      );
      toast.success('Habilidade editada com sucesso');
      setCarregando(false);
      buscarHabilidades();
      fecharEditarDialog(false);
    } catch (e) {
      toast.error(e.message);
    }
  }

  function alterarTextoCelula(id, key, value) {
    const novaLista = Object.assign([], dados.habilidadeLista);
    novaLista.find((item) => item.id === id)[key] = value;
    setDados({ ...dados, habilidadeLista: novaLista });
  }

  // async function alterarEstadoCelula(id) {
  //   const novaLista = Object.assign([], dados.habilidadeLista);
  //   const item = novaLista.find((habilidade) => habilidade.id === id);
  //   const itemInicial = dados.listaInicial.find(
  //     (habilidade) => habilidade.id === id
  //   );
  //   if (item.status === 'EDIT' && item.habilidade !== itemInicial.habilidade) {
  //     try {
  //       setCarregando(true);
  //       await api.put(`habilidade/${id}`, item);
  //       toast.success('Habilidade atualizada com sucesso');
  //       buscarHabilidades();
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //     setCarregando(false);
  //   } else {
  //     item.status = item.status ? null : 'EDIT';
  //     setDados({ ...dados, habilidadeLista: novaLista });
  //     filtrarLista(dados.filtro);
  //   }
  // }

  return (
    <Container>
      <h2>Motivos Cancelamento</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <InputGroup style={{ width: '300px' }}>
          <Input
            name="habilidade"
            style={{ width: '180px' }}
            placeholder="Motivos"
            onChange={
              useDebouncedCallback(setAtributoSelecionado, 500).callback
            }
            onFocus={() => setAtributoSelecionado('')}
          />
          <InputGroup.Button>
            <Search />
          </InputGroup.Button>
        </InputGroup>

        <InputGroup style={{ width: '250px' }}>
          <SelectPicker
            appearance="default"
            style={{ width: '2000px' }}
            searchable={false}
            placeholder="Tipo de Motivo"
            data={tiposCancelamentos}
            value={tipoSelecionado}
            onChange={(value) => seTipoSelecionado(value)}
          />
          <InputGroup.Button>
            <Search />
          </InputGroup.Button>
        </InputGroup>

        <IconButton
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setHabilidadeDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '880px' }}>
        <TabelaSemPaginacao
          carregando={carregando}
          dados={dados.habilidadeListaFiltrada}
        >
          <Column resizable width={340} sortable>
            <HeaderCell>Motivos</HeaderCell>
            <CelulaEditavel dataKey="descricao" onChange={alterarTextoCelula} />
          </Column>

          <Column resizable width={340} sortable>
            <HeaderCell>Tipo</HeaderCell>
            <Cell dataKey="tipo" />
          </Column>

          <Column resizable width={200}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcaoHabilidade
              dataKey="id"
              onClick={abrirEditarDialog}
              onDelete={abrirApagarDialog}
            />
          </Column>
        </TabelaSemPaginacao>
      </FlexboxGrid>

      <Modal
        backdrop="static"
        open={adicionarHabilidadeDialog}
        onClose={() => setHabilidadeDialog(false)}
        size="xs"
      >
        <Form
          model={modelo}
          onChange={setCamposInput}
          onSubmit={(status) => status && adicionarMotivoCancelamento()}
        >
          <Modal.Header>
            <h3>Adicionar Motivo</h3>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.ControlLabel>Motivo</Form.ControlLabel>
              <Form.Control name="motivo_cancelamento" />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>Tipo</Form.ControlLabel>
              <Form.Control
                name="tipo_cancelamento"
                accepter={SelectPicker}
                placeholder="Selecione"
                data={tiposCancelamentos}
                style={{ width: '300px' }}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => setHabilidadeDialog(false)}
              appearance="subtle"
            >
              Cancelar
            </Button>
            <ButtonFullBlue type="submit" appearance="primary">
              Adicionar
            </ButtonFullBlue>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        backdrop="static"
        open={editarHabilidadeDialog.exibir}
        onCLose={fecharEditarDialog}
        size="xs"
      >
        <Form
          model={modelo}
          onChange={setCamposInput}
          onSubmit={(status) => status && editarMotivo()}
        >
          <Modal.Header>
            <h3>Editar Motivo</h3>
          </Modal.Header>
          <Modal.Body>
            <Modal.Body>
              <Form.Group>
                <Form.ControlLabel>Motivo</Form.ControlLabel>

                <Form.Control
                  name="motivo_cancelamento"
                  defaultValue={
                    editarHabilidadeDialog.habilidade.descricao || ''
                  }
                />
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>Tipo</Form.ControlLabel>
                <Form.Control
                  defaultValue={editarHabilidadeDialog.habilidade.tipo || ''}
                  name="tipo_cancelamento"
                  accepter={SelectPicker}
                  placeholder="Selecione"
                  data={tiposCancelamentos}
                  style={{ width: '300px' }}
                />
              </Form.Group>
            </Modal.Body>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={fecharEditarDialog} appearance="subtle">
              Cancelar
            </Button>
            <ButtonFullBlue onClick={() => editarMotivo()} appearance="primary">
              Editar
            </ButtonFullBlue>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        backdrop="static"
        open={apagarHabilidadeDialog.exibir}
        onCLose={fecharApagarDialog}
        size="xs"
      >
        <Modal.Header>
          <h3>Apagar Motivo</h3>
        </Modal.Header>
        <Modal.Body>
          Você realmente deseja apagar este movito? <br /> <br />
          id: {apagarHabilidadeDialog.habilidade.id} <br />
          motivo: {apagarHabilidadeDialog.habilidade.descricao} <br />
          Essa ação é irreversível{' '}
          <RemindFill
            style={{
              color: '#ffb300',
              fontSize: 24,
            }}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={fecharApagarDialog} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue onClick={() => excluirMotivo()} appearance="primary">
            Deletar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Habilidades;
