/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import { format, parseISO, eachDayOfInterval } from 'date-fns';
import { InfoRound, Search } from '@rsuite/icons';

import {
  Table,
  Input,
  InputGroup,
  FlexboxGrid,
  SelectPicker,
  Checkbox,
  AutoComplete,
  RadioGroup,
  Radio,
  Whisper,
  Popover,
  IconButton,
} from 'rsuite';
import { useSelector } from 'react-redux';
import pt from 'date-fns/locale/pt-BR';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce/lib';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { TablePagination } from '~/components/TablePagination';
import CelulaAcao from '~/components/Celulas/components/CelulaAcao';
import CelulaAcaoPedidos from '~/components/Celulas/components/CelulaAcaoPedidos';
import CelulaVisualizado from '~/components/Celulas/components/CelulaVisualizado';
import { Container } from '~/styles/tabela';
import { ButtonBorderBlue } from '~/components/Buttons/ButtonBorderBlue/styles';
import api from '~/services/api';
import formatCurrency from '~/utils/formatCurrency';
import { InputDataRange } from '~/components/InputDataRange';
import useSearchNames from '~/services/useSearchNames';
import EditarPedido from './EditarPedido';
import { CelulaStatusFormatada } from './components/ListaRegistro/components/CelulaStatusFormatada';
import CardPedidos from '../../components/CardsPedidos';
import AutorizarPedidosAbertos from './components/AutorizarPedidosAbertos';
import FinalizarPedidosConfirmado from './components/FinalizarPedidosConfirmados';
import ModalPdfEscala from './components/PDFEscala';

const { HeaderCell, Column, Cell } = Table;

function Pedidos() {
  const user = useSelector((state) => state.setUser.data);
  const { role } = user;
  const [profissoes, setProfissoes] = useState([]);
  const [selectedParams, setSelectedParams] = useState({ status: '' });
  const [pedidos, setPedidos] = useState({ lista: [], quantidade: 0 });
  const [filtroData, setFiltroData] = useState();
  const [datas, setDatas] = useState();
  const [filtroServico, setFiltroServico] = useState();
  const [filtroCheckin, setFiltroCheckin] = useState(false);
  const [filtroFavoritos, setFiltroFavoritos] = useState(false);
  const [filtroTrabalhou, setFiltroTrabalhou] = useState(false);
  const [pagina, setPagina] = useState([]);
  const [statusSelecionado, setStatusSelecionado] = useState('Aberto');
  const [filtroSelecionado, setFiltroSelecionado] = useState('numero_pedido');
  const [filtroRadioPro, setFiltroRadioPro] = useState('radio-pro-selecionado');
  const [filtroCidades, setFiltroCidades] = useState([]);
  const [filtroEstados, setFiltroEstados] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [filtroProfissional, setFiltroProfissional] = useState();
  const [pedidoEditando, setPedidoEditando] = useState();
  const [pedidoEditandoId, setPedidoEditandoId] = useState();
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);
  const [nomesCidades, setNomesCidades] = useState([]);
  const [nomesEstados, setNomesEstados] = useState([]);
  const [nomesProfissionais, setNomesProfissionais] = useState([]);
  const [filtroEstabelecimento, setFiltroEstabelecimento] = useState();
  const [showEditarPedido, setShowEditarPedido] = useState(false);
  const [atualizar, setAtualizar] = useState(false);
  const [textodigitado, setTextoDigitado] = useState('');
  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const [orderBy, setOrderBy] = useState('order by id_pedido');

  const buscarPedidos = useCallback(async () => {
    setCarregando(true);
    const params = {
      pagina,
      data: filtroData,
      filtroServico,
      [filtroSelecionado]: textodigitado,
      status: statusSelecionado,
      idProfissional: filtroProfissional,
      aguardandoCheckin: filtroCheckin,
      idEstabelecimento: filtroEstabelecimento,
      idEstado: filtroEstados,
      idCidade: filtroCidades,
      favoritos: filtroFavoritos,
      trabalhou: filtroTrabalhou,
      orderBy,
    };
    setSelectedParams(params);
    try {
      let resposta;
      if (!filtroProfissional || filtroProfissional.length === 0) {
        resposta = await api.get('pedidos-adm', {
          params,
        });
      } else if (
        filtroRadioPro === 'radio-pro-registrado' &&
        filtroProfissional
      ) {
        resposta = await api.get('candidatura-pro', {
          params,
        });
      } else {
        resposta = await api.get('pedidos-adm', {
          params,
        });
      }
      const lista = resposta.data.lista.map((pedido) => ({
        ...pedido,
        nome_fantasia: pedido.nome_fantasia ? pedido.nome_fantasia : 'Excluído',
        id_estabelecimento: pedido.id_estabelecimento,
        uf: pedido.uf,
        visualizado: pedido.visualizado,
        data: format(parseISO(pedido.data), 'dd/LL/yyyy'),
        hora_inicio: format(new Date(pedido.hora_inicio), 'HH:mm'),
        hora_fim: format(new Date(pedido.hora_fim), 'HH:mm'),
        tempo_cadastro: formatDistanceToNow(new Date(pedido.data_criacao), {
          locale: pt,
        }),
        profissional: pedido.profissional ? pedido.profissional : '-',
        servico: pedido.profissao ? pedido.profissao : '-',
        // qtdRegistros: pedido.registros ? pedido.registros.length : '-',
        valor: pedido.valor ? formatCurrency(pedido.valor) : `R$ ${0}`,
      }));
      // setPedidos({
      //   lista,
      //   quantidade: resposta.data.quantidade,
      // });
      setPedidos({
        lista,
        quantidade: lista.length > 0 ? lista[0].full_count : 0,
      });
      if (pedidos.quantidade === 0) setPagina(1);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, [
    filtroData,
    pagina,
    statusSelecionado,
    filtroProfissional,
    filtroServico,
    filtroCheckin,
    filtroEstabelecimento,
    textodigitado,
    filtroFavoritos,
    filtroTrabalhou,
    filtroSelecionado,
    filtroRadioPro,
    filtroCidades,
    filtroEstados,
    orderBy,
    atualizar,
    pedidos.quantidade,
  ]);

  useEffect(() => {
    buscarPedidos();
  }, [buscarPedidos]);

  useEffect(() => {
    const buscarDadosApi = async () => {
      try {
        const resposta = await api.get('profissao');
        setProfissoes(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    buscarDadosApi();
  }, []);

  function abrirEditarPedido(pedido) {
    setPedidoEditando(pedido);
    setPedidoEditandoId(pedido.id);
    setShowEditarPedido(true);
  }

  function fecharEditarPedido() {
    setPedidoEditandoId();
    buscarPedidos();
    setShowEditarPedido(false);
  }

  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos',
    setFiltroEstabelecimento
  );

  const buscarNomescidades = useSearchNames(
    setNomesCidades,
    'cidades',
    setFiltroCidades
  );

  const buscarNomesEstados = useSearchNames(
    setNomesEstados,
    'estado',
    setFiltroEstados
  );

  const buscarNomesProfissionais = useSearchNames(
    setNomesProfissionais,
    'profissionais',
    setFiltroProfissional
  );

  function filtrarData(datasSelecionadas) {
    setDatas(datasSelecionadas);
    if (datasSelecionadas && datasSelecionadas.length !== 0) {
      const datasIntervalo = eachDayOfInterval({
        start: datasSelecionadas[0],
        end: datasSelecionadas[1],
      }).map((d) => d.toDateString());
      setFiltroData(datasIntervalo);
    } else {
      setFiltroData(null);
    }
  }

  const speaker = (
    <Popover>
      <p>Filtrar dados para Aguardando Check in </p>
    </Popover>
  );

  return (
    <Container>
      <h2>Pedidos</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <AutoComplete
          name="nome_estabelecimento"
          style={{ width: '180px' }}
          data={nomesEstabelecimentos.map((p) => ({
            label: p.nome_fantasia,
            value: p.nome_fantasia,
            id: p.id,
          }))}
          placeholder="Nome do Cliente"
          onSelect={async (value, item) => {
            setFiltroEstabelecimento(item.id);
          }}
          onChange={buscarNomesEstabelecimentos}
        />

        <SelectPicker
          value={statusSelecionado}
          placeholder="Status"
          searchable={false}
          data={[
            { value: 'Aberto', label: 'Aberto' },
            { value: 'Autorizado', label: 'Autorizado' },
            { value: 'Aceito', label: 'Aceito' },
            { value: 'Cancelado', label: 'Cancelado' },
            { value: 'Confirmado', label: 'Confirmado' },
            { value: 'Finalizado', label: 'Finalizado' },
          ]}
          onChange={setStatusSelecionado}
        />
        <InputDataRange
          ranges={[]}
          isoWeek
          value={datas}
          locale={locale}
          onChange={filtrarData}
        />
        <SelectPicker
          name="id_profissao"
          placeholder="Serviço"
          value={filtroServico}
          data={profissoes.map((p) => {
            return {
              label: p.profissao,
              value: p.id,
            };
          })}
          onChange={setFiltroServico}
        />
        <AutoComplete
          name="cidades"
          style={{ width: '180px' }}
          data={nomesCidades.map((p) => ({
            label: p.cidade,
            value: p.cidade,
            id: p.id,
          }))}
          placeholder="Cidade"
          onSelect={async (value, item) => {
            setFiltroCidades(item.id);
          }}
          onChange={buscarNomescidades}
        />
        <AutoComplete
          name="Estados"
          style={{ width: '180px' }}
          data={nomesEstados.map((p) => ({
            label: p.estado,
            value: p.estado,
            id: p.id,
          }))}
          placeholder="Estado"
          onSelect={async (value, item) => {
            setFiltroEstados(item.id);
          }}
          onChange={(value) => {
            if (!value) setFiltroEstados(null); // Limpa o filtro quando o input é limpo
            buscarNomesEstados(value);
          }}
        />
        <div>
          <InputGroup style={{ width: '180px' }}>
            <AutoComplete
              name="nome_profissional"
              width={100}
              data={nomesProfissionais.map((p) => ({
                label: p.nome,
                value: p.nome,
                id: p.id,
              }))}
              placeholder="Profissional"
              onSelect={async (value, item) => {
                setFiltroProfissional(item.id);
              }}
              onChange={buscarNomesProfissionais}
            />
          </InputGroup>
          <RadioGroup
            onChange={(value) => {
              setFiltroRadioPro(value);
            }}
            value={filtroRadioPro}
            inline
            defaultValue="radio-pro-selecionado"
          >
            <Radio
              style={{ marginRight: '-10px', fontSize: '12px' }}
              value="radio-pro-selecionado"
            >
              Selecionado
            </Radio>
            <Radio style={{ fontSize: '12px' }} value="radio-pro-registrado">
              Marcou
            </Radio>
          </RadioGroup>
        </div>
        <div>
          <InputGroup style={{ width: '200px' }}>
            <Input
              width={200}
              onChange={useDebouncedCallback(setTextoDigitado, 500).callback}
              placeholder="Digite o Número"
            />

            <InputGroup.Button>
              <Search />
            </InputGroup.Button>
          </InputGroup>
          <RadioGroup
            onChange={(value) => {
              setFiltroSelecionado(value);
            }}
            value={filtroSelecionado}
            inline
            defaultValue="numero_pedido"
          >
            <Radio style={{ fontSize: '12px' }} value="numero_pedido">
              Pedido
            </Radio>
            <Radio style={{ fontSize: '12px' }} value="numero_registro">
              Registro
            </Radio>
          </RadioGroup>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Checkbox
            value={filtroCheckin}
            style={{ margin: 0, padding: 0 }}
            onChange={(a, valor) => {
              setFiltroCheckin(valor);
            }}
            title="Filtrar dados por aguardando checkin"
          >
            Check in
          </Checkbox>
          <Checkbox
            value={filtroFavoritos}
            style={{ margin: 0, padding: 0 }}
            onChange={(a, valor) => {
              setFiltroFavoritos(valor);
            }}
            title="Filtrar dados por Profissionais Favoritos"
          >
            Favoritos
          </Checkbox>
          <Checkbox
            value={filtroFavoritos}
            style={{ margin: 0, padding: 0 }}
            onChange={(a, valor) => {
              setFiltroTrabalhou(valor);
            }}
            title="Filtrar profissionais que já trabalharam"
          >
            Já trabalhou lá
          </Checkbox>
        </div>
        <Whisper
          placement="bottom"
          trigger="hover"
          controlId="control-id-hover"
          speaker={speaker}
        >
          <IconButton
            style={{ backgroundColor: 'transparent' }}
            size="xs"
            icon={<InfoRound />}
          />
        </Whisper>
        <AutorizarPedidosAbertos buscarPedidos={buscarPedidos} />
        {role === 1 || role === 4 || role === 5 ? (
          <FinalizarPedidosConfirmado
            buscarPedidos={buscarPedidos}
            total={pedidos.quantidade}
            selectedParams={selectedParams}
          />
        ) : (
          ''
        )}
        <ModalPdfEscala />
      </FlexboxGrid>
      <CardPedidos
        data={new Date().toDateString()}
        placeholder="Nº Pedido/Nº Regristro"
        pedidos={pedidos}
        setData={filtrarData}
        setStatusSelecionado={setStatusSelecionado}
        fecharEditarPedido={fecharEditarPedido}
      />
      <FlexboxGrid style={{ width: '1520px' }}>
        <TablePagination
          carregando={carregando}
          dados={pedidos.lista}
          quantidade={pedidos.quantidade}
          onChangePage={setPagina}
          setOrderBy={setOrderBy}
        >
          <Column resizable width={90}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcaoPedidos
              dataKey="id_registro"
              onEditar={(pedido) => {
                abrirEditarPedido(pedido);
              }}
            />
          </Column>
          <Column resizable width={150} sortable>
            <HeaderCell>Nome Fantasia</HeaderCell>
            <CelulaStatusFormatada dataKey="nome_fantasia" />
          </Column>
          <Column resizable width={110} sortable>
            <HeaderCell>Data</HeaderCell>
            <CelulaStatusFormatada dataKey="data" />
          </Column>
          <Column resizable width={90} sortable>
            <HeaderCell>Início</HeaderCell>
            <CelulaStatusFormatada dataKey="hora_inicio" />
          </Column>
          <Column resizable width={100} sortable>
            <HeaderCell>Término</HeaderCell>
            <CelulaStatusFormatada dataKey="hora_fim" />
          </Column>
          <Column resizable width={200} sortable>
            <HeaderCell>Profissional</HeaderCell>
            <CelulaStatusFormatada dataKey="profissional" />
          </Column>
          <Column resizable width={150}>
            <HeaderCell>Serviço</HeaderCell>
            <CelulaStatusFormatada dataKey="servico" />
          </Column>
          <Column resizable width={140} sortable>
            <HeaderCell>Tempo Aberto</HeaderCell>
            <CelulaStatusFormatada dataKey="tempo_cadastro" />
          </Column>
          <Column resizable width={110}>
            <HeaderCell>Status</HeaderCell>
            <CelulaStatusFormatada dataKey="status" />
          </Column>
          {/* <Column resizable width={110}>
          <HeaderCell>Cidade</HeaderCell>
          <Cell dataKey="cidade" />
        </Column> */}
          <Column resizable width={80}>
            <HeaderCell>Estado</HeaderCell>
            <CelulaStatusFormatada dataKey="uf" />
          </Column>
          <Column resizable width={100}>
            <HeaderCell>Nº Pedido</HeaderCell>
            <CelulaStatusFormatada dataKey="numero_pedido" />
          </Column>
          <Column resizable width={100}>
            <HeaderCell>Valor</HeaderCell>
            <CelulaStatusFormatada dataKey="valor" />
          </Column>
          <Column resizable width={120}>
            <HeaderCell>Qtd Registros</HeaderCell>
            <CelulaStatusFormatada dataKey="contagem" />
          </Column>
        </TablePagination>
      </FlexboxGrid>

      <EditarPedido
        // idPedido={pedidoEditando && pedidoEditando.id}
        idPedido={pedidoEditandoId}
        showEditarPedido={showEditarPedido}
        codPedido={pedidoEditando && pedidoEditando.numero_pedido}
        setShowEditarPedido={setShowEditarPedido}
        fecharEditarPedido={fecharEditarPedido}
        setAtualizar={setAtualizar}
      />
    </Container>
  );
}

export default Pedidos;
