/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { format, parseISO, eachDayOfInterval, compareAsc } from 'date-fns';

import { InfoRound, Search, FileDownload } from '@rsuite/icons';

import { GerarCSV as CSV } from '~/utils/gerarCSV';
import {
  Table,
  Input,
  InputGroup,
  FlexboxGrid,
  SelectPicker,
  AutoComplete,
  RadioGroup,
  Toggle,
  IconButton,
} from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce/lib';
import { TablePagination } from '~/components/TablePagination';
import CelulaAcao from '~/components/Celulas/components/CelulaAcao';
import { Container } from '~/styles/tabela';
import { ModalPadrao } from '~/components/Modal';
import api from '~/services/api';
import formatCurrency from '~/utils/formatCurrency';
import { InputDataRange } from '~/components/InputDataRange';
import useSearchNames from '~/services/useSearchNames';
import { TogglesStatus } from './styles';
import EditarRecrutamento from './EditarRecrutamento';
import { LocalizacaoModal } from './components/modal/MapModal';
import FiltroCidades from '../Mensagens/components/FiltroCidades';
import FiltroBairros from '../Mensagens/components/FiltroBairros';
import { DarkModeContext } from '../../common/contexts/darkMode';
import { ExcluirRecrutamento } from './components/modal/ExcluirRecrutamento';
import { datasSelecionadasSemHorario } from '../../utils/datasSelecionadasSemHorario';
import ModalPdfRecrutamento from './components/RecrutamentoPdf/RecrutamentoPdf';

const { HeaderCell, Column, Cell } = Table;

function RecrutamentoESelecao() {
  const [profissoes, setProfissoes] = useState([]);
  const [recrutamento, setRecrutamento] = useState({
    lista: [],
    quantidade: 0,
  });
  const [datas, setDatas] = useState();
  const [filtroServico, setFiltroServico] = useState();
  const [pagina, setPagina] = useState(1);
  const [statusSelecionado, setStatusSelecionado] = useState();
  const [filtroCidades, setCidade] = useState([]);
  const [filtroBairros, setBairro] = useState([]);
  const [statusRecrutamento, setStatusRecrutamento] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [filtroProfissional, setFiltroProfissional] = useState();
  const [pedidoEditando, setPedidoEditando] = useState();
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);
  const [nomesProfissionais, setNomesProfissionais] = useState([]);
  const [filtroEstabelecimento, setFiltroEstabelecimento] = useState();
  const [showEditarPedido, setShowEditarPedido] = useState(false);
  const [textodigitado, setTextoDigitado] = useState('');
  const [exluirRecrutamento, setExluirRecrutamento] = useState({});
  const [modalExcluir, setModalExcluir] = useState(false);
  const [atualizarLista, setAtualizarLista] = useState(false);
  const [carregando2, setCarregando2] = useState(false);
  const [modalCSV, setModalCSV] = useState(false);
  const { toggleMode } = useContext(DarkModeContext);

  const modalTitulo = 'Gerar CSV';
  const modalTexto =
    'Tem certeza que deseja gerar este CSV com o filtro de data selecionado?';
  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const [orderBy, setOrderBy] = useState('order by id desc');

  const buscarRecrutamento = useCallback(async () => {
    let datasIntervalo = [];

    if (datas && datas.length !== 0) {
      const dataInicio = datas[0].toDateString();
      const dataFim = datas[1];
      datasIntervalo = [dataInicio, dataFim];
    }
    setCarregando(true);
    try {
      const resposta = await api.get('recrutamento', {
        params: {
          pagina,
          id_cliente: filtroEstabelecimento,
          status: statusSelecionado,
          data: datas,
          servico: filtroServico,
          cidade: filtroCidades,
          bairro: filtroBairros,
          profissional: filtroProfissional,
          numero_rs: textodigitado,
          orderBy,
        },
      });

      const data = resposta.data.lista.map((item) => ({
        ...item,
        nome_fantasia: item.nome_fantasia,
        funcao: item.profissao,
        quantidade: item.total_vagas,
        data_pedido: format(parseISO(item.data_solicitacao), 'dd/LL/yyyy'),
        salario: formatCurrency(item.salario),
        numero_pedido: item.numero_rs,
        status: Number(item.status),
        bairro: item.bairro,
        cidade: item.cidade,
        endereco_id: item.endereco_id,
        quantidadeCandidatos: item.contagem_candidatos
          ? item.contagem_candidatos
          : 0,
      }));

      setRecrutamento({
        lista: data,
        quantidade: data.length > 0 ? data[0].full_count : 0,
      });
      if (recrutamento.quantidade === 0) setPagina(1);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, [
    datas,
    pagina,
    statusSelecionado,
    filtroProfissional,
    filtroServico,
    // filtroCheckin,
    filtroEstabelecimento,
    textodigitado,
    // filtroSelecionado,
    filtroCidades,
    filtroBairros,
    orderBy,
    atualizarLista,
    recrutamento.quantidade,
    setPagina,
  ]);

  const relatorioCSVFechamento = useCallback(async () => {
    let datasEscolhidasSemHorario = '';
    if (datas) {
      datasEscolhidasSemHorario = datasSelecionadasSemHorario(datas);
    }
      setModalCSV(false);
      try {
        const resposta = await api.get('/recrutamento-csv', {
          params: {
            data: datasEscolhidasSemHorario,
            id_cliente: filtroEstabelecimento,
            status: statusSelecionado,
            servico: filtroServico,
            cidade: filtroCidades,
            bairro: filtroBairros,
            profissional: filtroProfissional,
            numero_rs: textodigitado,
          },
        });
        const lista = resposta.data.lista
          .sort((a, b) => compareAsc(parseISO(a.data), parseISO(b.data)))
          .map((item) => ({
            nome_fantasia: item.nome_fantasia,
            funcao: item.funcao,
            quantidade: item.total_vagas,
            tipo_estabelecimento: item.tipo_estabelecimento,
            data_pedido: format(parseISO(item.data_solicitacao), 'dd/LL/yyyy'),
            salario: formatCurrency(item.salario),
            status:
              item.recrutamentoStatus && item.recrutamentoStatus.status
                ? item.recrutamentoStatus.status
                : 0,
            estado: item.estado,
            bairro: item.bairro,
            cidade: item.cidade,
            logradouro: item.logradouro,
            cep: item.cep,
            numero: item.numero,
            gorjeta: formatCurrency(item.gorjeta)
              ? formatCurrency(item.gorjeta)
              : 0,
          }));
        if (!lista.length) {
          toast.error('Não há registros para essas datas.');
          return;
        }
        CSV({
          data: lista,
          download: true,
        });

        setCarregando2(false);
        toast.success(`Foram exportados ${lista.length} registros.`);
      } catch (e) {
        setCarregando2(false);
        toast.error('Erro ao Exportar os dados');
      }
  
  }, [
    datas,
    statusSelecionado,
    filtroProfissional,
    filtroServico,
    filtroEstabelecimento,
    textodigitado,
    filtroCidades,
    filtroBairros,
  ]);

  const buscarStatusRecrutamento = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.get('recrutamento-status');
      const data = resposta.data.map((item) => ({
        value: item.id,
        label: item.status,
      }));

      setStatusRecrutamento(data);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, []);

  useEffect(() => {
    buscarRecrutamento();
    buscarStatusRecrutamento();
  }, [buscarRecrutamento, buscarStatusRecrutamento]);

  useEffect(() => {
    const buscarDadosApi = async () => {
      try {
        const resposta = await api.get('profissao');
        setProfissoes(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    buscarDadosApi();
  }, []);

  function abrirEditarPedido(pedido) {
    setPedidoEditando(pedido);
    setShowEditarPedido(true);
  }

  function fecharEditarPedido() {
    buscarRecrutamento();
    setShowEditarPedido(false);
  }

  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos',
    setFiltroEstabelecimento
  );

  const buscarNomesProfissionais = useSearchNames(
    setNomesProfissionais,
    'profissionais',
    setFiltroProfissional
  );

  async function alteracaoStatus(e, value) {
    try {
      const statusValue = {
        id: e,
        status: value,
      };
      setCarregando(true);
      await api.patch(`recrutamento/status`, statusValue);
      setCarregando(false);
      buscarRecrutamento();
      toast.success('Status atualizado com sucesso');
    } catch (error) {
      toast.error(error.message);
    }
  }
  const CelulaAcaoStatus = ({ rowData, ...props }) => (
    <Cell {...props}>
      <SelectPicker
        size="xs"
        cleanable={false}
        value={rowData.status}
        placeholder="Status"
        searchable={false}
        data={statusRecrutamento}
        onChange={(value) => alteracaoStatus(rowData.id, value)}
      />
    </Cell>
  );

  return (
    <Container>
      <h2>Recrutamento</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <AutoComplete
          name="nome_estabelecimento"
          style={{ width: '180px' }}
          data={nomesEstabelecimentos.map((p) => ({
            label: p.nome_fantasia,
            value: p.nome_fantasia,
            id: p.id,
          }))}
          placeholder="Nome do Cliente"
          onSelect={async (value, item) => {
            setFiltroEstabelecimento(item.id);
          }}
          onChange={buscarNomesEstabelecimentos}
        />

        <SelectPicker
          value={statusSelecionado}
          placeholder="Status"
          searchable={false}
          data={statusRecrutamento}
          onChange={setStatusSelecionado}
        />
        <InputDataRange
          ranges={[]}
          // isoWeek
          value={datas}
          locale={locale}
          onChange={setDatas}
        />
        <SelectPicker
          name="id_profissao"
          placeholder="Serviço"
          value={filtroServico}
          data={profissoes.map((p) => {
            return {
              label: p.profissao,
              value: p.id,
            };
          })}
          onChange={setFiltroServico}
        />

        <Input
          style={{ width: '250px' }}
          placeholder="Cidade"
          onChange={useDebouncedCallback(setCidade, 500).callback}
        />

        <Input
          style={{ width: '250px' }}
          placeholder="Bairro"
          onChange={useDebouncedCallback(setBairro, 500).callback}
        />

        <div>
          <InputGroup style={{ width: '210px' }}>
            <AutoComplete
              name="nome_profissional"
              width={100}
              data={nomesProfissionais.map((p) => ({
                label: p.nome,
                value: p.nome,
                id: p.id,
              }))}
              placeholder="Profissional"
              onSelect={async (value, item) => {
                setFiltroProfissional(item.id);
              }}
              onChange={buscarNomesProfissionais}
            />
          </InputGroup>
        </div>
        <div>
          <InputGroup style={{ width: '140px' }}>
            <Input
              width={200}
              onChange={useDebouncedCallback(setTextoDigitado, 500).callback}
              placeholder="Número R"
            />

            <InputGroup.Button>
              <Search />
            </InputGroup.Button>
          </InputGroup>
        </div>
        <IconButton
          style={{ color: toggleMode ? '#fff' : '#575757' }}
          icon={<FileDownload />}
          onClick={() => setModalCSV(true)}
        >
          <p style={{ color: toggleMode ? '#fff' : '#575757' }}>
            CSV Recrutamento
          </p>
        </IconButton>
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '1460px' }}>
        <TablePagination
          carregando={carregando}
          dados={recrutamento.lista}
          quantidade={recrutamento.quantidade}
          onChangePage={setPagina}
          setOrderBy={setOrderBy}
        >
          <Column resizable width={60}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="numero_pedido"
              onEditar={(pedido) => {
                abrirEditarPedido(pedido);
              }}
            />
          </Column>
          <Column resizable width={250} sortable>
            <HeaderCell>Estabelecimento</HeaderCell>
            <Cell dataKey="nome_fantasia" />
          </Column>
          <Column resizable width={130} sortable>
            <HeaderCell>Função</HeaderCell>
            <Cell dataKey="funcao" />
          </Column>
          <Column resizable width={90} sortable>
            <HeaderCell>Vagas</HeaderCell>
            <Cell dataKey="quantidade" />
          </Column>
          <Column resizable width={120} sortable>
            <HeaderCell>Solicitação</HeaderCell>
            <Cell dataKey="data_pedido" />
          </Column>
          <Column resizable width={160} sortable>
            <HeaderCell>Salário</HeaderCell>
            <Cell dataKey="salario" />
          </Column>
          <Column resizable width={160} sortable>
            <HeaderCell>Bairro</HeaderCell>
            <Cell dataKey="bairro" />
          </Column>
          <Column resizable width={140} sortable>
            <HeaderCell>Cidade</HeaderCell>
            <Cell dataKey="cidade" />
          </Column>
          <Column resizable width={140}>
            <HeaderCell>Status</HeaderCell>
            <CelulaAcaoStatus dataKey="status" />
          </Column>
          <Column resizable width={90}>
            <HeaderCell>Qtd</HeaderCell>
            <Cell dataKey="quantidadeCandidatos" />
          </Column>
          <Column resizable width={120}>
            <HeaderCell align="left">Excluir</HeaderCell>
            <Cell dataKey="id" align="left" style={{ padding: '5px' }}>
              {(rowData) => (
                <>
                  <IconButton
                    appearance="subtle"
                    onClick={() => {
                      setExluirRecrutamento(rowData);
                      setModalExcluir(true);
                    }}
                    color="red"
                    icon={<TrashIcon />}
                  />
                </>
              )}
            </Cell>
          </Column>
        </TablePagination>
      </FlexboxGrid>

      <ModalPadrao
        openModal={modalCSV}
        carregando={carregando2}
        // csv
        setOpenModal={setModalCSV}
        title={modalTitulo}
        text={modalTexto}
        funcExec={relatorioCSVFechamento}
      />
      <EditarRecrutamento
        idPedido={pedidoEditando && pedidoEditando.id}
        showEditarPedido={showEditarPedido}
        codPedido={pedidoEditando && pedidoEditando.numero_pedido}
        setShowEditarPedido={setShowEditarPedido}
        fecharEditarPedido={fecharEditarPedido}
      />
      <ExcluirRecrutamento
        modalExcluir={modalExcluir}
        setModalExcluir={setModalExcluir}
        exluirRecrutamento={exluirRecrutamento}
        setAtualizarLista={setAtualizarLista}
      />
    </Container>
  );
}

export default RecrutamentoESelecao;

// const [exluirRecrutamento, setExluirRecrutamento] = useState({});
// const [modalExcluir, setModalExcluir] = useState(false);
