export default [
  {
    label: 'Profissionais',
    value: 'pro-all',
    children: [
      {
        label: 'Lista',
        value: 'pro-1',
      },
      {
        label: 'Lista Bloqueados',
        value: 'pro-2',
      },
      {
        label: 'Criar',
        value: 'pro-3',
      },
      {
        label: 'Carteira',
        value: 'pro-4',
      },
      {
        label: 'Disponibilidade Registrada',
        value: 'pro-5',
      },
      {
        label: 'Disponiveis Emergência',
        value: 'pro-6',
      },
    ],
  },
  {
    label: 'Clientes',
    value: 'estab-all',
    children: [
      {
        label: 'Lista',
        value: 'estab-1',
      },
      {
        label: 'Lista Bloqueados',
        value: 'estab-2',
      },
      {
        label: 'Criar',
        value: 'estab-3',
      },
      {
        label: 'Carteira',
        value: 'estab-4',
      },
    ],
  },
  {
    label: 'Pedidos',
    value: 'ped-all',
    children: [
      {
        label: 'Lista',
        value: 'ped-1',
      },
      {
        label: 'Criar',
        value: 'ped-2',
      },
      {
        label: 'Criar Pedidos Antigos',
        value: 'ped-3',
      },
      {
        label: 'Registros do Dia',
        value: 'ped-4',
      },
    ],
  },
  {
    label: 'Recrutamento',
    value: 'recrut-all',
    children: [
      {
        label: 'Lista',
        value: 'recrut-1',
      },
      {
        label: 'Criar',
        value: 'recrut-2',
      },
    ],
  },
  {
    label: 'Opções',
    value: 'op-all',
    children: [
      {
        label: 'Idiomas',
        value: 'op-1',
      },
      {
        label: 'Serviços',
        value: 'op-2',
      },
      {
        label: 'Áreas de Atuação',
        value: 'op-3',
      },
      {
        label: 'Escolaridade',
        value: 'op-4',
      },
      {
        label: 'Tempo de Experiência',
        value: 'op-5',
      },
      {
        label: 'Habilidades',
        value: 'op-6',
      },
      {
        label: 'Dias de Funcionamento',
        value: 'op-7',
      },
      {
        label: 'Período de Funcionamento',
        value: 'op-8',
      },
      {
        label: 'Uniformes',
        value: 'op-9',
      },
      {
        label: 'Cupons',
        value: 'op-10',
      },
      {
        label: 'Treinamentos',
        value: 'op-11',
      },
      {
        label: 'Motivos de Cancelamento',
        value: 'op-12',
      },
      {
        label: 'Como Descobriu',
        value: 'op-15',
      },
      {
        label: 'Genero',
        value: 'op-16',
      },
    ],
  },
  {
    label: 'Tarifas',
    value: 'tarif-all',
    children: [
      {
        label: 'Estados',
        value: 'tarif-1',
      },
      {
        label: 'Tarifas Feriados',
        value: 'tarif-2',
      },
    ],
  },
  {
    label: 'Relatórios',
    value: 'rel-all',
    children: [
      {
        label: 'Profissional',
        value: 'rel-1',
      },
      {
        label: 'Cliente',
        value: 'rel-2',
      },
      {
        label: 'Pedidos Cancelados',
        value: 'rel-8',
      },
      {
        label: 'Dashboard',
        value: 'rel-4',
      },
      {
        label: 'Métricas',
        value: 'rel-5',
      },
      {
        label: 'Clientes Ativados',
        value: 'rel-6',
      },
      {
        label: 'Relatório Notificações',
        value: 'rel-7',
      },
    ],
  },
  {
    label: 'Iugu',
    value: 'iugu-all',
    children: [
      {
        label: 'Indice',
        value: 'iugu-1',
      },
    ],
  },
  {
    label: 'Notificações',
    value: 'noti-all',
    children: [
      {
        label: 'Lista',
        value: 'noti-1',
      },
      {
        label: 'Enviar Notificações Profissionais',
        value: 'noti-2',
      },
      {
        label: 'Listar Comunicados Profissionais',
        value: 'noti-3',
      },
      {
        label: 'Enviar Notificações Estabelecimentos',
        value: 'noti-4',
      },
      {
        label: 'Listar Comunicados Estabelecimentos',
        value: 'noti-5',
      },
    ],
  },
  {
    label: 'Administração',
    value: 'adm-all',
    children: [
      {
        label: 'Usuários',
        value: 'adm-1',
      },
      {
        label: 'Logs',
        value: 'adm-2',
      },
    ],
  },
];
