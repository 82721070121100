import React from 'react';
import { RemindFill } from '@rsuite/icons';

import { Button, Modal } from 'rsuite';

import PropTypes from 'prop-types';

import { ButtonFullBlue } from '../Buttons/ButtonFullBlue/styles';

export function Modal2({
  show,
  onHide,
  title,
  text,
  excluir,
  apagarId,
  apagarDado,
  fecharApagarDialog,
}) {
  return (
    <Modal backdrop="static" open={show} onClose={onHide} size="xs">
      <Modal.Header>
        <h3>{title}</h3>
      </Modal.Header>
      <Modal.Body>
        {text} <br /> <br />
        id: {apagarId} <br />
        idioma: {apagarDado} <br />
        Essa ação é irreversível{' '}
        <RemindFill
          style={{
            color: '#ffb300',
            fontSize: 24,
          }}
        />
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={fecharApagarDialog} appearance="subtle">
          Cancelar
        </Button>
        <ButtonFullBlue onClick={excluir} appearance="primary">
          Deletar
        </ButtonFullBlue>
      </Modal.Footer>
    </Modal>
  );
}
Modal2.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  excluir: PropTypes.func.isRequired,
  apagarId: PropTypes.string.isRequired,
  apagarDado: PropTypes.string.isRequired,
  fecharApagarDialog: PropTypes.func.isRequired,
};
