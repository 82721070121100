import React, { useState, useEffect, useContext } from 'react';
import { startOfDay, endOfDay, eachDayOfInterval } from 'date-fns';
import {
  Form,
  Panel,
  Schema,
  Loader,
  FlexboxGrid,
  SelectPicker,
  Button,
  TagGroup,
  Tag,
  Modal,
  Table,
  Divider,
  RangeSlider,
  Checkbox,
  Toggle,
  DateRangePicker,
  CheckPicker,
} from 'rsuite';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import api from '~/services/api';
import FilterUsersNotification from '~/components/FilterUsersNotification';
import { TablePagination } from '~/components/TablePagination';
import { NotificationToggle } from './styles';
import { ButtonBorderBlue } from '../../../components/Buttons/ButtonBorderBlue/styles';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';
import FiltroProfissionais from '../components/FiltroProfissionais';
import FiltroEstabelecimentos from '../components/FiltroEstabelecimentos';
import FiltroCidades from '../components/FiltroCidades';
import FiltroBairros from '../components/FiltroBairros';
import FiltroTreinamentos from '../components/FiltroTreinamentos';
import FiltroExperiencias from '../components/FiltroExperiencias';
import FiltroTrabalhos from '../components/FiltroTrabalhos';
import Announcement from '../components/Announcement';
import * as Styled from './styles';
import { DarkModeContext } from '../../../common/contexts/darkMode';

const { HeaderCell, Column, Cell } = Table;

const { StringType } = Schema.Types;

function Notificacoes() {
  const [camposInput, setCamposInput] = useState({});
  const [carregando, setCarregando] = useState(false);
  const [exibirDialog, setExibirDialog] = useState(false);
  const [quantidade, setQuantidade] = useState(0);
  const [profissionais, setProfissionais] = useState();
  const [atributoSelecionado, setAtributoSelecioando] = useState({
    label: 'Cidade',
    value: 'cidade',
  });
  const [dataAtividade, setDataAtividade] = useState([]);
  const [dtLiberacaoCadastro, setDtLiberacaoCadastro] = useState([]);
  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };
  const loggeduser = useSelector((state) => state.setUser.data);

  const [disableCommunication, setDisableCommunication] = useState(true);
  const [bloqueados, setBloqueados] = useState(0);
  const [favoritos, setFavoritos] = useState(false);

  const [filtroProfissionais, setFiltroProfissionais] = useState([]);
  const [filtroEstabelecimentos, setFiltroEstabelecimentos] = useState([]);
  const [filtroCidades, setFiltroCidades] = useState([]);
  const [filtroBairros, setFiltroBairros] = useState([]);
  const [interesseSelecionado, setInteresseSelecionado] = useState([]);
  const [filtroTreinamentos, setFiltroTreinamentos] = useState([]);
  const [filtroExperiencias, setFiltroExperiencias] = useState([]);
  const [filtroTrabalhos, setFiltroTrabalhos] = useState([]);
  const [filtroAvaliacao, setFiltroAvaliacao] = useState([0, 5]);
  const [filtroAvOn, setFiltroAvOn] = useState(false);
  const [statusSelecionado, setStatusSelecionado] = useState('Finalizado');
  const [pagina, setPagina] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const atributosFiltro = [
    {
      label: 'Cidade',
      value: 'cidade',
    },
    {
      label: 'Bairro',
      value: 'bairro',
    },
    {
      label: 'Profissional',
      value: 'profissional',
    },
    {
      label: 'Experiência',
      value: 'experiencia',
    },
    {
      label: 'Treinamentos',
      value: 'treinamento',
    },
    {
      label: 'Cliente',
      value: 'estabelecimento',
    },
    {
      label: 'Já trabalhou como',
      value: 'trabalhos',
    },
  ];
  const statusPeriodo = [
    {
      label: 'Finalizado',
      value: 'Finalizado',
    },
    {
      label: 'Cancelado',
      value: 'Cancelado',
    },
    {
      label: 'Faltou',
      value: 'Faltou',
    },
  ];


  const [orderBy, setOrderBy] = useState('');
  const { toggleMode } = useContext(DarkModeContext)

  // function getInteresse(status) {
  //   const map = {
  //     'freelancer': 'Freelancer',
  //     'emprego': 'Emprego',
  //     'emprego_freelancer': 'Emprego e Freelancer',
  //   };

  //   return map[status];
  // }

  const modelo = Schema.Model({
    titulo: StringType().isRequired('Título é obrigatório'),
    mensagem: StringType().isRequired('Mensagem é obrigatória'),
  });
  let erro = {};

  async function enviarNotificacoes() {
    setCarregando(true);
    setExibirDialog(false);
    try {
      const datas = [];
      if (dataAtividade && dataAtividade.length >= 2) {
        datas.push(
          ...eachDayOfInterval({
            start: dataAtividade[0],
            end: dataAtividade[1],
          })
        );
      }
      const dtLiberacao = [];
      if (dtLiberacaoCadastro && dtLiberacaoCadastro.length >= 2) {
        dtLiberacao.push(
          ...eachDayOfInterval({
            start: dtLiberacaoCadastro[0],
            end: dtLiberacaoCadastro[1],
          })
        );
      }
      const resultado = await api.post('/notificacao/profissional', {
        datasAtividade: datas,
        dtLiberacaoCadastro: dtLiberacao,
        titulo: camposInput.titulo,
        mensagem: camposInput.mensagem,
        enviadoPor: loggeduser.nome,
        profissionais: filtroProfissionais.map((p) => p.id),
        estabelecimentos: filtroEstabelecimentos.map((e) => e.id),
        cidades: filtroCidades.map((c) => c.id),
        bairros: filtroBairros.map((e) => e.id),
        treinamentos: filtroTreinamentos.map((t) => t.id),
        experiencias: filtroExperiencias.map((e) => e.id),
        trabalhos: filtroTrabalhos.map((t) => t.id),
        rangeAvaliacao: filtroAvOn === true ? filtroAvaliacao : [],
        interesses: interesseSelecionado,
        favoritos,
        bloqueados,
        statusSelecionado,
      });
      toast.success(resultado.data.message);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  function exibirMensagemErro() {
    Object.values(erro).forEach((e) => {
      toast.error(e);
    });
  }

  async function buscarProfissionais() {
    setCarregando(true);
    setFirstLoad(false);
    try {
      const datas = [];
      if (dataAtividade && dataAtividade.length >= 2) {
        datas.push(
          ...eachDayOfInterval({
            start: dataAtividade[0],
            end: dataAtividade[1],
          })
        );
      }

      const dtLiberacao = [];
      if (dtLiberacaoCadastro && dtLiberacaoCadastro.length >= 2) {
        dtLiberacao.push(
          ...eachDayOfInterval({
            start: dtLiberacaoCadastro[0],
            end: dtLiberacaoCadastro[1],
          })
        );
      }
      const resultado = await api.get('/notificacao/profissional', {
        params: {
          pagina,
          datasAtividade: datas,
          dtLiberacaoCadastro: dtLiberacao,
          profissionais: filtroProfissionais.map((p) => p.id),
          estabelecimentos: filtroEstabelecimentos.map((e) => e.id),
          cidades: filtroCidades.map((c) => c.id),
          bairros: filtroBairros.map((e) => e.id),
          treinamentos: filtroTreinamentos.map((t) => t.id),
          experiencias: filtroExperiencias.map((e) => e.id),
          trabalhos: filtroTrabalhos.map((t) => t.id),
          rangeAvaliacao: filtroAvOn === true ? filtroAvaliacao : [],
          interesses: interesseSelecionado,
          favoritos,
          bloqueados,
          orderBy,
          statusSelecionado,
        },
      });

      // const newValor = {
      //   ress: '',
      //   password: datas,
      //   dataAtividade: dtLiberacao,
      //   treinamentos: "new Filtro",
      //   favoritos,
      //   bloqueados,
      // }

      setProfissionais(resultado.data.lista);
      setQuantidade(resultado.data.quantidade);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  useEffect(() => {
    return !firstLoad && buscarProfissionais();
  }, [pagina, orderBy]);

  function buscarQuantidade() {
    setExibirDialog(true);
    buscarProfissionais();
  }

  return (
    <Styled.Container>
      <Styled.Content>
        <Panel
          header={<h3>Enviar Notificações para os Profissionais</h3>}
          shaded
        >
          <TagGroup style={{ marginBottom: '15px' }}>
            {filtroProfissionais.map((profissional) => (
              <Tag
                closable
                onClose={() => {
                  const novaLista = filtroProfissionais.filter(
                    (p) => p.id !== profissional.id
                  );
                  setFiltroProfissionais(novaLista);
                }}
                key={profissional.id}
              >
                Profissional: {profissional.label}
              </Tag>
            ))}
            {filtroEstabelecimentos.map((estabelecimento) => (
              <Tag
                closable
                onClose={() => {
                  const novaLista = filtroEstabelecimentos.filter(
                    (e) => e.id !== estabelecimento.id
                  );
                  setFiltroEstabelecimentos(novaLista);
                }}
                key={estabelecimento.id}
              >
                estabelecimento: {estabelecimento.label}
              </Tag>
            ))}
            {filtroCidades.map((cidade) => (
              <Tag
                closable
                onClose={() => {
                  const novaLista = filtroCidades.filter(
                    (c) => c.id !== cidade.id
                  );
                  setFiltroCidades(novaLista);
                }}
                key={cidade.id}
              >
                Cidade: {cidade.label}
              </Tag>
            ))}
            {filtroBairros.map((estado) => (
              <Tag
                closable
                onClose={() => {
                  const novaLista = filtroBairros.filter(
                    (e) => e.id !== estado.id
                  );
                  setFiltroBairros(novaLista);
                }}
                key={estado.id}
              >
                Bairro: {estado.label}
              </Tag>
            ))}
            {filtroTreinamentos.map((treinamento) => (
              <Tag
                closable
                onClose={() => {
                  const novaLista = filtroTreinamentos.filter(
                    (t) => t.id !== treinamento.id
                  );
                  setFiltroTreinamentos(novaLista);
                }}
                key={treinamento.id}
              >
                Treinamento: {treinamento.label}
              </Tag>
            ))}
            {filtroExperiencias.map((experiencia) => (
              <Tag
                closable
                onClose={() => {
                  const novaLista = filtroExperiencias.filter(
                    (e) => e.id !== experiencia.id
                  );
                  setFiltroExperiencias(novaLista);
                }}
                key={experiencia.id}
              >
                Experiência: {experiencia.label}
              </Tag>
            ))}
            {filtroTrabalhos.map((profissao) => (
              <Tag
                closable
                onClose={() => {
                  const novaLista = filtroTrabalhos.filter(
                    (e) => e.id !== profissao.id
                  );
                  setFiltroTrabalhos(novaLista);
                }}
                key={profissao.id}
              >
                Trabalhou: {profissao.label}
              </Tag>
            ))}
          </TagGroup>
          <NotificationToggle darkMode={toggleMode}>
            <Toggle
              checkedChildren="Comunicado"
              unCheckedChildren="Comunicado"
              onChange={() => setDisableCommunication(!disableCommunication)}
              style={{ marginBottom: '10px' }}
            />
          </NotificationToggle>
          <h5 style={{ marginBottom: '10px' }}>Filtro:</h5>
          <FlexboxGrid justify="start" style={{ marginBottom: 30, gap: '10px' }}>
            <SelectPicker
              appearance="default"
              style={{ width: '150px' }}
              searchable={false}
              data={atributosFiltro}
              value={atributoSelecionado.value}
              cleanable={false}
              onSelect={(value, item) => setAtributoSelecioando(item)}
              placeholder="Filtro"
            />
            {
              {
                profissional: (
                  <FiltroProfissionais
                    onItemAdicionado={(value, item) => {
                      const itemExiste = filtroProfissionais.find(
                        (filtro) => filtro.id === item.id
                      );
                      if (itemExiste) return;
                      const lista = [...filtroProfissionais, item];
                      setFiltroProfissionais(lista);
                    }}
                  />
                ),
                estabelecimento: (
                  <FiltroEstabelecimentos
                    onItemAdicionado={(value, item) => {
                      const itemExiste = filtroEstabelecimentos.find(
                        (filtro) => filtro.id === item.id
                      );
                      if (itemExiste) return;
                      const lista = [...filtroEstabelecimentos, item];
                      setFiltroEstabelecimentos(lista);
                    }}
                  />
                ),
                cidade: (
                  <FiltroCidades
                    onItemAdicionado={(value, item) => {
                      const itemExiste = filtroCidades.find(
                        (filtro) => filtro.id === item.id
                      );
                      if (itemExiste) return;
                      const lista = [...filtroCidades, item];
                      setFiltroCidades(lista);
                    }}
                  />
                ),
                bairro: (
                  <FiltroBairros
                    onItemAdicionado={(value, item) => {
                      const itemExiste = filtroBairros.find(
                        (filtro) => filtro.id === item.id
                      );
                      if (itemExiste) return;
                      const lista = [...filtroBairros, item];
                      setFiltroBairros(lista);
                    }}
                  />
                ),
                treinamento: (
                  <FiltroTreinamentos
                    onItemAdicionado={(value, item) => {
                      const itemExiste = filtroTreinamentos.find(
                        (filtro) => filtro.id === item.id
                      );
                      if (itemExiste) return;
                      const lista = [...filtroTreinamentos, item];
                      setFiltroTreinamentos(lista);
                    }}
                  />
                ),
                experiencia: (
                  <FiltroExperiencias
                    onItemAdicionado={(value, item) => {
                      const itemExiste = filtroExperiencias.find(
                        (filtro) => filtro.id === item.id
                      );
                      if (itemExiste) return;
                      const lista = [...filtroExperiencias, item];
                      setFiltroExperiencias(lista);
                    }}
                  />
                ),
                trabalhos: (
                  <FiltroTrabalhos
                    onItemAdicionado={(value, item) => {
                      const itemExiste = filtroTrabalhos.find(
                        (filtro) => filtro.id === item.id
                      );
                      if (itemExiste) return;
                      const lista = [...filtroTrabalhos, item];
                      setFiltroTrabalhos(lista);
                    }}
                  />
                ),
              }[atributoSelecionado.value]
            }

        <CheckPicker
          appearance="default"
          style={{ marginRight: '15px' }}
          searchable={false}
          placeholder="Interesse"
          // data={{ label:"freelancer"}}
          data={[{
            label: 'Freelancer',
            value: 'freelancer',
          },
          {
            label: 'Emprego',
            value: 'emprego',
          },
          {
            label: 'Emprego e Freelancer',
            value: 'emprego_freelancer',
          },
          ]}
          value={interesseSelecionado}
          onChange={(value) => setInteresseSelecionado(value)}
        />

          </FlexboxGrid>

          <FilterUsersNotification
            setBloqueados={setBloqueados}
            bloqueados={bloqueados}
          />
          <h5 style={{ marginBottom: '10px' }}>
            Data liberacao cadastro:
          </h5>
          <DateRangePicker
            format="dd/MM/yyyy"
            placeholder="Data"
            style={{ marginBottom: 30 }}
            value={dtLiberacaoCadastro}
            ranges={[]}
            isoWeek
            locale={locale}
            onClean={() => setDtLiberacaoCadastro([])}
            onChange={(datasSelecionadas) => {
              const datas = [
                datasSelecionadas && startOfDay(datasSelecionadas[0]),
                datasSelecionadas && endOfDay(datasSelecionadas[1]),
              ];
              setDtLiberacaoCadastro(datas);
            }}
          />
          <h5 style={{ marginBottom: '10px' }}>
            Data de atividade (opcional):
          </h5>
          <DateRangePicker
            format="dd/MM/yyyy"
            placeholder="Data"
            style={{ marginBottom: 30 }}
            value={dataAtividade}
            ranges={[]}
            isoWeek
            locale={locale}
            onClean={() => setDataAtividade([])}
            onChange={(datasSelecionadas) => {
              const datas = [
                datasSelecionadas && startOfDay(datasSelecionadas[0]),
                datasSelecionadas && endOfDay(datasSelecionadas[1]),
              ];
              setDataAtividade(datas);
            }}
          />
          {dataAtividade && dataAtividade.length > 0 &&
            <SelectPicker
              style={{ marginBottom: 30, marginLeft: 5, }}
              value={statusSelecionado}
              placeholder="Status"
              searchable={false}
              data={statusPeriodo}
              onChange={setStatusSelecionado}
            />}
          <Checkbox
            checked={filtroAvOn}
            onChange={() => setFiltroAvOn(!filtroAvOn)}
            style={{ marginBottom: '30px' }}
          >
            <strong>Avaliação média</strong>
          </Checkbox>
          {/* Favoritos */}
          <Checkbox
            checked={favoritos}
            onChange={() => setFavoritos((f) => !f)}
            style={{ marginBottom: '30px' }}
          >
            <strong>Favoritos</strong>
          </Checkbox>
          {/* Favoritos  */}
          <RangeSlider
            renderMark={(mark) => {
              return mark;
            }}
            disabled={!filtroAvOn}
            value={filtroAvaliacao}
            min={0}
            max={5}
            defaultValue={filtroAvaliacao}
            step={1}
            graduated
            progress
            onChange={(value) => setFiltroAvaliacao(value)}
            style={{ width: '50%', marginBottom: '50px' }}
          />
          <Divider>
            <ButtonBorderBlue
              appearance="ghost"
              loading={carregando}
              onClick={() => buscarProfissionais()}
            >
              Atualizar Tabela
            </ButtonBorderBlue>
          </Divider>
          <h5
            style={{
              marginBottom: '10px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            Notificação
          </h5>
          <Form
            model={modelo}
            onChange={setCamposInput}
            formValue={camposInput}
            onSubmit={(semErro) =>
              semErro ? buscarQuantidade() : exibirMensagemErro()
            }
            onError={(err) => {
              erro = err;
            }}
          >
            <Form.Group>
              <Form.ControlLabel>Título</Form.ControlLabel>
              <Form.Control name="titulo" value={camposInput.titulo} />
            </Form.Group>
            <Form.Group>
              {disableCommunication ? (
                <Form.ControlLabel>Mensagem</Form.ControlLabel>
              ) : (
                <Form.ControlLabel>Resumo Comunicado</Form.ControlLabel>
              )}
              <Form.Control name="mensagem" value={camposInput.mensagem} />
            </Form.Group>
            {disableCommunication ? (
              <ButtonFullBlue
                block
                appearance="primary"
                type="submit"
                loading={carregando}
              >
                Enviar
              </ButtonFullBlue>
            ) : (
              ''
            )}
          </Form>
          {carregando && <Loader center backdrop />}
          <Modal
            backdrop="static"
            open={exibirDialog}
            onClose={() => setExibirDialog(false)}
            size="xs"
          >
            <Modal.Header>
              <h3>Enviar Notificação</h3>
            </Modal.Header>
            <Modal.Body>
              <p>Notificação será enviada para {quantidade} usuários</p>
            </Modal.Body>

            <Modal.Footer>
              <Button
                onClick={() => setExibirDialog(false)}
                appearance="subtle"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                appearance="primary"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={enviarNotificacoes}
              >
                Enviar
              </Button>
            </Modal.Footer>
          </Modal>
        </Panel>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: `100%` }}
        >
          <Announcement
            disabled={disableCommunication}
            target="profissional"
            // eslint-disable-next-line react/jsx-no-bind
            notificationCallback={enviarNotificacoes}
            quantidade={quantidade}
            filtros={{
              cidadesFiltradas: filtroCidades,
              bairrosFiltrados: filtroBairros,
              profissionaisFiltrados: filtroProfissionais,
              experienciasFiltradas: filtroExperiencias,
              treinamentosFiltrados: filtroTreinamentos,
              estabelecimentosFiltrados: filtroEstabelecimentos,
              trabalhosFiltrados: filtroTrabalhos,
              dataFiltrada: dataAtividade,
              rangeAvaliacao: filtroAvOn === true ? filtroAvaliacao : [],
              interesses: interesseSelecionado
            }}
            resumo={camposInput.mensagem}
            bloqueados={bloqueados}
          />
        </div>
      </Styled.Content>
      <Divider vertical style={{ height: '100%' }} />
      <Styled.Content>
        <Panel
          style={{ width: '100%' }}
          header={<h3>Profissionais a serem notificados</h3>}
          shaded
        >
          <TablePagination
            carregando={carregando}
            dados={profissionais}
            quantidade={quantidade}
            onChangePage={setPagina}
            setOrderBy={setOrderBy}
          >
            <Column resizable width={180} sortable>
              <HeaderCell>Nome Profissional</HeaderCell>
              <Cell dataKey="nome" />
            </Column>

            <Column resizable width={140} sortable>
              <HeaderCell>Email</HeaderCell>
              <Cell dataKey="email" />
            </Column>
            <Column resizable width={140} sortable>
              <HeaderCell>CPF</HeaderCell>
              <Cell dataKey="cpf_cnpj" />
            </Column>
            <Column resizable width={150} sortable>
              <HeaderCell>Telefone</HeaderCell>
              <Cell dataKey="telefone1" />
            </Column>
          </TablePagination>
        </Panel>
      </Styled.Content>
    </Styled.Container>
  );
}

export default Notificacoes;
