/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useCallback } from 'react';
import { Attachment, Edit, InfoRound } from '@rsuite/icons';
import {
  Table,
  FlexboxGrid,
  SelectPicker,
  IconButton,
  CheckPicker,
  Modal,
  Button,
} from 'rsuite';
import { toast } from 'react-toastify';
import { GerarCSV as CSV } from '~/utils/gerarCSV'
import { Container } from '~/styles/tabela';
import pt from 'date-fns/locale/pt-BR';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import {formatDistance} from "date-fns";
import { TablePagination } from '~/components/TablePagination';
import api from '~/services/api';
import formatCurrency from '~/utils/formatCurrency';
import SearchInputTable from '~/components/SearchInputTable';
import { InputDataRange } from '~/components/InputDataRange';
import { FiltroTreinamentos } from '~/components/FiltroTreinamentos';
import EditarProfissional from '../../Profissionais/EditarProfissional';
import DetalhesCarteiraDialog from './components/DetalhesCarteiraDialog';
import SelectState from '../../../components/SelectState';

const { HeaderCell, Column, Cell } = Table;

function CarteiraProfissionais() {
  const [carteiraProfissionais, setCarteiraProfissionais] = useState({
    lista: [],
    quantidade: 0,
  });
  const [profissoes, setProfissoes] = useState([]);
  const [experienciaSelecionada, setExperienciaSelecionada] = useState();
  const [treinamentos, setTreinamentos] = useState([]);
  const [treinamentoSelecionado, setTreinamentoSelecionado] = useState();
  const [trabFeitosSelecionado, setTrabFeitosSelecionado] = useState();
  const [atributoSelecionado, setAtributoSelecionado] = useState('nome');
  const [modalConfirmacao, setModalConfirmacao] = useState(false);
  const [textodigitado, setTextoDigitado] = useState();
  const [estadoSelecionado, setEstadoSelecionado] = useState();
  const [data, setData] = useState();
  const [pagina, setPagina] = useState(1);
  const [exibirDetalhesDialog, setExibirDetalhesDialog] = useState({
    exibir: false,
  });
  const [profissionalEditando, setProfissionalEditando] = useState();
  const [showEditarProfissional, setShowEditarProfissional] = useState(false);

  const [carregando, setCarregando] = useState(true);

  const [orderBy, setOrderBy] = useState('');
  const [buscarCSV, setBuscarCSV] = useState(false);

  const buscarCarteiraProfissionais = useCallback(async () => {
    setCarregando(true);
    setModalConfirmacao(false)
    try {
      const resposta = await api.get(`relatorios/carteira-profissionais`, {
        params: {
          [atributoSelecionado]: textodigitado,
          data,
          pagina,
          treinamentos: treinamentoSelecionado,
          experiencias: experienciaSelecionada,
          trabFeitos: trabFeitosSelecionado,
          estado: estadoSelecionado,
          orderBy,
          csvExport: buscarCSV
        },
      });
      const profissoes1 = await api.get('profissao');
      setProfissoes(profissoes1.data);
      const treinamentos1 = await api.get('treinamento');
      setTreinamentos(treinamentos1.data);

      const getDuration = (startDuration,stopoverDuration) => {
        const durationDateFrom = new Date(startDuration);
        const durationDateTo = new Date(stopoverDuration);
        return formatDistance(durationDateTo, durationDateFrom , {
          locale: pt,
        });
       }

      const lista = resposta.data.lista.map((item) => ({
        // ...item,
        ...(buscarCSV === false && item),
        'Nome': item.nome,
        'Bairro': item.bairro,
        'Cidade': item.cidade,
        'Avaliacao': item.avaliacao !== null ? `${item.avaliacao.toFixed(2)}` : `${'Não avaliado'}`,
        'Recebido': item.recebido ? formatCurrency(item.recebido) : `R$${0}`,
        'Horas': item.horas,
        'Shifts': item.shifts,
        'Faltas': item.faltas,
        'Cancelamentos': item.cancelamentos,
        'Favorito': (item.qtd_fav === null) ? 0 : item.qtd_fav,
        'Block': (item.qtd_block === null) ? 0 : item.qtd_block,
        'Total_Estab': (item.qty_estab === null) ? 0 : item.qty_estab,
        'Resp_Freela': item.contagem_registro ? item.contagem_registro : 0,
        'Resp_Emprego': item.contagem_recrutamento ? item.contagem_recrutamento : 0,
        'Meses Ativo': `${item.meses_ativo != null ? item.meses_ativo : 0} Meses`,
        'Tempo p/ ativar': `${item.tempo_cadastro_liberacao != null ? item.tempo_cadastro_liberacao : 0} Meses`,

      }));

      if(buscarCSV){
        CSV({
          data: lista,
          download: true,
        });

        setCarregando(false);
        toast.success(`Foram exportados ${lista.length} registros.`);
        setBuscarCSV(false);
        return;
      }

      // setCarteiraProfissionais({ lista, quantidade: resposta.data.quantidade });
      setCarteiraProfissionais({ lista, quantidade: lista.length > 0 ? lista[0].full_count : 0 });
      if(carteiraProfissionais.quantidade === 0) setPagina(1)
    } catch (e) {
      if(buscarCSV){
        setCarregando(false);
        toast.error('Erro ao Exportar os dados');
        setBuscarCSV(false);
        return;
      }

      toast.error(e.message);
    }
    setCarregando(false);
  }, [
    atributoSelecionado,
    textodigitado,
    data,
    pagina,
    treinamentoSelecionado,
    experienciaSelecionada,
    trabFeitosSelecionado,
    estadoSelecionado,
    orderBy,
    buscarCSV,
    setPagina,
    carteiraProfissionais.quantidade
  ]);

  function download(dados) {
    const blob = new Blob([dados], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'download.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a)
  }

  useEffect(() => {
    buscarCarteiraProfissionais();
  }, [buscarCarteiraProfissionais]);

  function abrirEditarProfissional(profissional) {
    setProfissionalEditando(profissional);
    setShowEditarProfissional(true);
  }

  function fecharEditarProfissional() {
    buscarCarteiraProfissionais();
    setShowEditarProfissional(false);
  }

  return (
    <Container>
      <h2>Carteira Profissionais
      <IconButton
          title="Exportar CSV"
          style={{ marginLeft: '100px' }}
          size="4em"
          icon={<Attachment />}
          onClick={() => {
            setModalConfirmacao(true);
          }}
        />
      </h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchInputTable setTextoDigitado={setTextoDigitado} />
        <SelectPicker
          value={atributoSelecionado}
          placeholder="Selecione o Status"
          searchable={false}
          data={[
            { value: 'nome', label: 'Nome' },
            { value: 'bairro', label: 'Bairro' },
            { value: 'cidade', label: 'Cidade' },
          ]}
          onChange={setAtributoSelecionado}
          style={{ marginLeft: '-15px' }}
          cleanable={false}
        />

        <InputDataRange ranges={[]} value={data} onChange={setData} />
        <SelectState
          selectedState={estadoSelecionado}
          setSelectedStates={setEstadoSelecionado}
        // marginRight
        // style={{ marginRight: '-30px' }}
        />
        <FiltroTreinamentos
          appearance="default"
          style={{ width: 224 }}
          searchable={false}
          data={treinamentos.map((treinamento) => ({
            label: treinamento.treinamento,
            value: treinamento.id,
          }))}
          value={treinamentoSelecionado}
          onChange={(value) => setTreinamentoSelecionado(value)}
        />
        <CheckPicker
          style={{ width: 224 }}
          appearance="default"
          placeholder="Experiências"
          data={profissoes.map((profissao) => ({
            label: profissao.profissao,
            value: profissao.id,
          }))}
          value={experienciaSelecionada}
          onChange={(value) => setExperienciaSelecionada(value)}
        />
        <CheckPicker
          style={{ width: 224 }}
          appearance="default"
          placeholder="Já trabalhou como:"
          data={profissoes.map((profissao) => ({
            label: profissao.profissao,
            value: profissao.id,
          }))}
          value={trabFeitosSelecionado}
          onChange={(value) => setTrabFeitosSelecionado(value)}
        />
      </FlexboxGrid>

      <FlexboxGrid style={{ width: '2290px' }}>
      <TablePagination
        carregando={carregando}
        dados={carteiraProfissionais.lista}
        quantidade={carteiraProfissionais.quantidade}
        onChangePage={setPagina}
        setOrderBy={setOrderBy}
      >
        <Column resizable width={100}>
          <HeaderCell>Opções</HeaderCell>
          <Cell dataKey="id" style={{ padding: '5px' }}>
            {(rowData) => (
              <>
                <IconButton
                  title="Detalhes"
                  appearance="subtle"
                  onClick={() => {
                    setExibirDetalhesDialog({
                      exibir: true,
                      id_profissional: rowData.id,
                    });
                  }}
                  icon={<InfoRound />}
                />
                <IconButton
                  title="Editar"
                  appearance="subtle"
                  onClick={() => {
                    abrirEditarProfissional(rowData.id);
                  }}
                  icon={<Edit />}
                />
              </>
            )}
          </Cell>
        </Column>
        <Column resizable width={240} sortable>
          <HeaderCell>Nome Profissional</HeaderCell>
          <Cell dataKey="Nome" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Bairro</HeaderCell>
          <Cell dataKey="Bairro" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Cidade</HeaderCell>
          <Cell dataKey="Cidade" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Avaliação</HeaderCell>
          <Cell dataKey="Avaliacao" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Recebido</HeaderCell>
          <Cell dataKey="Recebido" />
        </Column>
        <Column resizable width={100} sortable>
          <HeaderCell>Horas</HeaderCell>
          <Cell dataKey="Horas" />
        </Column>
        <Column resizable width={100} sortable>
          <HeaderCell>Shifts</HeaderCell>
          <Cell dataKey="Shifts" />
        </Column>
        <Column resizable width={100} sortable>
          <HeaderCell>Faltas</HeaderCell>
          <Cell dataKey="Faltas" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Cancelamentos</HeaderCell>
          <Cell dataKey="Cancelamentos" />
        </Column>
        <Column resizable width={110} sortable>
          <HeaderCell>Favorito</HeaderCell>
          <Cell dataKey="Favorito" />
        </Column>
        <Column resizable width={110} sortable>
          <HeaderCell>Block</HeaderCell>
          <Cell dataKey="Block" />
        </Column>
        <Column resizable width={130} sortable>
          <HeaderCell>Total Estab</HeaderCell>
          <Cell dataKey="Total_Estab" />
        </Column>
        <Column resizable width={130} sortable>
          <HeaderCell>Resp Freela</HeaderCell>
          <Cell dataKey="Resp_Freela" />
        </Column>
        <Column resizable width={130} sortable>
          <HeaderCell>Resp Emprego</HeaderCell>
          <Cell dataKey="Resp_Emprego" />
        </Column>
        <Column resizable width={130}>
          <HeaderCell>Meses Ativo</HeaderCell>
          <Cell dataKey= "Meses Ativo"/>
        </Column>
        <Column resizable width={160}>
          <HeaderCell>Tempo p/ ativar</HeaderCell>
          <Cell dataKey= "Tempo p/ ativar" />
        </Column>


      </TablePagination>
      </FlexboxGrid>
      <DetalhesCarteiraDialog
        exibir={exibirDetalhesDialog.exibir}
        id_profissional={exibirDetalhesDialog.id_profissional}
        datas={data}
        onFechar={() => setExibirDetalhesDialog({ exibir: false })}
      />
      <EditarProfissional
        idProfissional={profissionalEditando && profissionalEditando}
        showEditarProfissional={showEditarProfissional}
        setShowEditarProfissional={setShowEditarProfissional}
        fecharEditarProfissional={fecharEditarProfissional}
        atualizarLista={buscarCarteiraProfissionais}
      />
      <Modal
        open={modalConfirmacao}
        onClose={() => setModalConfirmacao(false)}
        size="xs"
      >
        <Modal.Header>Gerar Relatório CSV</Modal.Header>
        <Modal.Body>
          <p>
            Deseja realmente gerar o csv dos profissionais com o filtro
            atual?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalConfirmacao(false)}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <Button
            appearance="primary"
            type="button"
            onClick={() => {
              setBuscarCSV(true)
              buscarCarteiraProfissionais();
            }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default CarteiraProfissionais;
