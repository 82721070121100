/* eslint-disable react/jsx-no-bind */
import React, { useState, useCallback, useEffect } from 'react';
import { Attachment, Search } from '@rsuite/icons';

import { Loader, Table, FlexboxGrid, Input, InputGroup, IconButton, SelectPicker } from 'rsuite';
import { startOfMonth, format, parseISO, lastDayOfMonth } from 'date-fns';
import { toast } from 'react-toastify';
import { GerarCSV as CSV } from '~/utils/gerarCSV'

import { Container } from '~/styles/tabela';


import api from '~/services/api';
import { InputDataRange } from '~/components/InputDataRange';
import { TablePagination } from '~/components/TablePagination';
import fortCurrency from '../../../utils/formatCurrency'
import { ModalPadrao } from '../../../components/Modal';
import { ColContainer } from './styles'

const { HeaderCell, Column, Cell } = Table;

function ClientesAtivados() {
  const t = new Date();
  const [pagina, setPagina] = useState(1);
  const [dataFiltro, setDataFiltro] = useState([
    startOfMonth(new Date(), { weekStartsOn: 1 }),
    lastDayOfMonth(new Date()),
  ]);
  const [filtroNome, setFiltroNome] = useState('');
  const [clientes, setClientes] = useState({
    profissionais: [],
  });
  const [modalConfirmacao, setModalConfirmacao] = useState(false);
  const [filtroOrigem, setFiltroOrigem] = useState('');
  const [carregando, setCarregando] = useState(false);
  const [buscarCSV, setBuscarCSV] = useState(false);

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const [orderBy, setOrderBy] = useState('order by data_ativacao desc');
  const motalTitulo = 'Gerar Relatório CSV';
  const modalTexto =
    'Deseja realmente gerar o arquivo em csv dos Clientes Ativos com o filtro atual?';

  const buscarClientes = useCallback(async () => {
    setCarregando(true);
    setModalConfirmacao(false);
    try {
      const resposta = await api.get(
        `relatorios/estabelecimento/clientes-ativos`,
        {
          params: {
            pagina,
            datas: dataFiltro,
            nome: filtroNome,
            orderBy,
            csv:buscarCSV,
            origem: filtroOrigem
          },
        }
      );

      const lista = resposta.data.lista.map((cliente) => ({
        ...(buscarCSV === false && cliente),
        "Nome Estabelecimento": cliente.nome_fantasia,
        "Cnpj": cliente.cpf_cnpj,
        "Telefone": cliente.telefone,
        "N. Pedido": cliente.numero_pedidop,
        "Cidade": cliente.cidade,
        "Estado": cliente.estado,
        "Data Ativação": format(parseISO(cliente.data_ativacao), 'dd/LL/yyyy'),
        "Quantidade": cliente.count,
        "Total": fortCurrency(cliente.valor_bruto),
        "Origem": cliente.origem,
      }));


      if(buscarCSV){
        CSV({
          data: lista,
          download: true,
        });

        setCarregando(false);
        toast.success(`Foram exportados ${lista.length} registros.`);
        setBuscarCSV(false);
        return;
      }

      setClientes({
        lista,
        quantidade: lista.length > 0 ? lista[0].full_count : 0
      });
      if(clientes.quantidade === 0) setPagina(1)
    } catch (e) {
      if(buscarCSV){
        setCarregando(false);
        toast.error('Erro ao Exportar os dados');
        setBuscarCSV(false);
        return;
      }
      toast.error(e.message);
    }
    setCarregando(false);
  }, [pagina, dataFiltro, filtroNome, orderBy, buscarCSV, clientes.quantidade, filtroOrigem]);

  useEffect(() => {
    buscarClientes();
  }, [buscarClientes]);

  function test(){
    console.log(filtroOrigem)
  }



  return (  
    <Container>
      <h2>Data de Ativação Clientes
        <IconButton
          title="Exportar CSV"
          style={{ marginLeft: '100px' }}
          size="4em"
          icon={<Attachment />}
          onClick={() => {
            setModalConfirmacao(true);
          }}
        />
      </h2>
      <FlexboxGrid justify="start" align="middle">
        <ColContainer>
        <p>Pesquisar</p>
        <InputGroup style={{ width: '300px', marginRight: '15px' }}>
          <Input
            name="nome_estabelecimento"
            value={filtroNome}
            style={{ marginRight: '10px', width: '300px' }}
            placeholder="Nome do Cliente"
            onChange={setFiltroNome}
          />
          <InputGroup.Button>
            <Search />
          </InputGroup.Button>
        </InputGroup>
        </ColContainer>

        <ColContainer>
        <p>Data de Ativação</p>
        <InputDataRange
          name="data"
          style={{ marginRight: '15px', width: 240 }}
          ranges={[]}
          isoWeek
          // oneTap
          locale={locale}
          onChange={setDataFiltro}
          value={dataFiltro}
        // hoverRange="month"
        />
        </ColContainer>
        <ColContainer>
        <p>Origem</p>
        <SelectPicker
          appearance="default"
          searchable={false}
          style={{width: 170}}
          placeholder="Origem"
          data={[{
            label: 'Recrutamento',
            value: 'recrutamento',
          },
          {
            label: 'Pedidos',
            value: 'pedidos',
          }
          ]}
          value={filtroOrigem}
          onChange={(value) => setFiltroOrigem(value)}
        />

        </ColContainer>
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '1350px' }}>
      <TablePagination
        carregando={carregando}
        dados={clientes.lista}
        quantidade={clientes.quantidade || 0}
        onChangePage={setPagina}
        setOrderBy={setOrderBy}
      >
        <Column resizable width={240} sortable>
          <HeaderCell>Nome Estabelecimento</HeaderCell>
          <Cell dataKey="Nome Estabelecimento" />
        </Column>

        <Column resizable width={150}>
          <HeaderCell>Cnpj</HeaderCell>
          <Cell dataKey="Cnpj" />
        </Column>
        <Column resizable width={150}>
          <HeaderCell>Telefone</HeaderCell>
          <Cell dataKey="Telefone" />
        </Column>
        <Column resizable width={100} sortable>
          <HeaderCell>N. Pedido</HeaderCell>
          <Cell dataKey="N. Pedido" />
        </Column>
        <Column resizable width={150}>
          <HeaderCell>Cidade</HeaderCell>
          <Cell dataKey="Cidade" />
        </Column>
        <Column resizable width={70}>
          <HeaderCell>Estado</HeaderCell>
          <Cell dataKey="Estado" />
        </Column>
        <Column resizable width={130} sortable>
          <HeaderCell>Data Ativação</HeaderCell>
          <Cell dataKey="Data Ativação" />
        </Column>
        <Column resizable width={120} sortable>
          <HeaderCell>Quantidade</HeaderCell>
          <Cell dataKey="Quantidade" />
        </Column>
        <Column resizable width={110} >
          <HeaderCell>Total</HeaderCell>
          <Cell dataKey="Total" />
        </Column>
         <Column resizable width={130} >
          <HeaderCell>Origem</HeaderCell>
          <Cell dataKey="Origem" />
        </Column>
      </TablePagination>
      </FlexboxGrid>
      <ModalPadrao
        openModal={modalConfirmacao}
        setOpenModal={setModalConfirmacao}
        title={motalTitulo}
        text={modalTexto}
        funcExec={() => {
          setBuscarCSV(true);
          buscarClientes();
        }}
      />

      {carregando && <Loader center size="lg" vertical />}
    </Container>
  );
}

export default ClientesAtivados;
