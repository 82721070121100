/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Plus } from '@rsuite/icons';

import { Table, FlexboxGrid, Schema } from 'rsuite';
import { toast } from 'react-toastify';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import {
  CelulaEditavel,
  CelulaAcao,
} from '~/components/Celulas/components/CelulaEditavel';
import { ModalIcon } from '~/components/ModalCriacao/ModalIcon';
import { Modal1 } from '~/components/ModalCriacao/Modal1';
import { Modal2 } from '~/components/ModalCriacao/Modal2';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import SearchTable from '../components/SearchTable';

const { HeaderCell, Column } = Table;

const { StringType } = Schema.Types;

const modelo = Schema.Model({
  tipo_estabelecimento: StringType().isRequired('Serviço Obrigatório'),
});

function TiposEstabelecimentos() {
  const [dados, setDados] = useState({
    listaInicial: [],
    tipoEstabelecimentoLista: [],
    tipoEstabelecimentoListaFiltrada: [],
    filtro: '',
  });
  const [apagarTipoEstabelecimentoDialog, setApagarTipoEstabelecimentoDialog] =
    useState({
      exibir: false,
      tipo: {},
    });
  const [carregando, setCarregando] = useState(true);
  const [
    adicionarTipoEstabelecimentoDialog,
    setAdicionarTipoEstabelecimentoDialog,
  ] = useState(false);
  const [camposInput, setCamposInput] = useState({});

  async function buscarTiposEstabelecimentos() {
    const resposta = await api.get('tipo-estabelecimento');
    const resposta1 = await api.get('tipo-estabelecimento');
    const lista = resposta.data;
    setDados({
      listaInicial: resposta1.data,
      tipoEstabelecimentoLista: lista,
      tipoEstabelecimentoListaFiltrada: lista,
    });
    setCarregando(false);
  }

  useEffect(() => {
    buscarTiposEstabelecimentos();
  }, []);

  function filtrarLista(texto) {
    setDados({
      ...dados,
      tipoEstabelecimentoListaFiltrada: dados.tipoEstabelecimentoLista.filter(
        (tipo) =>
          tipo.tipo_estabelecimento
            .toString()
            .toUpperCase()
            .includes(texto ? texto.toUpperCase() : '')
      ),
      filtro: texto,
    });
  }

  function abrirApagarDialog(tipo) {
    setApagarTipoEstabelecimentoDialog({
      exibir: true,
      tipo,
    });
  }

  function fecharApagarDialog() {
    setApagarTipoEstabelecimentoDialog({
      exibir: false,
      tipo: {},
    });
  }

  async function excluirTipoEstabelecimento() {
    try {
      await api.delete(
        `/tipo-estabelecimento/${apagarTipoEstabelecimentoDialog.tipo.id}`
      );
      toast.success('Tipo Cliente excluido com sucesso');
      fecharApagarDialog();
      buscarTiposEstabelecimentos();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function adicionarTipoEstabelecimento() {
    try {
      setCarregando(true);
      await api.post(`/tipo-estabelecimento`, camposInput);
      toast.success('Tipo Cliente criado com sucesso');
      setCarregando(false);
      setAdicionarTipoEstabelecimentoDialog(false);
      buscarTiposEstabelecimentos();
    } catch (e) {
      toast.error('Erro ao adicionar tipo cliente');
    }
  }

  function alterarTextoCelula(id, key, value) {
    const novaLista = Object.assign([], dados.tipoEstabelecimentoLista);
    novaLista.find((tipo) => tipo.id === id)[key] = value;
    setDados({ ...dados, tipoEstabelecimentoLista: novaLista });
    filtrarLista(dados.filtro);
  }

  async function alterarEstadoCelula(id) {
    const novaLista = Object.assign([], dados.tipoEstabelecimentoLista);
    const item = novaLista.find((tipo) => tipo.id === id);
    const itemInicial = dados.listaInicial.find((tipo) => tipo.id === id);
    if (
      item.status === 'EDIT' &&
      item.tipo_estabelecimento !== itemInicial.tipo_estabelecimento
    ) {
      try {
        setCarregando(true);
        await api.put(`tipo-estabelecimento/${id}`, item);
        toast.success('Tipo Cliente atualizado com sucesso');
        buscarTiposEstabelecimentos();
      } catch (error) {
        toast.error(error.message);
      }
      setCarregando(false);
    } else {
      item.status = item.status ? null : 'EDIT';
      setDados({ ...dados, tipoEstabelecimentoLista: novaLista });
      filtrarLista(dados.filtro);
    }
  }

  return (
    <Container>
      <h2>Áreas de Atuação</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchTable estilo={{ width: '300px' }} filtrarLista={filtrarLista} />
        <ModalIcon
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setAdicionarTipoEstabelecimentoDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '440px' }}>
        <TabelaSemPaginacao
          carregando={carregando}
          dados={dados.tipoEstabelecimentoListaFiltrada}
        >
          <Column resizable width={240} sortable>
            <HeaderCell>Serviço</HeaderCell>
            <CelulaEditavel
              dataKey="tipo_estabelecimento"
              onChange={alterarTextoCelula}
            />
          </Column>

          <Column resizable width={200}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onClick={alterarEstadoCelula}
              onDelete={abrirApagarDialog}
            />
          </Column>
        </TabelaSemPaginacao>
      </FlexboxGrid>

      <Modal1
        show={adicionarTipoEstabelecimentoDialog}
        onHide={() => setAdicionarTipoEstabelecimentoDialog(false)}
        size="xs"
        title="Adicionar Tipo Cliente"
        label="Tipo Cliente"
        name="tipo_estabelecimento"
        model={modelo}
        onSubmit={(status) => status && adicionarTipoEstabelecimento()}
        onChange={setCamposInput}
        onClick={() => setAdicionarTipoEstabelecimentoDialog(false)}
      />

      <Modal2
        show={apagarTipoEstabelecimentoDialog.exibir}
        onHide={fecharApagarDialog}
        title="Apagar Tipo Cliente"
        text="Você realmente deseja apagar este tipo de cliente?"
        apagarId={apagarTipoEstabelecimentoDialog.tipo.id}
        apagarDado={apagarTipoEstabelecimentoDialog.tipo.tipo_estabelecimento}
        fecharApagarDialog={fecharApagarDialog}
        excluir={() => excluirTipoEstabelecimento()}
      />
    </Container>
  );
}

export default TiposEstabelecimentos;
