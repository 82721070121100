import React, { useEffect, useState } from 'react';
import { Button, FlexboxGrid, Table } from 'rsuite';
import { toast } from 'react-toastify';

import { useDispatch, useSelector } from 'react-redux';
import { Container } from '~/styles/tabela';

import {
  buscarNotificacoes,
  marcarNotificacoesLidas as atualizarNotificacoesLidas,
} from '~/store/modulos/notificacao/actions';
import SearchInputTable from '~/components/SearchInputTable';
import { InputDataRange } from '~/components/InputDataRange';
import { TablePagination } from '~/components/TablePagination';
import api from '~/services/api';
import { CelulaNotifcacao } from '~/components/CelulaNotificacao';


const { HeaderCell, Column } = Table;

function AlteracaoPedido() {
  const [pagina, setPagina] = useState(1);
  const [data, setData] = useState([]);
  const [textodigitado, setTextoDigitado] = useState('');
  // const [carregando, setCarregando] = useState(true);
  const notificacoes = useSelector((state) => state.notificacao.notificacoes);
  const carregando = useSelector((state) => state.notificacao.carregando);
  const pedidosCanceladosNaoLidos = useSelector((state) => state.notificacao.pedidosCanceladosNaoLidos);
  // const AtualizacaoTempoReal = useSelector(
  //   (state) => state.notificacao.AtualizacaoPaginaTempoReal
  // );
  const dispatch = useDispatch();

  useEffect(() => {
    if(notificacoes.quantidade > 0 && notificacoes.lista.length === 0) setPagina(1)
    if (data) {
      dispatch(
        buscarNotificacoes({
          pagina,
          titulo: "Pedido cancelado",
          mensagem: textodigitado,
          dataInicio: data[0],
          dataFim: data[1],
        })
      );
    }
    if (!data) {
      dispatch(
      buscarNotificacoes({
        pagina,
        titulo: "Pedido cancelado",
        mensagem: textodigitado,
      })
      );
      }
  }, [pagina, dispatch, textodigitado, data, pedidosCanceladosNaoLidos, notificacoes.quantidade, notificacoes.lista.length]);

  async function marcarNotificacoesLidas() {
    dispatch(atualizarNotificacoesLidas({ pagina, titulo: "Pedido cancelado" }));
  }

  async function statusResolvidoOuNaoResolvido(profissao, status) {
    try {
      // setCarregando(true);
      profissao.profissao_bloqueada = !status;
      await api.put(`profissao/${profissao.id}`, profissao);
      // setCarregando(false);
      buscarNotificacoes();
      toast.success('Status atualizado com sucesso');
    } catch (error) {
      toast.error(error.message);
    }
  }

  return (
    <Container>
      <h2>Pedido Cancelado</h2>
      <FlexboxGrid align="middle" style={{ gap: '10px' }}>
        <SearchInputTable
          estilo={{ width: '300px' }}
          setTextoDigitado={setTextoDigitado} />
        <InputDataRange
          // format="DD/MM/YYYY"
          ranges={[]}
          // placeholder="Data"
          value={data}
          onChange={setData}
        />
        <Button
          // eslint-disable-next-line react/jsx-no-bind
          onClick={marcarNotificacoesLidas}
          size="sm"
        >
          Marcar Todas como lidas
        </Button>
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '570px' }}>
        <TablePagination
          carregando={carregando}
          dados={notificacoes.lista}
          quantidade={notificacoes.quantidade}
          onChangePage={setPagina}
        >
          <Column resizable width={400}>
            <HeaderCell>Mensagem</HeaderCell>
            <CelulaNotifcacao dataKey="mensagem" />
          </Column>
          <Column resizable width={170}>
            <HeaderCell>Data</HeaderCell>
            <CelulaNotifcacao dataKey="data_formatada" />
          </Column>

        </TablePagination>
      </FlexboxGrid>
    </Container>
  );
}

export default AlteracaoPedido
  ;
