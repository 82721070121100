/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useCallback } from 'react';
import { Table, SelectPicker, FlexboxGrid, Input } from 'rsuite';
import { obterExtremosMes } from '~/utils/datas';
import { toast } from 'react-toastify';
import { format, parseISO, eachDayOfInterval } from 'date-fns';
import { InputDataRange } from '~/components/InputDataRange';
import pt from 'date-fns/locale/pt-BR';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { Container } from '~/styles/tabela';
import api from '~/services/api';
import CelulaAcao from '~/components/Celulas/components/CelulaAcao';
import SearchInputTable from '~/components/SearchInputTable';
import SelectState from '~/components/SelectState';
import { TablePagination } from '~/components/TablePagination';
import { useDebouncedCallback } from 'use-debounce/lib';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend, } from 'recharts';
import FiltroCidades from '../../Mensagens/components/FiltroCidades';



const { HeaderCell, Column, Cell } = Table;

function ControleMatchings() {
  const [cidade, setCidade] = useState('');
  const [matchings, setMatchings] = useState([]);
  const [matchingsGraphic, setMatchingsGraphic] = useState([]);
  // const [controle, setControle] = useState([]);
  const [datas, setDatas] =  useState(obterExtremosMes(new Date()));
  const [filtroData, setFiltroData] = useState();
  const [estadoSelecionado, setEstadoSelecionado] = useState();
  const [filtroCidades, setFiltroCidades] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [pagina, setPagina] = useState(1);
  const [orderBy, setOrderBy] = useState('');

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const buscarControleMatch = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.get('registros/controle-matchings/', {
        params: {
          pagina,
          data: datas,
          cidade: filtroCidades,
          estado: estadoSelecionado,
          orderBy,
          tabela: true
        }
      })
      const lista = resposta.data.controleMatching.map((item) => ({
        ...item,
        nome: item.nome,
        matchings: item.matchings
      }))
      setMatchings(lista)
      const lista2 = resposta.data.controleMatchingGrafico.map((item) => ({
        ...item,
        nome: item.nome,
        finalizados: item.finalizados,
        cancelados: item.cancelados,
        faltas: item.faltas
      }))
      setMatchingsGraphic(lista2)
    } catch (e) {
      toast.error(e.message)
    }
    setCarregando(false);
  }, [
    datas,
    filtroCidades,
    orderBy,
    pagina,
    estadoSelecionado
  ])

  useEffect(() => {
    buscarControleMatch();
  }, [buscarControleMatch]);



  return (
    <Container>
      <h2>Controle de Matchings</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        {/* <div style={{ display: 'flex', flexDirection: 'row'}}> */}
        <InputDataRange
          ranges={[]}
          isoWeek
          value={datas}
          locale={locale}
          onChange={setDatas}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '1490px' }}>
        <TablePagination
          carregando={carregando}
          dados={matchings}
          quantidade={matchings.length}
          onChangePage={setPagina}
          setOrderBy={setOrderBy}
        >
          <Column resizable width={200} sortable>
            <HeaderCell><b>Operador</b></HeaderCell>
            <Cell dataKey="nome" />
          </Column>
          <Column resizable width={120} sortable>
            <HeaderCell>Matchings</HeaderCell>
            <Cell dataKey="matchings" />
          </Column>

          <Column resizable width={120} sortable>
            <HeaderCell>Finalizados</HeaderCell>
            <Cell dataKey="finalizados" />
          </Column>

          <Column resizable width={120}>
            <HeaderCell>Finalizados %</HeaderCell>
            <Cell dataKey="per_finalizados" />
          </Column>
          <Column resizable width={120} sortable>
            <HeaderCell>Cancelados</HeaderCell>
            <Cell dataKey="cancelados" />
          </Column>
          <Column resizable width={120}>
            <HeaderCell>Cancelados %</HeaderCell>
            <Cell dataKey="per_cancelados" />
          </Column>
          <Column resizable width={120} sortable>
            <HeaderCell>Faltas</HeaderCell>
            <Cell dataKey="faltas" />
          </Column>
          <Column resizable width={120}>
            <HeaderCell>Faltas %</HeaderCell>
            <Cell dataKey="per_faltas" />
          </Column>
          <Column resizable width={150} sortable>
            <HeaderCell>Avaliação média</HeaderCell>
            <Cell dataKey="med_avaliacao" />
          </Column>
          <Column resizable width={150} sortable>
            <HeaderCell>Nota menor 4</HeaderCell>
            <Cell dataKey="nt_menor4" />
          </Column>
          <Column resizable width={150} sortable>
            <HeaderCell>Nota maior 4</HeaderCell>
            <Cell dataKey="nt_maior4" />
          </Column>
        </TablePagination>
      </FlexboxGrid>
    </Container>
  );
}

export default ControleMatchings;
