/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useCallback } from 'react';
import { Edit, InfoRound, Plus, Attachment, Icon } from '@rsuite/icons';

import TrashIcon from '@rsuite/icons/Trash';

import {
  Table,
  Input,
  IconButton,
  FlexboxGrid,
  SelectPicker,
  CheckTreePicker,
  Modal,
  Checkbox,
  Button,
  CheckPicker
} from 'rsuite';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { formatDistanceToNow } from 'date-fns';
import moment from 'moment';
import pt from 'date-fns/locale/pt-BR';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Container } from '~/styles/tabela';
import api from '~/services/api';
import SearchInputTable from '~/components/SearchInputTable';
// import { InputDataRange } from '~/components/InputDataRange';
import { GerarCSV as CSV } from '~/utils/gerarCSV'
import { FiltroExperiencias } from '~/components/FiltroExperiencias';
import { FiltroTreinamentos } from '~/components/FiltroTreinamentos';
import SelectState from '~/components/SelectState';
import { TablePagination } from '~/components/TablePagination';
import EditarProfissional from './EditarProfissional';
import DetalhesCarteiraDialog from '../relatorios/CarteiraProfissionais/components/DetalhesCarteiraDialog';

const { HeaderCell, Cell } = Table;

function Profissionais() {
  const [profissionais, setProfissionais] = useState({
    lista: [],
    quantidade: 0,
  });
  const [experiencias, setExperiencias] = useState([]);
  const [experienciaSelecionada, setExperienciaSelecionada] = useState();
  const [interesses, setInteresses] = useState([]);
  const [interesseSelecionado, setInteresseSelecionado] = useState([]);
  const [perfilSelecionado, setPerfilSelecionado] = useState(false);
  const [trabalhouSimOuNao, setTrabalhouSimOuNao] = useState([]);
  const [treinamentos, setTreinamentos] = useState([]);
  const [treinamentoSelecionado, setTreinamentoSelecionado] = useState();
  const [cidade, setCidade] = useState('');
  const [bairro, setBairro] = useState('');
  const [estadoSelecionado, setEstadoSelecionado] = useState();
  const [carregando, setCarregando] = useState(true);
  const [textodigitado, setTextoDigitado] = useState();
  const [pagina, setPagina] = useState(1);
  const [data, setData] = useState();
  const [atributoSelecionado, setAtributoSelecioando] = useState('nome');
  const [profissionalEditando, setProfissionalEditando] = useState();
  const [proExcluirModal, setproExcluirModal] = useState(false);
  const [modalConfirmacao, setModalConfirmacao] = useState(false);
  const [proExcluir, setproExcluir] = useState();
  const [showEditarProfissional, setShowEditarProfissional] = useState(false);
  const [exibirDetalhesDialog, setExibirDetalhesDialog] = useState({
    exibir: false,
  });
  const [buscarCSV, setBuscarCSV] = useState(false);
  const atributosFiltro = [
    {
      label: 'Nome',
      value: 'nome',
    },
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Cpf',
      value: 'cpf_cnpj',
    },
  ];

  const [orderBy, setOrderBy] = useState('order by Tempo de Cadastro desc');

  function getInteresse(status) {
    const map = {
      'freelancer': 'Freelancer',
      'emprego': 'Emprego',
      'emprego_freelancer': 'Emprego e Freelancer',
    };

    return map[status];
  }

  const buscarProfissionais = useCallback(async () => {
    setModalConfirmacao(false)
    setCarregando(true);
    try {
      const resposta = await api.get('profissionais', {
        params: {
          [atributoSelecionado]: textodigitado,
          pagina,
          estado: estadoSelecionado,
          treinamentos: treinamentoSelecionado,
          experiencias: experienciaSelecionada,
          interesses: interesseSelecionado,
          perfil: perfilSelecionado,
          trabalhou: trabalhouSimOuNao,
          cidade,
          bairro,
          orderBy,
          csv: buscarCSV
        },
      });

      const experienci = await api.get('profissao');
      const treinament = await api.get('treinamento');

      const newLista = experienci.data.map((map)=>({
        label: map.profissao,
        value: map.id,
      }))
      setTreinamentos(treinament.data);
      setExperiencias([{
        label: "Experiencias",
        value: 'Todos',
        children: [...newLista]
      }]);

      const lista = resposta.data.lista.map((p) => {
        return {
          // ...item,
          ...(buscarCSV === false && p),
          "Nome": p.nome,
          "Email": p.email,
          "Telefone": p.telefone1,
          "Cpf/Cnpj": p.cpf_cnpj,
          "Tempo de Cadastro": formatDistanceToNow(new Date(p.data_criacao), {
            locale: pt,
          }),
          "Cidade": p.endereco.bairro.cidade.cidade,
          "Estado": p.endereco.bairro.cidade.estado.uf,
          "Bairro": p.endereco.bairro.bairro,
          "Interesse": getInteresse(p.interesses),
          "Pro Liberacao": p.usr_liberacao ? p.usr_liberacao : "",
          "Dt Liberacao": p.dt_liberacao ? moment(p.dt_liberacao).format('DD/MM/YYYY') : "",
          "Resp Freela": p.contagem_registro ? p.contagem_registro : 0,
          "Resp Emprego": p.contagem_recrutamento ? p.contagem_recrutamento : 0,
          "Trabalhou": (p.contagem_recrutamento !== null || p.contagem_registro !== null) ? 'Sim' : 'Não',
          "Perfil completo": (p.id_arquivo !== null && p.id_foto_perfil !== null && p.id_exp_profissao !== null) ? 'Sim' : 'Não',
        }
        })

        if (buscarCSV) {
          CSV({
            data: lista,
            download: true,
          });

          setCarregando(false);
          toast.success(`Foram exportados ${lista.length} registros.`);
          setBuscarCSV(false);
          return;

        }

      setProfissionais({
        quantidade: lista.length > 0 ? lista[0].full_count : 0,
        lista,
      });
      if(profissionais.quantidade === 0) setPagina(1)
    } catch (e) {
      if(buscarCSV){
        setCarregando(false);
        toast.error('Erro ao Exportar os dados');
        setBuscarCSV(false);
        return;
      }
      toast.error(e.message);
    }
    setCarregando(false);
  }, [
    pagina,
    atributoSelecionado,
    textodigitado,
    estadoSelecionado,
    treinamentoSelecionado,
    experienciaSelecionada,
    interesseSelecionado,
    perfilSelecionado,
    trabalhouSimOuNao,
    cidade,
    bairro,
    orderBy,
    buscarCSV,
    setPagina,
    profissionais.quantidade
  ]);

  function download(dados) {
    const blob = new Blob([dados], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'download.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a)
  }

  async function excluirPro() {
    const { id, nome } = proExcluir
    setCarregando(true)
    setproExcluirModal(false)
    try {
      await api.delete(`profissional/${id}`)
      await buscarProfissionais()
      setCarregando(false)
      toast.success(`Profissional ${nome} excluido(a) com sucesso.`)
    } catch (e) {
      setCarregando(false)
      toast.error(e.message)
    }
  }

  useEffect(() => {
    buscarProfissionais();
  }, [buscarProfissionais]);

  function abrirEditarProfissional(profissional) {
    setProfissionalEditando(profissional);
    setShowEditarProfissional(true);
  }

  function fecharEditarProfissional() {
    buscarProfissionais();
    setShowEditarProfissional(false);
  }

  return (
    <Container>
      <h2>Profissionais
        {/* <Icon
          title="Exportar CSV"
          as={Attachment}
          size="4em"
          style={{ marginLeft: '50px' }}
          onClick={() => {
            setExibirDetalhesDialog({
              exibir: true,
            })
          }}
        /> */}

        <IconButton
          title="Exportar CSV"
          style={{ marginLeft: '100px' }}
          size="4em"
          icon={<Attachment />}
          onClick={() => {
            setModalConfirmacao(true);
          }}
        />
      </h2>

      <FlexboxGrid justify="start" style={{ gap: "10px" }}>
        <SearchInputTable setTextoDigitado={setTextoDigitado} />

        <SelectPicker
          appearance="default"
          style={{ marginLeft: '-15px' }}
          searchable={false}
          data={atributosFiltro}
          value={atributoSelecionado}
          cleanable={false}
          onChange={(value) => setAtributoSelecioando(value)}
        />

        <Input
          style={{ width: '250px' }}
          placeholder="Cidade"
          onChange={useDebouncedCallback(setCidade, 500).callback}
        />

        <Input
          style={{ width: '250px' }}
          placeholder="Bairro"
          onChange={useDebouncedCallback(setBairro, 500).callback}
        />

        <SelectState
          selectedState={estadoSelecionado}
          setSelectedStates={setEstadoSelecionado}
        // marginRight
        // style={{ marginRight: '-30px' }}
        />

        <FiltroTreinamentos
          appearance="default"
          style={{ width: 224 }}
          searchable={false}
          data={treinamentos.map((treinamento) => ({
            label: treinamento.treinamento,
            value: treinamento.id,
          }))}
          value={treinamentoSelecionado}
          onChange={(value) => setTreinamentoSelecionado(value)}
        />
        <CheckTreePicker
          style={{ width: 224 }}
          defaultExpandAll
          placeholder="Experiencias"
          appearance="default"
          data={experiencias}
          value={experienciaSelecionada}
          onChange={(value) => setExperienciaSelecionada(value)}
        />
        <CheckPicker
          appearance="default"
          style={{ marginRight: '15px' }}
          searchable={false}
          placeholder="Interesse"
          // data={{ label:"freelancer"}}
          data={[{
            label: 'Freelancer',
            value: 'freelancer',
          },
          {
            label: 'Emprego',
            value: 'emprego',
          },
          {
            label: 'Emprego e Freelancer',
            value: 'emprego_freelancer',
          },
          ]}
          value={interesseSelecionado}
          onChange={(value) => setInteresseSelecionado(value)}
        />
        <SelectPicker
          appearance="default"
          style={{ marginLeft: '-15px' }}
          searchable={false}
          placeholder="Já trabalhou"
          data={[{
            label: 'Sim',
            value: 'sim_trabalhou',
          },
          {
            label: 'Não',
            value: 'nao_trabalhou',
          },
          {
            label: 'Todos',
            value: 'todos',
          },
          ]}
          value={trabalhouSimOuNao}
          cleanable={false}
          onChange={(value) => setTrabalhouSimOuNao(value)}
        />
        <Checkbox
          style={{ marginLeft: '-10px', marginRight: '10px' }}
          checked={perfilSelecionado}
          onChange={(bool, value) => setPerfilSelecionado(value)}
        >Perfil Bom</Checkbox>
      </FlexboxGrid>

      <FlexboxGrid style={{ width: '2470px' }}>
      <TablePagination
        carregando={carregando}
        dados={profissionais.lista}
        quantidade={profissionais.quantidade}
        onChangePage={setPagina}
        setOrderBy={setOrderBy}
      >
        <Table.Column resizable width={100}>
          <HeaderCell align='center'>Opções</HeaderCell>
          <Cell dataKey="id" style={{ padding: '5px' }}>
            {(rowData) => (
              <>
                <IconButton
                  title="Detalhes"
                  appearance="subtle"
                  onClick={() => {
                    setExibirDetalhesDialog({
                      exibir: true,
                      id_profissional: rowData.id,
                    });
                  }}
                  icon={<InfoRound />}
                />
                <IconButton
                  title="Editar"
                  appearance="subtle"
                  onClick={() => {
                    abrirEditarProfissional(rowData);
                  }}
                  icon={<Edit />}
                />
              </>
            )}
          </Cell>
        </Table.Column>
        <Table.Column resizable width={240} sortable>
          <HeaderCell>Nome</HeaderCell>
          <Cell dataKey="Nome" />
        </Table.Column>

        <Table.Column resizable width={240} sortable>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="Email" />
        </Table.Column>

        <Table.Column resizable width={150} sortable>
          <HeaderCell>Cpf/Cnpj</HeaderCell>
          <Cell dataKey="Cpf/Cnpj" />
        </Table.Column>
        <Table.Column resizable width={200} sortable>
          <HeaderCell>Tempo Cadastro</HeaderCell>
          <Cell dataKey="Tempo de Cadastro" />
        </Table.Column>
        <Table.Column resizable width={150} sortable>
          <HeaderCell>Cidade</HeaderCell>
          <Cell dataKey="Cidade" />
        </Table.Column>
        <Table.Column resizable width={90} sortable>
          <HeaderCell>Estado</HeaderCell>
          <Cell dataKey="Estado" />
        </Table.Column>
        <Table.Column resizable width={150} sortable>
          <HeaderCell>Bairro</HeaderCell>
          <Cell dataKey="Bairro" />
        </Table.Column>
        <Table.Column resizable width={150}>
          <HeaderCell>Interesse</HeaderCell>
          <Cell dataKey="Interesse" />
        </Table.Column>
        <Table.Column resizable width={150}>
          <HeaderCell>Pro Liberação</HeaderCell>
          <Cell dataKey="Pro Liberacao" />
        </Table.Column>
        <Table.Column resizable width={150}>
          <HeaderCell>Dt Liberação</HeaderCell>
          <Cell dataKey="Dt Liberacao" />
        </Table.Column>
        <Table.Column resizable width={150}>
          <HeaderCell>Resp Freela</HeaderCell>
          <Cell dataKey="Resp Freela" />
        </Table.Column>
        <Table.Column resizable width={150}>
          <HeaderCell>Resp Emprego</HeaderCell>
          <Cell dataKey="Resp Emprego" />
        </Table.Column>
        <Table.Column resizable width={150}>
          <HeaderCell>Trabalhou?</HeaderCell>
          <Cell dataKey="Trabalhou" />
        </Table.Column>
        <Table.Column resizable width={150}>
          <HeaderCell>Perfil Completo?</HeaderCell>
          <Cell dataKey="Perfil Completo" />
        </Table.Column>
        <Table.Column resizable width={100}>
          <HeaderCell align='left'>Excluir</HeaderCell>
          <Cell dataKey="id" align='left' style={{ padding: '5px' }}>
            {(rowData) => (
              <>
                <IconButton
                  appearance="subtle"
                  onClick={() => {
                    setproExcluir(rowData)
                    setproExcluirModal(true)
                  }}
                  color="red"
                  icon={<TrashIcon />}
                />
              </>
            )}
          </Cell>
        </Table.Column>
      </TablePagination>
      </FlexboxGrid>

      <DetalhesCarteiraDialog
        exibir={exibirDetalhesDialog.exibir}
        id_profissional={exibirDetalhesDialog.id_profissional}
        datas={data}
        onFechar={() => setExibirDetalhesDialog({ exibir: false })}
      />
      <EditarProfissional
        idProfissional={profissionalEditando && profissionalEditando.id}
        showEditarProfissional={showEditarProfissional}
        setShowEditarProfissional={setShowEditarProfissional}
        fecharEditarProfissional={fecharEditarProfissional}
        atualizarLista={buscarProfissionais}
      />
      <Modal
        open={modalConfirmacao}
        onClose={() => setModalConfirmacao(false)}
        size="xs"
      >
        <Modal.Header>Gerar Relatório CSV</Modal.Header>
        <Modal.Body>
          <p>
            Deseja realmente gerar o csv dos profissionais com o filtro
            atual?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalConfirmacao(false)}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <Button
            appearance="primary"
            type="button"
            onClick={() => {
              setBuscarCSV(true)
              buscarProfissionais();
            }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        open={proExcluirModal}
        onClose={() => setproExcluirModal(false)}
        size="xs"
      >
        <Modal.Header>Excluir Profissional</Modal.Header>
        <Modal.Body>
          <p>Deseja realmente excluir este Profissional?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setproExcluirModal(false)}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <Button
            appearance="primary"
            type="button"
            onClick={() => {
              excluirPro();
            }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Profissionais;
