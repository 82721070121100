/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import { Edit, InfoRound } from '@rsuite/icons';

import { Table, FlexboxGrid, SelectPicker, IconButton } from 'rsuite';
import { toast } from 'react-toastify';
import { Container } from '~/styles/tabela';
import moment from 'moment';
import api from '~/services/api';
import SearchInputTable from '~/components/SearchInputTable';
import { FiltroExperiencias } from '~/components/FiltroExperiencias';
import { FiltroTreinamentos } from '~/components/FiltroTreinamentos';
import SelectState from '~/components/SelectState';
import { TablePagination } from '~/components/TablePagination'
import { TabelaPaginacao } from '~/components/TabelaPaginacao';
import EditarProfissional from '../../Profissionais/EditarProfissional';
import DetalhesCarteiraDialog from '../CarteiraProfissionais/components/DetalhesCarteiraDialog';
import ModalNotificacaoEmergencia from './components/ModalNotificacaoEmergencia';

const { HeaderCell, Column, Cell } = Table;

function RelatorioProfissionaisEmergencia() {
  const [relatorios, setRelatorios] = useState({
    lista: [],
    quantidade: 0,
  });
  const [orderBy, setOrderBy] = useState()
  const [experiencias, setExperiencias] = useState([]);
  const [experienciaSelecionada, setExperienciaSelecionada] = useState();
  const [treinamentos, setTreinamentos] = useState([]);
  const [treinamentoSelecionado, setTreinamentoSelecionado] = useState();
  const [estadoSelecionado, setEstadoSelecionado] = useState();
  const [atributoSelecionado, setAtributoSelecioando] = useState('nome');
  const [textodigitado, setTextoDigitado] = useState();
  const [pagina, setPagina] = useState(1);
  const [carregando, setCarregando] = useState(false);
  const [exibirDetalhesDialog, setExibirDetalhesDialog] = useState({
    exibir: false,
  });
  const [profissionalEditando, setProfissionalEditando] = useState();
  const [showEditarProfissional, setShowEditarProfissional] = useState(false);
  const [filtroData, setFiltroData] = useState();
  const atributosFiltro = [
    {
      label: 'Nome',
      value: 'nome',
    },
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Cpf',
      value: 'cpf_cnpj',
    },
    {
      label: 'Cidade',
      value: 'cidade',
    },
  ];
  const buscarRelatorios = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.get('relatorios/profissionais-emergencia', {
        params: {
          pagina,
          [atributoSelecionado]: textodigitado,
          estado: estadoSelecionado,
          experiencias: experienciaSelecionada,
          treinamentos: treinamentoSelecionado,
          orderBy,
        },
      });

      const lista = resposta.data.lista.map((p) => ({
        ...p,
        estado: p.endereco.bairro.cidade.estado.uf,
        cidade: p.endereco.bairro.cidade.cidade,
        created_at: moment(p.created_at).format('DD/MM/YYYY | HH:mm')
        }))

      setRelatorios({
        quantidade: lista.length > 0 ? lista[0].full_count : 0,
        lista
      });
      if(relatorios.quantidade === 0) setPagina(1)

      const experiencias1 = await api.get('profissao');
      setExperiencias(experiencias1.data);
      const treinamentos1 = await api.get('treinamento');
      setTreinamentos(treinamentos1.data);
    } catch (e) {
      toast.error(e.message);
    }

    setCarregando(false);
  }, [
    pagina,
    atributoSelecionado,
    textodigitado,
    estadoSelecionado,
    experienciaSelecionada,
    treinamentoSelecionado,
    orderBy,
    setPagina,
    relatorios.quantidade
  ]);

  useEffect(() => {
    buscarRelatorios();
  }, [buscarRelatorios]);

  function abrirEditarProfissional(profissional) {
    setProfissionalEditando(profissional);
    setShowEditarProfissional(true);
  }

  function fecharEditarProfissional() {
    buscarRelatorios();
    setShowEditarProfissional(false);
  }

  return (
    <Container>
      <h2>Disponíveis para Emergência</h2>
      <FlexboxGrid justify="start" style={{ gap: "10px" }}>
        <SearchInputTable setTextoDigitado={setTextoDigitado} />

        <SelectPicker
          appearance="default"
          style={{ marginLeft: '-15px' }}
          searchable={false}
          data={atributosFiltro}
          value={atributoSelecionado}
          cleanable={false}
          onChange={(value) => setAtributoSelecioando(value)}
        />

        <SelectState
          selectedState={estadoSelecionado}
          setSelectedStates={setEstadoSelecionado}
        // marginRight
        />
        <FiltroExperiencias
          style={{ width: 224 }}
          appearance="default"
          data={experiencias.map((profissao) => ({
            label: profissao.profissao,
            value: profissao.id,
          }))}
          value={experienciaSelecionada}
          onChange={(value) => setExperienciaSelecionada(value)}
        />
        <FiltroTreinamentos
          appearance="default"
          style={{ width: 224 }}
          searchable={false}
          data={treinamentos.map((treinamento) => ({
            label: treinamento.treinamento,
            value: treinamento.id,
          }))}
          value={treinamentoSelecionado}
          onChange={(value) => setTreinamentoSelecionado(value)}
        />

        <ModalNotificacaoEmergencia
        atributoSelecionado={atributoSelecionado}
        textodigitado={textodigitado}
        estadoSelecionado={estadoSelecionado}
        experienciaSelecionada={experienciaSelecionada}
        treinamentoSelecionado={treinamentoSelecionado}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '1350px' }}>

        <TablePagination
          carregando={carregando}
          dados={relatorios.lista}
          quantidade={relatorios.quantidade}
          onChangePage={setPagina}
          setOrderBy={setOrderBy}
        >
          <Column resizable width={100}>
            <HeaderCell>Opções</HeaderCell>
            <Cell dataKey="id" style={{ padding: '5px' }}>
              {(rowData) => (
                <>
                  <IconButton
                    title="Detalhes"
                    appearance="subtle"
                    onClick={() => {
                      setExibirDetalhesDialog({
                        exibir: true,
                        id_profissional: rowData.id,
                      });
                    }}
                    icon={<InfoRound />}
                  />
                  <IconButton
                    title="Editar"
                    appearance="subtle"
                    onClick={() => {
                      abrirEditarProfissional(rowData);
                    }}
                    icon={<Edit />}
                  />
                </>
              )}
            </Cell>
          </Column>
          <Column resizable width={250} sortable>
            <HeaderCell>Nome</HeaderCell>
            <Cell dataKey="nome" />
          </Column>
          <Column resizable width={250} sortable>
            <HeaderCell>Email</HeaderCell>
            <Cell dataKey="email" />
          </Column>
          <Column resizable width={150}>
            <HeaderCell>Telefone</HeaderCell>
            <Cell dataKey="telefone1" />
          </Column>
          <Column resizable width={90}>
            <HeaderCell>Início</HeaderCell>
            <Cell dataKey="hora_inicio" />
          </Column>
          <Column resizable width={90}>
            <HeaderCell>Fim</HeaderCell>
            <Cell dataKey="hora_fim" />
          </Column>
          <Column resizable width={150} sortable>
            <HeaderCell>Cidade</HeaderCell>
            <Cell dataKey="cidade" />
          </Column>
          <Column resizable width={90} sortable>
            <HeaderCell>Estado</HeaderCell>
            <Cell dataKey="estado" />
          </Column>
          <Column resizable width={180} sortable>
            <HeaderCell>Data e Hora</HeaderCell>
            <Cell dataKey="created_at" />
          </Column>
        </TablePagination>
      </FlexboxGrid>

      <DetalhesCarteiraDialog
        exibir={exibirDetalhesDialog.exibir}
        id_profissional={exibirDetalhesDialog.id_profissional}
        datas={filtroData}
        onFechar={() => setExibirDetalhesDialog({ exibir: false })}
      />
      <EditarProfissional
        idProfissional={profissionalEditando && profissionalEditando.id}
        showEditarProfissional={showEditarProfissional}
        setShowEditarProfissional={setShowEditarProfissional}
        fecharEditarProfissional={fecharEditarProfissional}
        atualizarLista={buscarRelatorios}
      />
    </Container>
  );
}

export default RelatorioProfissionaisEmergencia;
