/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import { Edit, InfoRound } from '@rsuite/icons';

import { Table, FlexboxGrid, IconButton } from 'rsuite';
import { toast } from 'react-toastify';
import { Container } from '~/styles/tabela';

import api from '~/services/api';
import SelectState from '~/components/SelectState';

import { InputData } from '~/components/InputData';
import { FiltroTreinamentos } from '~/components/FiltroTreinamentos';
import { FiltroExperiencias } from '~/components/FiltroExperiencias';
import { TabelaPaginacao } from '~/components/TabelaPaginacao';
import EditarProfissional from '../../Profissionais/EditarProfissional';
import DetalhesCarteiraDialog from '../CarteiraProfissionais/components/DetalhesCarteiraDialog';

const { HeaderCell, Column, Cell } = Table;

function RelatoriosProfissional() {
  const [filtroData, setFiltroData] = useState(new Date());
  const [data, setData] = useState();
  const [relatorios, setRelatorios] = useState({
    lista: [],
    quantidade: 0,
  });
  const [experiencias, setExperiencias] = useState([]);
  const [experienciaSelecionada, setExperienciaSelecionada] = useState();
  const [treinamentos, setTreinamentos] = useState([]);
  const [treinamentoSelecionado, setTreinamentoSelecionado] = useState();
  const [pagina, setPagina] = useState(1);
  const [carregando, setCarregando] = useState(false);
  const [estadoSelecionado, setEstadoSelecionado] = useState();
  const [exibirDetalhesDialog, setExibirDetalhesDialog] = useState({
    exibir: false,
  });
  const [profissionalEditando, setProfissionalEditando] = useState();
  const [showEditarProfissional, setShowEditarProfissional] = useState(false);

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const buscarRelatorios = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.post('relatorios/profissionais-disponiveis', {
        params: {
          diaSemana: filtroData,
          pagina,
          estado: estadoSelecionado,
          experiencias: experienciaSelecionada,
          treinamentos: treinamentoSelecionado,
        },
      });

      const experiencias1 = await api.get('profissao');
      setExperiencias(experiencias1.data);
      const treinamentos1 = await api.get('treinamento');
      setTreinamentos(treinamentos1.data);

      const {lista} = resposta.data
      setRelatorios({
        lista,
        quantidade: lista.length > 0 ? lista[0].full_count : 0 ,
      });
      if(relatorios.quantidade === 0) setPagina(1)
    } catch (e) {
      toast.error(e.message);
    }

    setCarregando(false);
  }, [
    pagina,
    filtroData,
    estadoSelecionado,
    experienciaSelecionada,
    treinamentoSelecionado,
    setPagina,
    relatorios.quantidade
  ]);

  useEffect(() => {
    buscarRelatorios();
  }, [buscarRelatorios]);

  function abrirEditarProfissional(profissional) {
    setProfissionalEditando(profissional);
    setShowEditarProfissional(true);
  }

  function fecharEditarProfissional() {
    buscarRelatorios();
    setShowEditarProfissional(false);
  }

  return (
    <Container>
      <h2>Profissionais Disponíveis</h2>
      <FlexboxGrid justify="start" style={{ gap: "10px" }}>
        <InputData
          cleanable={false}
          ranges={[]}
          isoWeek
          oneTap
          locale={locale}
          value={filtroData}
          onChange={setFiltroData}
          style={{ marginRight: '0px' }}
        />
        <SelectState
          selectedState={estadoSelecionado}
          setSelectedStates={setEstadoSelecionado}
        // marginRight
        />
        <FiltroExperiencias
          style={{ width: 224 }}
          appearance="default"
          data={experiencias.map((profissao) => ({
            label: profissao.profissao,
            value: profissao.id,
          }))}
          value={experienciaSelecionada}
          onChange={(value) => setExperienciaSelecionada(value)}
        />
        <FiltroTreinamentos
          appearance="default"
          style={{ marginRight: '15px', width: 224 }}
          searchable={false}
          data={treinamentos.map((treinamento) => ({
            label: treinamento.treinamento,
            value: treinamento.id,
          }))}
          value={treinamentoSelecionado}
          onChange={(value) => setTreinamentoSelecionado(value)}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '950px' }}>
        <TabelaPaginacao
          carregando={carregando}
          dados={relatorios.lista}
          quantidade={relatorios.quantidade}
          onChangePage={setPagina}
        >
          <Column resizable width={100}>
            <HeaderCell>Opções</HeaderCell>
            <Cell dataKey="id" style={{ padding: '5px' }}>
              {(rowData) => (
                <>
                  <IconButton
                    title="Detalhes"
                    appearance="subtle"
                    onClick={() => {
                      setExibirDetalhesDialog({
                        exibir: true,
                        id_profissional: rowData.id,
                      });
                    }}
                    icon={<InfoRound />}
                  />
                  <IconButton
                    title="Editar"
                    appearance="subtle"
                    onClick={() => {
                      abrirEditarProfissional(rowData);
                    }}
                    icon={<Edit />}
                  />
                </>
              )}
            </Cell>
          </Column>
          <Column resizable width={250}>
            <HeaderCell>Nome</HeaderCell>
            <Cell dataKey="nome" />
          </Column>
          <Column resizable width={150}>
            <HeaderCell>CPF</HeaderCell>
            <Cell dataKey="cpf_cnpj" />
          </Column>
          <Column resizable width={150}>
            <HeaderCell>Telefone</HeaderCell>
            <Cell dataKey="telefone1" />
          </Column>
          <Column resizable width={200}>
            <HeaderCell>Cidade</HeaderCell>
            <Cell dataKey="cidade" />
          </Column>
          <Column resizable width={100}>
            <HeaderCell>Estado</HeaderCell>
            <Cell dataKey="uf" />
          </Column>
        </TabelaPaginacao>
      </FlexboxGrid>
      <DetalhesCarteiraDialog
        exibir={exibirDetalhesDialog.exibir}
        id_profissional={exibirDetalhesDialog.id_profissional}
        datas={data}
        onFechar={() => setExibirDetalhesDialog({ exibir: false })}
      />
      <EditarProfissional
        idProfissional={profissionalEditando && profissionalEditando.id}
        showEditarProfissional={showEditarProfissional}
        setShowEditarProfissional={setShowEditarProfissional}
        fecharEditarProfissional={fecharEditarProfissional}
        atualizarLista={buscarRelatorios}
      />
    </Container>
  );
}

export default RelatoriosProfissional;
