/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Button, FlexboxGrid, Table } from 'rsuite';

import { useDispatch, useSelector } from 'react-redux';
import { Container } from '~/styles/tabela';

import {
  buscarNotificacoes,
  marcarNotificacoesLidas as atualizarNotificacoesLidas,
} from '~/store/modulos/notificacao/actions';
import SearchInputTable from '~/components/SearchInputTable';
import { InputDataRange } from '~/components/InputDataRange';
import { TablePagination } from '~/components/TablePagination';
import { CelulaNotifcacao } from '~/components/CelulaNotificacao';

const { HeaderCell, Column } = Table;

function NovoProfissional() {
  const [pagina, setPagina] = useState(1);
  const [data, setData] = useState([]);
  const [textodigitado, setTextoDigitado] = useState('');
  const notificacoes = useSelector((state) => state.notificacao.notificacoes);
  const carregando = useSelector((state) => state.notificacao.carregando);
  const novosProfissionaisCriadosNaoLidos = useSelector((state) => state.notificacao.novosProfissionaisCriadosNaoLidos);

  const dispatch = useDispatch();

  useEffect(() => {
    if(notificacoes.quantidade > 0 && notificacoes.lista.length === 0) setPagina(1)
    if (data) {
      dispatch(
        buscarNotificacoes({
          pagina,
          titulo: "Profissional novo criado",
          mensagem: textodigitado,
          dataInicio: data[0],
          dataFim: data[1],
        })
      );
    }
    if (!data) {
    dispatch(
    buscarNotificacoes({
      pagina,
      titulo: "Profissional novo criado",
      mensagem: textodigitado,
    })
    );
    }
  }, [pagina, dispatch, textodigitado, data, novosProfissionaisCriadosNaoLidos, notificacoes.quantidade]);

  async function marcarNotificacoesLidas() {
    dispatch(atualizarNotificacoesLidas({ pagina, titulo: "Profissional novo criado" }));
  }

  return (
    <Container>
      <h2>Novos Profissionais</h2>
      <FlexboxGrid align="middle" style={{ gap: "10px" }}>
        <SearchInputTable setTextoDigitado={setTextoDigitado} />
        <InputDataRange
          // format="DD/MM/YYYY"
          ranges={[]}
          // placeholder="Data"
          value={data}
          onChange={setData}
        />
        <Button
          style={{ marginLeft: '15px' }}
          // eslint-disable-next-line react/jsx-no-bind
          onClick={marcarNotificacoesLidas}
          size="sm"
        >
          Marcar Todas como lidas
        </Button>
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '570px' }}>
        <TablePagination
          carregando={carregando}
          dados={notificacoes.lista}
          quantidade={notificacoes.quantidade}
          onChangePage={setPagina}
        >
          <Column resizable width={400}>
            <HeaderCell>Mensagem</HeaderCell>
            <CelulaNotifcacao dataKey="mensagem" />
          </Column>
          <Column resizable width={170}>
            <HeaderCell>Data</HeaderCell>
            <CelulaNotifcacao dataKey="data_formatada" />
          </Column>
        </TablePagination>
      </FlexboxGrid>
    </Container>
  );
}

export default NovoProfissional;
