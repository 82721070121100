/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Plus, RemindFill, Search } from '@rsuite/icons';

import {
  Table,
  Input,
  InputGroup,
  Button,
  FlexboxGrid,
  Modal,
  Form,
  IconButton,
  Schema,
  SelectPicker,
} from 'rsuite';
import { useDebouncedCallback } from 'use-debounce/lib';
import { toast } from 'react-toastify';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import {
  CelulaEditavel,
} from '~/components/Celulas/components/CelulaEditavel';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';
import { CelulaAcaoHabilidade } from './components/CelulaEditavel';
import { ResponsiveFormTextArea } from '../../../components/ResponsiveForm';

const { HeaderCell, Column, Cell } = Table;

const { StringType, NumberType } = Schema.Types;

const modelo = Schema.Model({
  habilidade: StringType().isRequired('Habilidade Obrigatório'),
  id_profissao: NumberType().isRequired('ID Serviço Obrigatório'),
});

function Habilidades() {
  const [dados, setDados] = useState({
    listaInicial: [],
    habilidadeLista: [],
    habilidadeListaFiltrada: [],
    profissaoLista: [],
    filtro: '',
  });
  const [apagarHabilidadeDialog, setApagarHabilidadeDialog] = useState({
    exibir: false,
    habilidade: {},
  });
  const [editarHabilidadeDialog, setEditarHabilidadeDialog] = useState({
    exibir: false,
    habilidade: {},
  });
  const [carregando, setCarregando] = useState(true);
  const [adicionarHabilidadeDialog, setHabilidadeDialog] = useState(false);
  const [camposInput, setCamposInput] = useState({});
  // const [pagina, setPagina] = useState(1);
  // const [orderBy, setOrderBy] = useState('');
  const [atributoSelecionado, setAtributoSelecionado] = useState('profissao');
  // const [activePage, setActivePage] = useState(5);

  async function buscarHabilidades() {
    try {
      const profissao = await api.get('profissao');
      const resposta = await api.get('habilidade');
      const resposta1 = await api.get('habilidade');
      const lista = resposta.data.map((habilidade) => ({
        ...habilidade,
        id_profissao: habilidade.profissao.id,
        profissao: profissao.data.find((p) => p.id === habilidade.profissao.id)
          .profissao,
      }));
      setDados({
        listaInicial: resposta1.data,
        habilidadeLista: lista,
        habilidadeListaFiltrada: [],
        profissaoLista: profissao.data,
      });
      setCarregando(false);
    } catch (e) {
      setCarregando(false);
      toast.error(e.message);
    }
  }

  function filtrarLista(texto) {
    setDados({
      ...dados,
      habilidadeListaFiltrada: texto
        ? dados.habilidadeLista.filter((habilidade) =>
          habilidade[atributoSelecionado]
            .toString()
            .toUpperCase()
            .includes(texto && texto.toUpperCase())
        )
        : [],
      filtro: texto,
    });
  }

  useEffect(() => {
    buscarHabilidades();
  }, []);
  function filtrarListaServico(texto) {
    setDados({
      ...dados,
      habilidadeListaFiltrada: texto
        ? dados.habilidadeLista.filter((servico) =>
          servico[atributoSelecionado]
            .toString()
            .toUpperCase()
            .includes(texto && texto.toUpperCase())
        )
        : [],
      filtro: texto,
    });
  }

  function abrirApagarDialog(habilidade) {
    setApagarHabilidadeDialog({
      exibir: true,
      habilidade,
    });
  }

  function fecharApagarDialog() {
    setApagarHabilidadeDialog({
      exibir: false,
      habilidade: {},
    });
  }

  function abrirEditarDialog(habilidade) {
    setEditarHabilidadeDialog({
      exibir: true,
      habilidade,
    });
  }

  function fecharEditarDialog() {
    setEditarHabilidadeDialog({
      exibir: false,
      habilidade: {},
    });
  }

  async function excluirHabilidade() {
    try {
      await api.delete(`/habilidade/${apagarHabilidadeDialog.habilidade.id}`);
      toast.success('Habiliade excluida com sucesso');
      fecharApagarDialog();
      buscarHabilidades();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function adicionarHabilidade() {
    try {
      setCarregando(true);
      await api.post(`/habilidade`, camposInput);
      toast.success('Habilidade criada com sucesso');
      setCarregando(false);
      setHabilidadeDialog(false);
      buscarHabilidades();
    } catch (e) {
      toast.error(e.message);
    }
  }
  async function editarHabilidade() {
    try {
      setCarregando(true);
      await api.put(
        `/habilidade/${editarHabilidadeDialog.habilidade.id}`,
        editarHabilidadeDialog.habilidade
      );
      toast.success('Habilidade editada com sucesso');
      setCarregando(false);
      buscarHabilidades();
      fecharEditarDialog(false);
    } catch (e) {
      toast.error(e.message);
    }
  }

  function alterarTextoCelula(id, key, value) {
    const novaLista = Object.assign([], dados.habilidadeLista);
    novaLista.find((item) => item.id === id)[key] = value;
    setDados({ ...dados, habilidadeLista: novaLista });
    filtrarLista(dados.filtro);
  }

  // async function alterarEstadoCelula(id) {
  //   const novaLista = Object.assign([], dados.habilidadeLista);
  //   const item = novaLista.find((habilidade) => habilidade.id === id);
  //   const itemInicial = dados.listaInicial.find(
  //     (habilidade) => habilidade.id === id
  //   );
  //   if (item.status === 'EDIT' && item.habilidade !== itemInicial.habilidade) {
  //     try {
  //       setCarregando(true);
  //       await api.put(`habilidade/${id}`, item);
  //       toast.success('Habilidade atualizada com sucesso');
  //       buscarHabilidades();
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //     setCarregando(false);
  //   } else {
  //     item.status = item.status ? null : 'EDIT';
  //     setDados({ ...dados, habilidadeLista: novaLista });
  //     filtrarLista(dados.filtro);
  //   }
  // }

  return (
    <Container>
      <h2>Habilidade</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <InputGroup style={{ width: '300px' }}>
          <Input
            name="habilidade"
            style={{ width: '180px' }}
            placeholder="Habilidade"
            onChange={useDebouncedCallback(filtrarLista, 500).callback}
            onFocus={() => setAtributoSelecionado('habilidade')}
          />
          <InputGroup.Button>
            <Search />
          </InputGroup.Button>
        </InputGroup>

        <InputGroup style={{ width: '300px' }}>
          <Input
            name="profissao"
            style={{ width: '180px' }}
            placeholder="Serviço"
            onChange={useDebouncedCallback(filtrarListaServico, 500).callback}
            onFocus={() => setAtributoSelecionado('profissao')}
          />
          <InputGroup.Button>
            <Search />
          </InputGroup.Button>
        </InputGroup>
        <IconButton
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setHabilidadeDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '880px' }}>
        <TabelaSemPaginacao
          carregando={carregando}
          dados={dados.habilidadeListaFiltrada}
        >
          <Column resizable width={340} sortable>
            <HeaderCell>Habilidade</HeaderCell>
            <CelulaEditavel dataKey="habilidade" onChange={alterarTextoCelula} />
          </Column>

          <Column resizable width={340} sortable>
            <HeaderCell>Serviço</HeaderCell>
            <Cell dataKey="profissao" />
          </Column>

          <Column resizable width={200}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcaoHabilidade
              dataKey="id"
              onClick={abrirEditarDialog}
              onDelete={abrirApagarDialog}
            />
          </Column>
        </TabelaSemPaginacao>
      </FlexboxGrid>

      <Modal
        backdrop="static"
        open={adicionarHabilidadeDialog}
        onClose={() => setHabilidadeDialog(false)}
        size="xs"
      >
        <Form
          model={modelo}
          onChange={setCamposInput}
          onSubmit={(status) => status && adicionarHabilidade()}
        >
          <Modal.Header>
            <h3>Adicionar Habilidade</h3>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.ControlLabel>Habilidade</Form.ControlLabel>
              <Form.Control name="habilidade" />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>ID Serviço</Form.ControlLabel>
              <Form.Control
                name="id_profissao"
                accepter={SelectPicker}
                placeholder="Selecione"
                data={dados.profissaoLista.map((p) => ({
                  label: p.profissao,
                  value: p.id,
                }))}
                style={{ width: '300px' }}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => setHabilidadeDialog(false)}
              appearance="subtle"
            >
              Cancelar
            </Button>
            <ButtonFullBlue type="submit" appearance="primary">
              Adicionar
            </ButtonFullBlue>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        backdrop="static"
        open={editarHabilidadeDialog.exibir}
        onCLose={fecharEditarDialog}
        size="xs"
      >
        <Form
          model={modelo}
          onChange={setCamposInput}
          onSubmit={(status) => status && editarHabilidade()}
        >
          <Modal.Header>
            <h3>Editar Habilidade</h3>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.ControlLabel>Nome:</Form.ControlLabel>
              <Form.Control
                defaultValue={editarHabilidadeDialog.habilidade.habilidade}
                onChange={(value) =>
                  setEditarHabilidadeDialog({
                    ...editarHabilidadeDialog,
                    habilidade: {
                      ...editarHabilidadeDialog.habilidade,
                      habilidade: value,
                    },
                  })
                }
                name="habilidade"
              />
            </Form.Group>
            <Form.Group>
              <ResponsiveFormTextArea
                label="Descrição:"
                name="descricao"
                rows={4}
                defaultValue={editarHabilidadeDialog.habilidade.descricao || ''}
                onChange={(value) =>
                  setEditarHabilidadeDialog({
                    ...editarHabilidadeDialog,
                    habilidade: {
                      ...editarHabilidadeDialog.habilidade,
                      descricao: value,
                    },
                  })
                }
                componentClass="textarea"
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={fecharEditarDialog} appearance="subtle">
              Cancelar
            </Button>
            <ButtonFullBlue
              onClick={() => editarHabilidade()}
              appearance="primary"
            >
              Editar
            </ButtonFullBlue>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        backdrop="static"
        open={apagarHabilidadeDialog.exibir}
        onCLose={fecharApagarDialog}
        size="xs"
      >
        <Modal.Header>
          <h3>Apagar Habilidade</h3>
        </Modal.Header>
        <Modal.Body>
          Você realmente deseja apagar esta habilidade? <br /> <br />
          id: {apagarHabilidadeDialog.habilidade.id} <br />
          habilidade: {apagarHabilidadeDialog.habilidade.habilidade} <br />
          Essa ação é irreversível{' '}
          <RemindFill
            style={{
              color: '#ffb300',
              fontSize: 24,
            }}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={fecharApagarDialog} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            onClick={() => excluirHabilidade()}
            appearance="primary"
          >
            Deletar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Habilidades;
