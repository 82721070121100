import React, { useState, useEffect, useContext } from 'react';
import { startOfDay, endOfDay, eachDayOfInterval } from 'date-fns';
import {
  Form,
  Panel,
  Schema,
  Loader,
  FlexboxGrid,
  SelectPicker,
  Button,
  TagGroup,
  Checkbox,
  Tag,
  Modal,
  Table,
  Divider,
  Toggle,
  DateRangePicker,
} from 'rsuite';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import api from '~/services/api';
import FilterUsersNotification from '~/components/FilterUsersNotification';
import { TablePagination } from '~/components/TablePagination';
import { NotificationToggle } from '../EnviarNotificacaoProfissional/styles';
import { ButtonBorderBlue } from '../../../components/Buttons/ButtonBorderBlue/styles';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';
import FiltroProfissionais from '../components/FiltroProfissionais';
import FiltroEstabelecimentos from '../components/FiltroEstabelecimentos';
import FiltroCidades from '../components/FiltroCidades';
import FiltroBairros from '../components/FiltroBairros';
import Announcement from '../components/Announcement';
import * as Styled from '../EnviarNotificacaoProfissional/styles';
import { DarkModeContext } from '../../../common/contexts/darkMode';

const { HeaderCell, Column, Cell } = Table;

const { StringType } = Schema.Types;

function Notificacoes() {
  const [camposInput, setCamposInput] = useState({});
  const [carregando, setCarregando] = useState(false);
  const [exibirDialog, setExibirDialog] = useState(false);
  const [quantidade, setQuantidade] = useState(0);
  const [inadimplentes, setInadimplentes] = useState(false);
  const [estabelecimentos, setEstabelecimentos] = useState();
  const [statusSelecionado, setStatusSelecionado] = useState('Finalizado');
  const [atributoSelecionado, setAtributoSelecioando] = useState({
    label: 'Cidade',
    value: 'cidade',
  });
  const loggeduser = useSelector((state) => state.setUser.data);
  const [pagina, setPagina] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [filtroProfissionais, setFiltroProfissionais] = useState([]);
  const [filtroEstabelecimentos, setFiltroEstabelecimentos] = useState([]);
  const [filtroCidades, setFiltroCidades] = useState([]);
  const [filtroBairros, setFiltroEstados] = useState([]);

  const [bloqueados, setBloqueados] = useState(0);

  const [disableCommunication, setDisableCommunication] = useState(true);

  const [dataAtividade, setDataAtividade] = useState([]);
  const [pedidoFiltroSelecionado, setPedidoFiltroSelecionado] = useState({
    label: 'Todos',
    value: 'todos',
  }
  );

  const { toggleMode } = useContext(DarkModeContext)

  const pedidosFiltro = dataAtividade.length === 0 ? [
    {
      label: 'Todos',
      value: 'todos'
    },
    {
      label: 'Realizou Pedidos',
      value: 'realizou pedidos'
    },
    {
      label: 'Não Realizou Pedidos',
      value: 'não realizou pedidos'
    }
  ] : [
    {
      label: 'Realizou Pedidos',
      value: 'realizou pedidos'
    },
    {
      label: 'Não Realizou Pedidos',
      value: 'não realizou pedidos'
    }
  ]

  const statusPeriodo = [
    {
      label: 'Finalizado',
      value: 'Finalizado',
    },
    {
      label: 'Cancelado',
      value: 'Cancelado',
    },
    {
      label: 'Faltou',
      value: 'Faltou',
    },
  ];

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const atributosFiltro = [
    {
      label: 'Cidade',
      value: 'cidade',
    },
    {
      label: 'Bairro',
      value: 'bairro',
    },
    {
      label: 'Profissional',
      value: 'profissional',
    },
    {
      label: 'Cliente',
      value: 'estabelecimento',
    },
  ];

  const [orderBy, setOrderBy] = useState('');

  const modelo = Schema.Model({
    titulo: StringType().isRequired('Título é obrigatório'),
    mensagem: StringType().isRequired('Mensagem é obrigatória'),
  });
  let erro = {};

  async function enviarNotificacoes() {
    setCarregando(true);
    try {
      const resultado = await api.post('/notificacao/estabelecimento', {
        titulo: camposInput.titulo,
        dataAtividade,
        mensagem: camposInput.mensagem,
        enviadoPor: loggeduser.nome,
        profissionais: filtroProfissionais.map((p) => p.id),
        estabelecimentos: filtroEstabelecimentos.map((e) => e.id),
        cidades: filtroCidades.map((c) => c.id),
        bairros: filtroBairros.map((e) => e.id),
        bloqueados,
        inadimplentes,
        filtroPedido: pedidoFiltroSelecionado.value,
        statusSelecionado,
      });
      toast.success(resultado.data.message);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  function exibirMensagemErro() {
    Object.values(erro).forEach((e) => {
      toast.error(e);
    });
  }

  async function buscarEstabelecimentos() {
    setCarregando(true);
    setFirstLoad(false);
    try {
      const datas = [];
      if (dataAtividade && dataAtividade.length >= 2) {
        datas.push(
          ...eachDayOfInterval({
            start: dataAtividade[0],
            end: dataAtividade[1],
          })
        );
      }
      const resultado = await api.get('/notificacao/estabelecimento', {
        params: {
          pagina,
          datasAtividade: datas,
          profissionais: filtroProfissionais.map((p) => p.id),
          estabelecimentos: filtroEstabelecimentos.map((e) => e.id),
          cidades: filtroCidades.map((c) => c.id),
          bairros: filtroBairros.map((e) => e.id),
          bloqueados,
          inadimplentes,
          orderBy,
          filtroPedido: pedidoFiltroSelecionado.value,
          statusSelecionado
        },
      });
      setEstabelecimentos(resultado.data.lista);
      setQuantidade(resultado.data.quantidade);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  useEffect(() => {
    return !firstLoad && buscarEstabelecimentos();
  }, [pagina, orderBy]);

  function buscarQuantidade() {
    setExibirDialog(true);
    buscarEstabelecimentos();
  }

  return (
    <Styled.Container>
      <Styled.Content>
        <Panel
          header={<h3>Enviar Notificações para os Estabelecimentos</h3>}
          bordered
          shaded
        >
          <TagGroup style={{ marginBottom: '15px' }}>
            {filtroProfissionais.map((profissional) => (
              <Tag
                closable
                onClose={() => {
                  const novaLista = filtroProfissionais.filter(
                    (p) => p.id !== profissional.id
                  );
                  setFiltroProfissionais(novaLista);
                }}
                key={profissional.id}
              >
                Profissional: {profissional.label}
              </Tag>
            ))}
            {filtroEstabelecimentos.map((estabelecimento) => (
              <Tag
                closable
                onClose={() => {
                  const novaLista = filtroEstabelecimentos.filter(
                    (e) => e.id !== estabelecimento.id
                  );
                  setFiltroEstabelecimentos(novaLista);
                }}
                key={estabelecimento.id}
              >
                Estabelecimento: {estabelecimento.label}
              </Tag>
            ))}
            {filtroCidades.map((cidade) => (
              <Tag
                closable
                onClose={() => {
                  const novaLista = filtroCidades.filter(
                    (c) => c.id !== cidade.id
                  );
                  setFiltroCidades(novaLista);
                }}
                key={cidade.id}
              >
                Cidade: {cidade.label}
              </Tag>
            ))}
            {filtroBairros.map((estado) => (
              <Tag
                closable
                onClose={() => {
                  const novaLista = filtroBairros.filter(
                    (e) => e.id !== estado.id
                  );
                  setFiltroEstados(novaLista);
                }}
                key={estado.id}
              >
                Bairro: {estado.label}
              </Tag>
            ))}
          </TagGroup>
          <NotificationToggle darkMode={toggleMode}>
            <Toggle
              checkedChildren="Comunicado"
              unCheckedChildren="Comunicado"
              onChange={() => setDisableCommunication(!disableCommunication)}
              style={{ marginBottom: '10px' }}
            />
          </NotificationToggle>
          <h5 style={{ marginBottom: '10px' }}>Filtro:</h5>
          <FlexboxGrid justify="start" style={{ marginBottom: 30, gap: '10px' }}>
            <SelectPicker
              appearance="default"
              style={{ width: '150px' }}
              searchable={false}
              data={atributosFiltro}
              value={atributoSelecionado.value}
              cleanable={false}
              onSelect={(value, item) => setAtributoSelecioando(item)}
              placeholder="Filtro"
            />
            {
              {
                profissional: (
                  <FiltroProfissionais
                    onItemAdicionado={(value, item) => {
                      const itemExiste = filtroProfissionais.find(
                        (filtro) => filtro.id === item.id
                      );
                      if (itemExiste) return;
                      const lista = [...filtroProfissionais, item];
                      setFiltroProfissionais(lista);
                    }}
                  />
                ),
                estabelecimento: (
                  <FiltroEstabelecimentos
                    onItemAdicionado={(value, item) => {
                      const itemExiste = filtroEstabelecimentos.find(
                        (filtro) => filtro.id === item.id
                      );
                      if (itemExiste) return;
                      const lista = [...filtroEstabelecimentos, item];
                      setFiltroEstabelecimentos(lista);
                    }}
                  />
                ),
                cidade: (
                  <FiltroCidades
                    onItemAdicionado={(value, item) => {
                      const itemExiste = filtroCidades.find(
                        (filtro) => filtro.id === item.id
                      );
                      if (itemExiste) return;
                      const lista = [...filtroCidades, item];
                      setFiltroCidades(lista);
                    }}
                  />
                ),
                bairro: (
                  <FiltroBairros
                    onItemAdicionado={(value, item) => {
                      const itemExiste = filtroBairros.find(
                        (filtro) => filtro.id === item.id
                      );
                      if (itemExiste) return;
                      const lista = [...filtroBairros, item];
                      setFiltroEstados(lista);
                    }}
                  />
                ),
              }[atributoSelecionado.value]
            }
            <Checkbox checked={inadimplentes}
            onChange={(bool, value)=> {
               setInadimplentes(value)
            }} >
          Inadimplentes
        </Checkbox>
          </FlexboxGrid>
          <FilterUsersNotification
            setBloqueados={setBloqueados}
            bloqueados={bloqueados}
          />
          <h5 style={{ marginBottom: '10px' }}>
            Data de atividade (opcional):
          </h5>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 60,
            gap: 10,
          }}>
            <DateRangePicker
              format="dd/MM/yyyy"
              placeholder="Data"
              value={dataAtividade}
              ranges={[]}
              isoWeek
              locale={locale}
              onClean={() => setDataAtividade([])}
              onChange={(datasSelecionadas) => {
                const datas = [
                  datasSelecionadas && startOfDay(datasSelecionadas[0]),
                  datasSelecionadas && endOfDay(datasSelecionadas[1]),
                ];
                setDataAtividade(datas);
                if (pedidoFiltroSelecionado.value === 'todos') {
                  setPedidoFiltroSelecionado({
                    label: 'Realizou Pedidos',
                    value: 'realizou pedidos'
                  })
                }
              }}
            />

            {dataAtividade && dataAtividade.length > 0 &&
              <SelectPicker
                value={statusSelecionado}
                placeholder="Status"
                searchable={false}
                data={statusPeriodo}
                onChange={setStatusSelecionado}
              />}

            <SelectPicker
              appearance="default"
              style={{ marginRight: '15px', width: '200px' }}
              searchable={false}
              data={pedidosFiltro}
              value={pedidoFiltroSelecionado.value}
              cleanable={false}
              onSelect={(value, item) => setPedidoFiltroSelecionado(item)}
              placeholder="Filtro de Pedidos"
            />
          </div>
          <Divider>
            <ButtonBorderBlue
              appearance="ghost"
              loading={carregando}
              onClick={() => buscarEstabelecimentos()}
            >
              Atualizar Tabela
            </ButtonBorderBlue>
          </Divider>
          <h5 style={{ marginBottom: '10px' }}>Notificação</h5>
          <Form
            model={modelo}
            onChange={setCamposInput}
            formValue={camposInput}
            onSubmit={(semErro) =>
              semErro ? buscarQuantidade() : exibirMensagemErro()
            }
            onError={(err) => {
              erro = err;
            }}
          >
            <Form.Group>
              <Form.ControlLabel>Título</Form.ControlLabel>
              <Form.Control name="titulo" value={camposInput.titulo} />
            </Form.Group>
            <Form.Group>
              {disableCommunication ? (
                <Form.ControlLabel>Mensagem</Form.ControlLabel>
              ) : (
                <Form.ControlLabel>Resumo Comunicado</Form.ControlLabel>
              )}
              <Form.Control name="mensagem" value={camposInput.mensagem} />
            </Form.Group>
            {disableCommunication ? (
              <ButtonFullBlue
                block
                appearance="primary"
                type="submit"
                loading={carregando}
              >
                Enviar
              </ButtonFullBlue>
            ) : (
              ''
            )}
          </Form>
          {carregando && <Loader center backdrop />}
          <Modal
            backdrop="static"
            open={exibirDialog}
            onClose={() => setExibirDialog(false)}
            size="xs"
          >
            <Modal.Header>
              <h3>Enviar Notificação</h3>
            </Modal.Header>
            <Modal.Body>
              <p>Notificação será enviada para {quantidade} usuários</p>
            </Modal.Body>

            <Modal.Footer>
              <Button
                onClick={() => setExibirDialog(false)}
                appearance="subtle"
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                appearance="primary"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={enviarNotificacoes}
              >
                Enviar
              </Button>
            </Modal.Footer>
          </Modal>
        </Panel>
        <div
          style={{ display: 'flex', flexDirection: 'column', width: `100%` }}
        >
          <Announcement
            disabled={disableCommunication}
            target="estabelecimento"
            // eslint-disable-next-line react/jsx-no-bind
            notificationCallback={enviarNotificacoes}
            quantidade={quantidade}
            filtros={{
              cidadesFiltradas: filtroCidades,
              bairrosFiltrados: filtroBairros,
              dataFiltrada: dataAtividade,
              estabelecimentosFiltrados: filtroEstabelecimentos,
              profissionaisFiltrados: filtroProfissionais,
              filtroPedido: pedidoFiltroSelecionado
            }}
            resumo={camposInput.mensagem}
            bloqueados={bloqueados}
          />
        </div>
      </Styled.Content>

      <Divider vertical style={{ height: '100%' }} />

      <Styled.Content>
        <Panel
          style={{ width: '100%' }}
          header={<h3>Clientes a serem notificados</h3>}
          bordered
          shaded
        >
          <TablePagination
            carregando={carregando}
            dados={estabelecimentos}
            quantidade={quantidade}
            onChangePage={setPagina}
            setOrderBy={setOrderBy}
          >
            <Column resizable width={180} sortable>
              <HeaderCell>Nome Cliente</HeaderCell>
              <Cell dataKey="nome_fantasia" />
            </Column>

            <Column resizable width={140} sortable>
              <HeaderCell>Email</HeaderCell>
              <Cell dataKey="email" />
            </Column>
            <Column resizable width={140} sortable>
              <HeaderCell>CNPJ</HeaderCell>
              <Cell dataKey="cpf_cnpj" />
            </Column>
            <Column resizable width={150} sortable>
              <HeaderCell>Telefone</HeaderCell>
              <Cell dataKey="telefone" />
            </Column>
          </TablePagination>
        </Panel>
      </Styled.Content>
    </Styled.Container>
  );
}

export default Notificacoes;
