/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Table, FlexboxGrid, Button, SelectPicker, IconButton } from 'rsuite';
import { format, eachDayOfInterval } from 'date-fns';
import { Attachment, Search } from '@rsuite/icons';
import { toast } from 'react-toastify';
import { TablePagination } from '~/components/TablePagination';
import { Container } from '~/styles/tabela';
import api from '~/services/api';
import { GerarCSV as CSV } from '~/utils/gerarCSV'
import { ModalPadrao } from '~/components/Modal';
import formatCurrency from '~/utils/formatCurrency';
import SearchInputTable from '~/components/SearchInputTable';
import { InputDataRange } from '~/components/InputDataRange';
import DetalhesCarteiraDialog from '../CarteiraProfissionais/components/DetalhesCarteiraDialog';
import { CelulaRelatorio, CelulaRelatorioOpcoes } from './components/CelulaRelatorio';
import PagamentoDialog from './components/PagamentoDialog';
import {
  CelulaAcaoRelatorio,
  CelulaAcaoPagamentoRelatorio,
} from './components/CelulaAcaoRelatorio';
import EditarProfissional from '../../Profissionais/EditarProfissional';
import CardTotais from './components/CardTotais';
import { DarkModeContext } from '../../../common/contexts/darkMode';


const { HeaderCell, Column, Cell } = Table;

function RelatoriosProfissional() {
  const { toggleMode } = useContext(DarkModeContext)
  const [filtroData, setFiltroData] = useState();
  const [datas, setDatas] = useState();
  const [relatorios, setRelatorios] = useState({
    lista: [],
    quantidade: 0,
    valorTotal: 0,
  });
  const modalTitulo = 'Gerar CSV'
  const modalTexto = 'Tem certeza que deseja gerar este CSV com os filtros atuais?'
  const [atributoSelecionado, setAtributoSelecionado] = useState('nome');
  const [statusFiltro, setStatusFiltro] = useState();
  const [textoDigitado, setTextoDigitado] = useState();
  const [showInfo, setShowInfo] = useState(false);
  const [profInfo, setProfInfo] = useState({ profissional: { id: 0 } });
  const [pagina, setPagina] = useState(1);
  const [modalCSV, setModalCSV] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [carregando2, setCarregando2] = useState(false);
  const [exibirDetalhesDialog, setExibirDetalhesDialog] = useState({
    exibir: false,
  });
  const [pagamentoDialog, setPagamentoDialog] = useState({
    exibir: false,
    relatorio: {},
  });
  const [profissionalEditando, setProfissionalEditando] = useState();
  const [showEditarProfissional, setShowEditarProfissional] = useState(false);
  const [buscarCSV, setBuscarCSV] = useState(false);
  const atributosFiltro = [
    { value: 'nome', label: 'Nome' },
    { value: 'cpf_cnpj', label: 'CPF/CNPJ' },
  ];
  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };
  const [orderBy, setOrderBy] = useState('');

  const buscarRelatorios = useCallback(async (csv = false) => {
    setCarregando(true);
    setModalCSV(false)
    try {
      const resposta = await api.get('relatorios/profissional-adm', {
        params: {
          datas: filtroData,
          [atributoSelecionado]: textoDigitado,
          status: statusFiltro,
          pagina,
          orderBy,
          csv: buscarCSV
        },
      });

      const lista = resposta.data.lista.map((relatorio) => ({
        // ...relatorio,
        ...(buscarCSV === false && relatorio),
        "Nome": relatorio.profissional.nome,
        "Cpf/Cnpj": relatorio.profissional.cpf_cnpj,
        "Semana": format(
          new Date(relatorio.semana.replace(/-/g, '/').replace(/T.+/, '')),
          'dd/LL/yyyy'
        ),
        "Pagamento": format(
          new Date(relatorio.pagamento),
          'dd/LL/yyyy'
        ),
        "Qtd": relatorio.quantidade,
        "Valor": relatorio.valor ? formatCurrency(relatorio.valor) : 0,
        "Avaliacao": relatorio && relatorio.avaliacao && relatorio.avaliacao.toFixed(2),
        "Banco":
        relatorio.profissional.conta && relatorio.profissional.conta.banco,
        "Agência":
          relatorio.profissional.conta && relatorio.profissional.conta.agencia,
        "Conta":
          relatorio.profissional.conta &&
          `${relatorio.profissional.conta.conta} - ${relatorio.profissional.conta.digito}`,
        "Pix": relatorio.profissional.pix,
        "Tipo Conta":
          relatorio.profissional.conta &&
          relatorio.profissional.conta.tipo_conta,
        "Cpf Titular":
        relatorio.profissional.conta && relatorio.profissional.conta.cpf,
        "Status": relatorio.status,
        "Método": relatorio.metodo_pagamento,
      }));


      if(buscarCSV){
        CSV({
          data: lista,
          download: true,
        });

        setCarregando2(false);
        toast.success(`Foram exportados ${lista.length} registros.`);
        setBuscarCSV(false);
        return;
      }

      setRelatorios({
        lista,
        quantidade: resposta.data.quantidade,
        valorTotal: resposta.data.valorTotal
          ? resposta.data.valorTotal.toFixed(2)
          : 0,
      });
      if(relatorios.quantidade > 0 && relatorios.lista.length === 0) setPagina(1)
    } catch (e) {
      if(buscarCSV){
        setCarregando2(false);
        toast.error('Erro ao Exportar os dados');
        setBuscarCSV(false);
        return;
      }
      toast.error(e.message);
    }

      setCarregando(false);
  }, [
    pagina,
    filtroData,
    atributoSelecionado,
    textoDigitado,
    statusFiltro,
    orderBy,
    buscarCSV,
    relatorios.quantidade,
    relatorios.lista.length,
    setPagina
  ]);

  useEffect(() => {
    buscarRelatorios();
  }, [buscarRelatorios]);


  function abrirEditarProfissional(profissional) {
    setProfissionalEditando(profissional);
    setShowEditarProfissional(true);
  }

  function fecharEditarProfissional() {
    buscarRelatorios();
    setShowEditarProfissional(false);
  }

  async function atualizarStatus(relatorio) {
    setCarregando(true);
    try {
      if (relatorio.status === 'A Pagar')
        await api.put(
          `/relatorios/profissional/${relatorio.profissional.id}`,
          {},
          {
            params: {
              semana: relatorio.semana,
            },
          }
        );
      else await api.put(`/relatorios/profissional/cancelar/${relatorio.id}`);
      toast.success('Relatório atualizado com sucesso');
      buscarRelatorios();
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  function abreServicos(profissional) {
    setProfInfo(profissional);
    setShowInfo(true);
  }
  function fechaServicos() {
    setShowInfo(false);
  }
  return (
    <Container>
      <h2>Relatórios Profissional

      <IconButton
          title="Exportar CSV"
          style={{ marginLeft: '20px' }}
          size="4em"
          icon={<Attachment />}
          onClick={() => {
            setModalCSV(true)
          }}
        />

      </h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchInputTable estilo={{ width: '300px' }} setTextoDigitado={setTextoDigitado} />

        <SelectPicker
          appearance="default"
          searchable={false}
          data={atributosFiltro}
          value={atributoSelecionado}
          cleanable={false}
          onChange={(value) => setAtributoSelecionado(value)}
        />

        <InputDataRange
          ranges={[]}
          isoWeek
          oneTap
          locale={locale}
          onClean={() => {
            setFiltroData(null);
            setDatas([]);
          }}
          onChange={(datasSelecionadas) => {
            setDatas(datasSelecionadas);
            if (datasSelecionadas && datasSelecionadas.length !== 0) {
              const datasIntervalo = eachDayOfInterval({
                start: datasSelecionadas[0],
                end: datasSelecionadas[1],
              });
              setFiltroData(datasIntervalo);
            } else {
              setFiltroData(null);
            }
          }}
          value={datas}
          hoverRange="week"
        />

        <SelectPicker
          title={statusFiltro || 'Filtro Status'}
          toggleComponentClass={Button}
          appearance="default"
          searchable={false}
          placeholder="Selecione Status"
          data={[
            { label: 'A Pagar', value: 'A Pagar' },
            { label: 'Pago', value: 'Pago' },
          ]}
          onChange={setStatusFiltro}
        />
      </FlexboxGrid>
      <CardTotais
        numeroProfissionais={relatorios.quantidade}
        totalContratado={relatorios.valorTotal}
      />

<FlexboxGrid style={{ width: '1970px' }}>
      <TablePagination
        carregando={carregando}
        dados={relatorios.lista}
        quantidade={relatorios.quantidade}
        onChangePage={setPagina}
        setOrderBy={setOrderBy}
      >
        <Column resizable width={100}>
          <HeaderCell>Opções</HeaderCell>
          <CelulaRelatorioOpcoes
            setExibirDetalhesDialog={setExibirDetalhesDialog}
            abrirEditarProfissional={abrirEditarProfissional}
            dataKey="id"
            toggleMode={toggleMode}
          />
        </Column>
        <Column resizable width={160} sortable>
          <HeaderCell>Nome</HeaderCell>
          <CelulaRelatorio dataKey="Nome" />
        </Column>

        <Column resizable width={140} sortable>
          <HeaderCell>Cpf/Cnpj</HeaderCell>
          <CelulaRelatorio dataKey="Cpf/Cnpj" />
        </Column>

        <Column resizable width={120} sortable>
          <HeaderCell>Semana</HeaderCell>
          <CelulaRelatorio dataKey="Semana" />
        </Column>

        <Column resizable width={120} sortable >
          <HeaderCell>Pagamento</HeaderCell >
          <CelulaRelatorio dataKey="Pagamento" />
        </Column>

        <Column resizable width={60} sortable>
          <HeaderCell>Qtd</HeaderCell>
          <CelulaRelatorio dataKey="quantidade" />
        </Column>

        <Column resizable width={120} sortable>
          <HeaderCell>Valor</HeaderCell>
          <CelulaRelatorio dataKey="Valor" />
        </Column>
        <Column resizable width={80} sortable>
          <HeaderCell>Avaliação</HeaderCell>
          <CelulaRelatorio dataKey="Avaliacao" />
        </Column>
        <Column resizable width={80}>
          <HeaderCell>Banco</HeaderCell>
          <CelulaRelatorio dataKey="Banco" />
        </Column>
        <Column resizable width={80}>
          <HeaderCell>Agência</HeaderCell>
          <CelulaRelatorio dataKey="Agência" />
        </Column>
        <Column resizable width={100}>
          <HeaderCell>Conta</HeaderCell>
          <CelulaRelatorio dataKey="Conta" />
        </Column>
        <Column resizable width={130}>
          <HeaderCell>Pix</HeaderCell>
          <CelulaRelatorio dataKey="Pix" />
        </Column>
        <Column resizable width={140}>
          <HeaderCell>Tipo Conta</HeaderCell>
          <CelulaRelatorio dataKey="Tipo Conta" />
        </Column>
        <Column resizable width={140}>
          <HeaderCell>Cpf Titular</HeaderCell>
          <CelulaRelatorio dataKey="Cpf Titular" />
        </Column>

        <Column resizable width={100} sortable>
          <HeaderCell>Status</HeaderCell>
          <CelulaRelatorio dataKey="Status" />
        </Column>

        <Column resizable width={100}>
          <HeaderCell>Método</HeaderCell>
          <CelulaRelatorio dataKey="Método" />
        </Column>

        <Column resizable width={100}>
          <HeaderCell>Status</HeaderCell>
          <CelulaAcaoRelatorio onClick={atualizarStatus} />
        </Column>
        <Column resizable width={100}>
          <HeaderCell>Pagamento</HeaderCell>
          <CelulaAcaoPagamentoRelatorio
          toggleMode={toggleMode}
            onClick={(relatorio) => {
              setPagamentoDialog({
                exibir: true,
                relatorio,
              });
            }}
          />
        </Column>
      </TablePagination>
      </FlexboxGrid>
      <DetalhesCarteiraDialog
        exibir={exibirDetalhesDialog.exibir}
        id_profissional={exibirDetalhesDialog.id_profissional}
        datas={datas}
        onFechar={() => setExibirDetalhesDialog({ exibir: false })}
      />
      <EditarProfissional
        idProfissional={profissionalEditando && profissionalEditando.id}
        showEditarProfissional={showEditarProfissional}
        setShowEditarProfissional={setShowEditarProfissional}
        fecharEditarProfissional={fecharEditarProfissional}
        atualizarLista={buscarRelatorios}
      />

      <ModalPadrao
      openModal={modalCSV}
      carregando={carregando2}
      // csv
      setOpenModal={setModalCSV}
      title={modalTitulo}
      text={modalTexto}
      funcExec={() => {
        setBuscarCSV(true);
        buscarRelatorios()
      }}
      />
      <PagamentoDialog
        relatorio={pagamentoDialog.relatorio}
        exibir={pagamentoDialog.exibir}
        recarregar={buscarRelatorios}
        fechar={() => {
          setPagamentoDialog({
            exibir: false,
            relatorio: {},
          });
        }}
      />
    </Container>
  );
}

export default RelatoriosProfissional;
