import React, { useState, useCallback, useEffect } from 'react'
import { Container } from '~/styles/tabela';
import { Loader, Table, FlexboxGrid, Input, InputGroup, IconButton, Button } from 'rsuite';
import { Attachment, Search } from '@rsuite/icons';
import { startOfMonth, format, parseISO, lastDayOfMonth } from 'date-fns';
import { InputDataRange } from '~/components/InputDataRange';
import { TablePagination } from '~/components/TablePagination';
import api from '~/services/api';
import { toast } from 'react-toastify';
import { ColContainer, RowContainer } from './styles'
import fortCurrency from '../../../utils/formatCurrency'




const AnaliseRecorrencia = () => {
  const { HeaderCell, Column, Cell } = Table;
  const [filtroNome, setFiltroNome] = useState('');

  const [pagina, setPagina] = useState(1);
  const [semPedidoFiltro, setSemPedidoFiltro] = useState([]);
  const [comPedidoFiltro, setComPedidoFiltro] = useState([]);
  const [clientes, setClientes] = useState({
    estabelecimentos: [],
  });
  const [carregando, setCarregando] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const buscarClientes = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.get(
        `relatorios/estabelecimento/analise-recorrencia`,
        {
          params: {
            pagina,
            nome: filtroNome,
            orderBy,
            periodoComPedido: comPedidoFiltro,
            periodoSemPedido: semPedidoFiltro
          },
        }
      );
      const lista = resposta.data.lista.map((map) => ({
        ...map,
        data_ativacao: format(parseISO(map.data_ativacao), 'dd/LL/yyyy'),
        valor_bruto: fortCurrency(map.valor_bruto),
        ultimo_pedido: format(parseISO(map.ultimo_pedido), 'dd/LL/yyyy')
      }));

      setClientes({
        quantidade: lista.length > 0 ? lista[0].full_count : 0,
        lista
      });
      if(clientes.quantidade === 0) setPagina(1)
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, [pagina, filtroNome, comPedidoFiltro, semPedidoFiltro, orderBy, clientes.quantidade])

  useEffect(() => {
    buscarClientes();
  }, [buscarClientes]);





  return (



    <Container>
      <h2>Análise de Recorrência</h2>
      <RowContainer>
        <ColContainer>
          <p>Pesquisar</p>
          <InputGroup style={{ width: '300px', marginRight: '15px' }}>
            <Input
              name="nome_estabelecimento"
              value={filtroNome}
              style={{ marginRight: '10px', width: '300px' }}
              placeholder="Nome do Cliente"
              onChange={setFiltroNome}
            />
            <InputGroup.Button>
              <Search />
            </InputGroup.Button>
          </InputGroup>
        </ColContainer>
        <ColContainer>
          <p>Fez pedido no período</p>
          <InputDataRange
            name="data"
            style={{ marginRight: '15px', width: '230px' }}
            ranges={[]}
            isoWeek
            locale={locale}
            onChange={setComPedidoFiltro}
            value={comPedidoFiltro}
            placeholder='Insira uma Data'
          />
        </ColContainer>
        <ColContainer>
          <p><b>Não</b> fez pedido no período</p>
          <InputDataRange
            name="data"
            style={{ marginRight: '15px', width: '230px' }}
            ranges={[]}
            isoWeek
            locale={locale}
            onChange={setSemPedidoFiltro}
            value={semPedidoFiltro}
            placeholder='Insira uma Data'
          />

        </ColContainer>
      </RowContainer>
      <FlexboxGrid style={{ width: '1080px' }}>
      <TablePagination
        carregando={carregando}
        dados={clientes.lista}
        quantidade={clientes.quantidade || 0}
        onChangePage={setPagina}
        setOrderBy={setOrderBy}
      >
        <Column resizable width={240} sortable>
          <HeaderCell>Nome Estabelecimento</HeaderCell>
          <Cell dataKey="nome_fantasia" />
        </Column>

        <Column resizable width={150}>
          <HeaderCell>Cnpj</HeaderCell>
          <Cell dataKey="cpf_cnpj" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Cidade</HeaderCell>
          <Cell dataKey="cidade" />
        </Column>
        <Column resizable width={70} sortable>
          <HeaderCell>Estado</HeaderCell>
          <Cell dataKey="uf" />
        </Column>
        <Column resizable width={130} sortable>
          <HeaderCell>Data Ativação</HeaderCell>
          <Cell dataKey="data_ativacao" />
        </Column>
        <Column resizable width={120} sortable>
          <HeaderCell>Quantidade</HeaderCell>
          <Cell dataKey="count" />
        </Column>
        <Column resizable width={110} sortable>
          <HeaderCell>Total</HeaderCell>
          <Cell dataKey="valor_bruto" />
        </Column>
        <Column resizable width={110} sortable>
          <HeaderCell>Ultimo Pedido</HeaderCell>
          <Cell dataKey="ultimo_pedido" />
        </Column>
      </TablePagination>
      </FlexboxGrid>


      {carregando && <Loader center size="lg" vertical />}
    </Container>
  )
}

export default AnaliseRecorrencia
