/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import InfoRoundIcon from '@rsuite/icons/InfoRound';
import EditIcon from '@rsuite/icons/Edit';
import ReloadIcon from '@rsuite/icons/Reload';
import { Trash } from '@rsuite/icons';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { Modal, Form, Button, Schema, InputPicker, IconButton, Checkbox, Avatar, AutoComplete, Table, FlexboxGrid, CheckPicker, Input } from 'rsuite';
import useSearchNames from '~/services/useSearchNames';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce/lib';
import { confirmarProfissional } from '~/store/modulos/pedido/actions';
import EditarProfissional from '~/pages/Profissionais/EditarProfissional';
import api from '~/services/api';
import { toast } from 'react-toastify';
import { eachDayOfInterval, format, parseISO } from 'date-fns';
import { InputDataRange } from '~/components/InputDataRange';
import { FiltroExperiencias } from '~/components/FiltroExperiencias';
import DetalhesCarteiraDialog from '../../../relatorios/CarteiraProfissionais/components/DetalhesCarteiraDialog';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';
import ModalDeleteProf from '../ModalDeleteProf';
import CardModalConfirm from '../CardModalConfirmarProfissional';
import  {LocalizacaoModal}  from '../MapModal/index';
import { DarkModeContext } from '../../../../common/contexts/darkMode';
import theme from '../../../../styles/theme';

const { NumberType } = Schema.Types;

const modelo = Schema.Model({
  id_profissional: NumberType().isRequired('Profissional Obrigatório'),
});

function ConfirmarProfissionalDialog({ exibir, registro, onFechar, idPedido }) {
  const { toggleMode } = useContext(DarkModeContext);
  const dispatch = useDispatch();
  const [camposInput, setCamposInput] = useState({});
  const [profissionalEditando, setProfissionalEditando] = useState();
  const [showEditarProfissional, setShowEditarProfissional] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [proLista, setProLista] = useState();
  const [listaDeProfissionais, setListaDeProfissionais] = useState();
  const [data, setData] = useState();
  const { Column, HeaderCell, Cell } = Table;
  const [experienciaSelecionada, setExperienciaSelecionada] = useState();
  const [nome, setNome] = useState('');
  const [bairro, setBairro] = useState('');
  const [experiencias, setExperiencias] = useState([]);
  const [dataFiltro, setDataFiltro] = useState();
  const [filtroTrabalhou, setFiltroTrabalhou] = useState(false);
  const [filtroFavoritos, setFiltroFavoritos] = useState(false);
  const [LocalizaRecrutamento, setLocalizaRecrutamento] = useState(false);
  const [modalLocaliza, setModalLocaliza] = useState(false);
  const [color, setColor] = useState(0);
  const [exibirDetalhesDialog, setExibirDetalhesDialog] = useState({
    exibir: false,
  });
  const [exibirDeleteDialog, setExibirDeleteDialog] = useState({
    exibir: false,
  });

  const [selecionado, setSelecionado] = useState();

  function handleClick(item) {
    setSelecionado(prevSelecionado => !prevSelecionado);

    if (item.value !== camposInput.value) {
      setCamposInput(item);
    } else {
      setSelecionado(prevSelecionado => {
        if (prevSelecionado) {
          setSelecionado(prevSelecionado)
          setCamposInput(item);
        } else {
          setSelecionado(prevSelecionado)
          setCamposInput({});
        }
      });
    }
  }

  function editarRegistro() {
    const registroEditado = { profissional: {} };
    registroEditado.id = registro.id;
    registroEditado.profissional.id = camposInput.value;
    registroEditado.profissional.nome = camposInput.nome;
    dispatch(confirmarProfissional(registroEditado));
    onFechar();
  }

  function abrirEditarProfissional(id) {
    setProfissionalEditando(id);
    setShowEditarProfissional(true);
  }

  function fecharEditarProfissional() {
    setShowEditarProfissional(false);
  }


  const listaDados = useCallback(() => {
    const idRegistro = registro.id;

    if (idRegistro) {

      const registroProf = async () => {
        let datasIntervalo = []

        if (dataFiltro && dataFiltro.length !== 0){

          const dataInicio = dataFiltro[0].toDateString()
          const dataFim = dataFiltro[1]
          datasIntervalo = [
            dataInicio,
            dataFim
          ]
        }
        const resposta = await api.get(`registro/lista-candidaturas/${idRegistro}`, {
          params: {
            data: datasIntervalo,
            bairro,
            nome,
            experiencias: experienciaSelecionada,
            trabalhou: filtroTrabalhou,
            favoritos: filtroFavoritos,
            id_estab: registro.id_estab
          },
        });
        const listaProfissionais = resposta.data;
        setListaDeProfissionais(listaProfissionais);

        const experienci = await api.get('profissao');
        setExperiencias(experienci.data);

        const lista = proLista ? [...proLista] : [...listaProfissionais];
        lista.sort((a, b) => b.pontuacao - a.pontuacao);
        setData(
          lista.map((p, index) => {

            let bloqueado = '';
            if (p.desc_pontuacao === 'Profissional bloqueado!') {
              bloqueado = `- ${p.desc_pontuacao}❌❌❌`;
              return {
                id_estabelecimento: p.id_estabelecimento,
                id_profissional: p.id_profissional,
                bairro: p.bairro,
                nome: p.nome,
                data: format(parseISO(p.created_at), 'dd/MM/yyyy'),
                hora: format(new Date(p.created_at), 'HH:mm'),
                perfil_foto: p.caminho,
                trabalhos: p.shifts,
                cancelamentos: p.cancelamentos,
                tem_experiencia: p.tem_experiencia,
                ja_trabalhou: p.ja_trabalhou,
                avaliacao: p.avaliacao !== null ? p.avaliacao.toFixed(1) : '',
                faltas: p.faltas || "",
                label: `${p.nome}: ${p.pontuacao.toFixed(2)} ${bloqueado}`,
                value: p.id_profissional,
                desc_pontuacao: p.desc_pontuacao,
                trab: p.trab,
                fav: p.fav
              };
            }
            return {
              id_estabelecimento: p.id_estabelecimento,
              id_profissional: p.id_profissional,
              bairro: p.bairro,
              nome: p.nome,
              data: format(parseISO(p.created_at), 'dd/MM/yyyy'),
              hora: format(new Date(p.created_at), 'HH:mm'),
              perfil_foto: p.caminho,
              trabalhos: p.shifts,
              cancelamentos: p.cancelamentos,
              tem_experiencia: p.tem_experiencia,
              ja_trabalhou: p.ja_trabalhou,
              avaliacao: p.avaliacao !== null ? p.avaliacao.toFixed(1) : '',
              faltas: p.faltas || "",
              label: `${p.nome}: ${p.pontuacao.toFixed(2)}`,
              value: p.id_profissional,
              desc_pontuacao: p.desc_pontuacao,
              trab: p.trab,
                fav: p.fav
            };
          })
        );
      };

      registroProf();
    }
  }, [registro, proLista, bairro, nome, experienciaSelecionada, dataFiltro, filtroTrabalhou, filtroFavoritos]);

  async function fechar(){
    setFiltroTrabalhou(false)
    setFiltroFavoritos(false)
    setNome('')
    setBairro('')
    setExperienciaSelecionada([])
    setDataFiltro([])
    setCamposInput({})
    onFechar()
  }

  async function atualizarNotas() {
    setCarregando(true);
    try {
      const resposta = await api.put(
        '/pedidos/atualizar-notas/',
        listaDeProfissionais
      );
      setCarregando(false);
      setProLista(resposta.data);

      setData(
        resposta.data && resposta.data.map((p) => {
          let bloqueado = '';
          if (p.desc_pontuacao === 'Profissional bloqueado!') {
            bloqueado = `- ${p.desc_pontuacao}❌❌❌`;
            return {
              id_estabelecimento: p.id_estabelecimento,
              id_profissional: p.id_profissional,
              bairro: p.bairro,
              nome: p.nome,
              data: format(parseISO(p.created_at), 'dd/MM/yyyy'),
              hora: format(new Date(p.created_at), 'HH:mm'),
              perfil_foto: p.caminho,
              trabalhos: p.shifts,
              cancelamentos: p.cancelamentos,
              avaliacao: p.avaliacao !== null ? p.avaliacao.toFixed() : '',
              faltas: p.faltas || "",
              label: `${p.nome}: ${p.pontuacao.toFixed(2)} ${bloqueado}`,
              value: p.id,
              desc_pontuacao: p.desc_pontuacao,
            };
          }

          return {
            id_estabelecimento: p.id_estabelecimento,
            id_profissional: p.id_profissional,
            bairro: p.bairro,
            nome: p.nome,
            data: format(parseISO(p.created_at), 'dd/MM/yyyy'),
            hora: format(new Date(p.created_at), 'HH:mm'),
            perfil_foto: p.caminho,
            trabalhos: p.shifts,
            cancelamentos: p.cancelamentos,
            avaliacao: p.avaliacao !== null ? p.avaliacao.toFixed() : '',
            faltas: p.faltas || "",
            label: `${p.nome}: ${p.pontuacao.toFixed(2)}`,
            value: p.id,
            desc_pontuacao: p.desc_pontuacao,
          };
        })
      );
      toast.success('Registros atualizados com sucesso!');
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function removerProfissional(prof) {
    const dataReg = {
      id_prof: prof,
      id_registro: registro.id,
      id_pedido: idPedido,
    }
    await api.put('/registros/remover-candidatura-profissional', dataReg)
    const registrosMantidos = data.filter((r) => {
      return r.value !== prof;
    });
    setData(registrosMantidos);
  }

  useEffect(() => {
    setCamposInput({
      ...registro,
    });


    if (registro && registro.profissionais_lista) {
      const a = [...registro.profissionais_lista];

      a.sort((a1, b) => a1 - b);
    }

    listaDados();
  }, [registro]);

  useEffect(() => {
    listaDados();
  }, [listaDados]);

  // useEffect(() => {
  //   console.log("Registro", registro)
  //   console.log("CamposInput", camposInput)
  // }, [registro]);

  // useEffect(() => {
  //   setDataFiltro(datas);
  // }, [datas]);

  return (
    <div style={{ boxSizing: 'border-box', width: '1500px', margin: 'auto'}}>
      <Modal
      size="full"
      open={exibir}
      onClose={fechar}
      style={{ boxSizing: 'inherit', margin: 'auto'}}
    >
      <div style={{ display: 'flex', margin: 'auto',  flexDirection: 'column', overflowX: 'scroll', boxSizing: 'inherit'}}>
        <Form
          style={{  width: '1300px', margin: 'auto', boxSizing: 'inherit'}}
          fluid
          model={modelo}
          formValue={camposInput}
          onChange={(valor) => setCamposInput(valor)}
          onSubmit={(erro) => erro && editarRegistro()}
        >
          <Modal.Header style={{ marginLeft: '40px' }} >
            <Modal.Title style={{ marginLeft: '10px' }} >Confirmar Profissional%?&</Modal.Title>
            <FlexboxGrid justify="start" align="middle" style={{ gap: '10px', padding: '10px' }}>
              <InputDataRange
                ranges={[]}
                value={dataFiltro}
                onChange={setDataFiltro}
              />

              <FiltroExperiencias
                style={{ width: 224 }}
                appearance="default"
                data={experiencias.map((profissao) => ({
                  label: profissao.profissao,
                  value: profissao.id,
                }))}
                value={experienciaSelecionada}
                onChange={(value) => setExperienciaSelecionada(value)}

              />

              <Input
                style={{ width: '250px' }}
                placeholder="Nome"
                onChange={useDebouncedCallback(setNome, 500).callback}
              />

              <Input
                style={{ width: '250px' }}
                placeholder="Bairro"
                onChange={useDebouncedCallback(setBairro, 500).callback}
              />
              <Checkbox
            value={filtroTrabalhou}
            style={{ margin: 0, padding: 0, color: 'red' }}
            onChange={(a, valor) => {
              setFiltroTrabalhou(valor);
            }}
            title="Filtrar profissionais que já trabalharam"
          >
            Já trabalhou lá
          </Checkbox>
          <Checkbox
            value={filtroFavoritos}
            style={{ margin: 0, padding: 0, color: 'blue' }}
            onChange={(a, valor) => {
              setFiltroFavoritos(valor);
            }}
            title="Filtrar profissionais favoritos"
          >
            Favoritos
          </Checkbox>
            </FlexboxGrid>
          </Modal.Header>
          <Modal.Body style={{ backgroundColor: toggleMode && '#1a1d24', borderRadius:'10px' , marginBottom:'10px'}}>

            <div style={{overflowY: 'scroll'}}>
            {data && data.map((item, index) => {

              return (
                <div style={{
                  margin: '10px 0px 10px 0px',
                  width: '100%',
                  display: 'flex',
                  gap: '10px',
                  justifyContent: 'center',
                  flexDirection: "row",

                }}>
                  <div style={{
                    backgroundColor: toggleMode && '#1a1d24',
                    display: 'flex',
                    width: '1200px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: "row",
                  }}>
                    <button type='submit'
                      style={{
                        backgroundColor: 'Transparent',
                      }}
                      onClick={() => handleClick(item)

                      }
                    >
                      <CardModalConfirm item={item} index={index} camposInput={camposInput} showInfo={false} />
                    </button>
                    <div
                      style={{
                        border: item.value === camposInput.value ? '#37AB00 solid 2px' : '',
                        width: '60px',
                        backgroundColor: item.value === camposInput.value ? toggleMode ? theme.darkGreen : '#9DEB38' : '',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px',
                        height: '120px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexDirection: "column",
                        boxShadow: '1px 1px 4px 1px #989898',
                        mozBoxShadow: '1px 1px 4px 1px #989898',
                        webkitBoxShadow: '1px 1px 4x 1px #989898',
                      }}>
                        <IconButton
                    appearance="subtle"
                    onClick={() => {
                      setLocalizaRecrutamento(item)
                      setModalLocaliza(true)
                    }}
                    alt="Localização"
                    icon={<FaMapMarkedAlt />}
                  />

                      <IconButton
                        style={{ color: toggleMode && theme.lightGray }}
                        title="Detalhes"
                        appearance="subtle"
                        onClick={() => {
                          setExibirDetalhesDialog({
                            exibir: true,
                            id_profissional: item.value,
                          });
                        }}
                        icon={<InfoRoundIcon />}
                      />
                      <IconButton
                        style={{ marginTop: '0', color: toggleMode && theme.lightGray, backgroundColor: 'transparent' }}
                        circle
                        size="sm"
                        icon={<EditIcon />}
                        onClick={() => {
                          abrirEditarProfissional(item.value);
                        }}
                      />
                       <IconButton
                        style={{ color: toggleMode && theme.lightGray }}
                        title="Detalhes"
                        appearance="subtle"
                        onClick={() => {
                          setExibirDeleteDialog({
                            exibir: true,
                            id_profissional: item.value,
                            nome_profissional: item.label,
                          });
                        }}
                        icon={<Trash />}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
            </div>


          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onFechar} appearance="subtle">
              Cancelar
            </Button>
            <ButtonFullBlue onClick={editarRegistro} appearance="primary">
              Salvar
            </ButtonFullBlue>
            {/* <IconButton
             title="Atualizar Notas"
             appearance="subtle"
             onClick={atualizarNotas}
             icon={<ReloadIcon />}
            /> */}
          </Modal.Footer>
        </Form>
          </div>
      <LocalizacaoModal
        modalLocaliza={modalLocaliza}
        setModalLocaliza={setModalLocaliza}
        LocalizaRecrutamento={LocalizaRecrutamento}
      />
      <ModalDeleteProf
        onFechar={() => setExibirDeleteDialog({ exibir: false })}
        exibir={exibirDeleteDialog.exibir}
        id_profissional={exibirDeleteDialog.id_profissional}
        nome_profissional={exibirDeleteDialog.nome_profissional}
        onExcluir={() => {
          removerProfissional(exibirDeleteDialog.id_profissional);
        }}
      />
      <DetalhesCarteiraDialog
        exibir={exibirDetalhesDialog.exibir}
        id_profissional={exibirDetalhesDialog.id_profissional}
        onFechar={() => setExibirDetalhesDialog({ exibir: false })}
      />
      <EditarProfissional
        idProfissional={profissionalEditando && profissionalEditando}
        showEditarProfissional={showEditarProfissional}
        setShowEditarProfissional={setShowEditarProfissional}
        fecharEditarProfissional={fecharEditarProfissional}
      />
    </Modal>
    </div>
  );
}

ConfirmarProfissionalDialog.propTypes = {
  exibir: PropTypes.bool.isRequired,
  onFechar: PropTypes.func.isRequired,
  registro: PropTypes.object.isRequired,
  idPedido: PropTypes.number.isRequired,
};

export default ConfirmarProfissionalDialog;
