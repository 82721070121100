/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import { format, parseISO, eachDayOfInterval } from 'date-fns';
import { InfoRound, Search } from '@rsuite/icons';

import { Table, FlexboxGrid, SelectPicker, IconButton, InputGroup, AutoComplete, Input, CheckPicker } from 'rsuite';
import { toast } from 'react-toastify';
import { Container } from '~/styles/tabela';
import moment from 'moment';
import api from '~/services/api';
import formatCurrency from '~/utils/formatCurrency';
import SearchInputTable from '~/components/SearchInputTable';
import useSearchNames from '~/services/useSearchNames';

import { FiltroExperiencias } from '~/components/FiltroExperiencias';
import { FiltroTreinamentos } from '~/components/FiltroTreinamentos';
import SelectState from '~/components/SelectState';
import { TablePagination } from '~/components/TablePagination'
import { TabelaPaginacao } from '~/components/TabelaPaginacao';
import { useDebouncedCallback } from 'use-debounce/lib';
import { InputDataRange } from '../../../components/InputDataRange';
import FiltroCidades from '../../Mensagens/components/FiltroCidades';
import FiltroBairros from '../../Mensagens/components/FiltroBairros';

const { HeaderCell, Column, Cell } = Table;

function CadidatosGerais() {
  const [recrutamento, setRecrutamento] = useState({
    lista: [],
    quantidade: 0,
  });
  const [orderBy, setOrderBy] = useState()
  const [textodigitado, setTextoDigitado] = useState();
  const [pagina, setPagina] = useState(1);
  const [carregando, setCarregando] = useState(false);
  const [profissoes, setProfissoes] = useState([]);
  const [filtroEstabelecimento, setFiltroEstabelecimento] = useState();
  const [statusSelecionado, setStatusSelecionado] = useState();
  const [datas, setDatas] = useState();
  const [filtroServico, setFiltroServico] = useState();
  const [filtroCidades, setFiltroCidades] = useState([]);
  const [statusRecrutamento, setStatusRecrutamento] = useState([]);
  const [filtroBairros, setFiltroBairros] = useState([]);
  const [filtroProfissional, setFiltroProfissional] = useState();
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);
  const [nomesProfissionais, setNomesProfissionais] = useState([]);
  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const buscarRecrutamentoCandidatosGerais = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.get('recrutamento-candidatos-gerais', {
        params: {
          pagina,
          id_cliente: filtroEstabelecimento,
          status: statusSelecionado,
          data: datas,
          servico: filtroServico,
          cidade: filtroCidades[0],
          bairro: filtroBairros[0],
          profissional: filtroProfissional,
          numero_rs: textodigitado,
          orderBy,
        },
      });
      const data = resposta.data.lista.map((item) => ({
        ...item,
        nome_profissional: item.profissionais.nome,
        nome_fantasia: item.recrutamento.estabelecimento.nome_fantasia,
        funcao: item.recrutamento.profissoes.profissao,
        bairro_vaga: item.recrutamento.estabelecimento.endereco.bairro.bairro,
        cidade_vaga: item.recrutamento.estabelecimento.endereco.bairro.cidade.cidade,
        bairro_profissional: item.profissionais.endereco.bairro.bairro,
        cidade_profissional: item.profissionais.endereco.bairro.cidade.cidade,
        estado_profissional: item.profissionais.endereco.bairro.cidade.estado.estado,

      }));
      setRecrutamento({
        lista: data,
        quantidade: resposta.data.quantidade,
        });
      if(recrutamento.quantidade > 0 && recrutamento.lista.length === 0) setPagina(1)
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, [
    datas,
    pagina,
    statusSelecionado,
    filtroProfissional,
    filtroServico,
    filtroEstabelecimento,
    textodigitado,
    filtroCidades,
    filtroBairros,
    orderBy,
    recrutamento.quantidade,
    setPagina,
    recrutamento.lista.length
  ]);

  const buscarStatusRecrutamento = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.get('recrutamento-status');
      const data = resposta.data.map((item) => ({
        value: item.id, label: item.status
      }));


      setStatusRecrutamento(data);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, [ ]);


  useEffect(() => {
    buscarRecrutamentoCandidatosGerais();
    buscarStatusRecrutamento()
  }, [buscarRecrutamentoCandidatosGerais, buscarStatusRecrutamento]);

  useEffect(() => {
    const buscarDadosApi = async () => {
      try {
        const resposta = await api.get('profissao');
        setProfissoes(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };
    buscarDadosApi();
  }, []);

  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos',
    setFiltroEstabelecimento
  );

  const buscarNomesProfissionais = useSearchNames(
    setNomesProfissionais,
    'profissionais',
    setFiltroProfissional
  );
  const allValue = statusRecrutamento.map(item => item.value);

  return (
    <Container>
      <h2>Candidatos gerais RS</h2>
      <FlexboxGrid justify="start" style={{ gap: "10px" }}>
        <AutoComplete
            name="nome_estabelecimento"
            style={{ width: '180px' }}
            data={nomesEstabelecimentos.map((p) => ({
              label: p.nome_fantasia,
              value: p.nome_fantasia,
              id: p.id,
            }))}
            placeholder="Nome do Cliente"
            onSelect={async (value, item) => {
              setFiltroEstabelecimento(item.id);
            }}
            onChange={buscarNomesEstabelecimentos}
          />
        <CheckPicker
            value={statusSelecionado}
            placeholder="Status"
            searchable={false}
            data={statusRecrutamento}
            onChange={setStatusSelecionado}
          />
        <InputDataRange
            ranges={[]}
            // isoWeek
            value={datas}
            locale={locale}
            onChange={setDatas}
          />
        <SelectPicker
            name="id_profissao"
            placeholder="Serviço"
            value={filtroServico}
            data={profissoes.map((p) => {
              return {
                label: p.profissao,
                value: p.id,
              };
            })}
            onChange={setFiltroServico}
          />
        <InputGroup style={{ width: '150px' }}>
            <FiltroCidades
            setFiltroCidades={setFiltroCidades}
              onItemAdicionado={(value, item) => {
                const itemExiste = filtroCidades.find(
                  (filtro) => filtro.id === item.id
                );
                if (itemExiste) return;
                const lista = [item];
                setFiltroCidades(lista.map((p) => p.id));
              }}
            />
          </InputGroup>
          <InputGroup style={{ width: '150px' }}>
            <FiltroBairros
            setFiltroBairros={setFiltroBairros}
              onItemAdicionado={(value, item) => {
                const itemExiste = filtroBairros.find(
                  (filtro) => filtro.id === item.id
                );
                if (itemExiste) return;
                const lista = [item];
                setFiltroBairros(lista.map((p) => p.id));
              }}
            />
          </InputGroup>
          <InputGroup style={{ width: '180px' }}>
          <AutoComplete
            name="nome_profissional"
            width={100}
            data={nomesProfissionais.map((p) => ({
              label: p.nome,
              value: p.nome,
              id: p.id,
            }))}
            placeholder="Profissional"
            onSelect={async (value, item) => {
              setFiltroProfissional(item.id);
            }}
            onChange={buscarNomesProfissionais}
          />
        </InputGroup>
        <div>
            <InputGroup style={{ width: '140px' }}>
              <Input
                width={200}
                onChange={useDebouncedCallback(setTextoDigitado, 500).callback}
                placeholder="Número R"
              />

              <InputGroup.Button>
                <Search />
              </InputGroup.Button>
            </InputGroup>
        </div>
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '1430px' }}>

        <TablePagination
          carregando={carregando}
          dados={recrutamento.lista}
          quantidade={recrutamento.quantidade}
          onChangePage={setPagina}
          setOrderBy={setOrderBy}
        >
          <Column resizable width={250} sortable>
            <HeaderCell>Nome do Profissional</HeaderCell>
            <Cell dataKey="nome_profissional" />
          </Column>
          <Column resizable width={250} sortable>
            <HeaderCell>Nome do Estabelecimento</HeaderCell>
            <Cell dataKey="nome_fantasia" />
          </Column>
          <Column resizable width={130} sortable>
            <HeaderCell>Função</HeaderCell>
            <Cell dataKey="funcao" />
          </Column>
          <Column resizable width={130} sortable>
            <HeaderCell>Bairro da vaga</HeaderCell>
            <Cell dataKey="bairro_vaga" />
          </Column>
          <Column resizable width={140} sortable>
            <HeaderCell>Cidade da vaga</HeaderCell>
            <Cell dataKey="cidade_vaga" />
          </Column>
          <Column resizable width={170} sortable>
            <HeaderCell>Bairro do Profissional</HeaderCell>
            <Cell dataKey="bairro_profissional" />
          </Column>
          <Column resizable width={180} sortable>
            <HeaderCell>Cidade do Profissional</HeaderCell>
            <Cell dataKey="cidade_profissional" />
          </Column>
          <Column resizable width={180} sortable>
            <HeaderCell>Estado do Profissional</HeaderCell>
            <Cell dataKey="estado_profissional" />
          </Column>
        </TablePagination>
      </FlexboxGrid>
    </Container>
  );
}

export default CadidatosGerais;
